import { Component, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

// IonicModule
import { IonicModule } from '@ionic/angular';

import { NgApexchartsModule } from "ng-apexcharts";
import { AgGridModule } from 'ag-grid-angular';

import { ReportesRoutingModule } from './reportes-routing.module';

// Pages

import { ColocacionComponent } from './pages/colocacion/colocacion.component';

// Components

// Calendar UI Module;

import { ColocacionModule } from './pages/colocacion/colocacion.module';
import { CarteraModule } from './pages/cartera/cartera.module';
import { WordsCellsComponent } from './components/cell-render/words-cells/words-cells.component';
import { PercentCellsComponent } from './components/cell-render/percent-cells/percent-cells.component';
import { NumberCellsComponent } from './components/cell-render/number-cells/number-cells.component';
import { RecargaModule } from './pages/recarga/recarga.module';
import { InputRenderComponent } from './components/cell-render/input-render/input-render.component';
import { PhonenumberCellsComponent } from './components/cell-render/phonenumber-cells/phonenumber-cells.component';
import { AnchorTagCellsComponent } from './components/cell-render/anchor-tag-cells/anchor-tag-cells.component';
import { ComposicionPagosModule } from './pages/composicionPagos/composicion-pagos.module';
import { PopoversCellComponent } from './components/cell-render/popovers-cell/popovers-cell.component';
import { SelectTipobajaComponent } from './components/composicionPagos/tabla-composicion-pagos/cell-render/select-tipobaja/select-tipobaja.component';
import { InputComposicionpagosComponent } from './components/composicionPagos/tabla-composicion-pagos/cell-render/input-composicionpagos/input-composicionpagos.component';
import { ActionComposicionpagosComponent } from './components/composicionPagos/tabla-composicion-pagos/cell-render/action-composicionpagos/action-composicionpagos.component';
import { DateComposicionpagosComponent } from './components/composicionPagos/tabla-composicion-pagos/cell-render/date-composicionpagos/date-composicionpagos.component';
import { CustomHeaderComposicionpagosComponent } from './components/composicionPagos/tabla-composicion-pagos/cell-render/custom-header-composicionpagos/custom-header-composicionpagos.component';

// const cellComponent = [
//   MoneyCellsComponent,
//   NumberCellsComponent,
//   PercentCellsComponent,
//   WordsCellsComponent,
//   DateCellsComponent,
//   ModalFechaComponent,
//   ColorFilterComponent,
//   CustomTooltipComponent
// ];


@NgModule({
  declarations: [
    // ColocacionComponent,
    // GraficoColocacionComponent,
    // TablaColocacionComponent,
    // FiltrosComponent,
    // cellComponent
    WordsCellsComponent,
    SelectTipobajaComponent,
    InputComposicionpagosComponent,
    ActionComposicionpagosComponent,
    DateComposicionpagosComponent,
    PopoversCellComponent,
    PercentCellsComponent,
    NumberCellsComponent,
    CustomHeaderComposicionpagosComponent,
    InputRenderComponent,
    PhonenumberCellsComponent,
    AnchorTagCellsComponent
  ],
  imports: [
    CommonModule,

    ColocacionModule,
    CarteraModule,
    RecargaModule,
    ComposicionPagosModule,
  ],
  exports: [
    ColocacionModule,
    CarteraModule,
    RecargaModule,
    ComposicionPagosModule,

  ],
  // entryComponents: [cellComponent,
  //   CustomTooltipComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class ReportesModule { }
