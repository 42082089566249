import { Component, OnInit } from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
@Component({
  selector: 'app-phonenumber-cells',
  templateUrl: './phonenumber-cells.component.html',
  styleUrls: ['./phonenumber-cells.component.scss'],
})
export class PhonenumberCellsComponent implements OnInit, ICellRendererAngularComp{
  cellData: any;
  params: ICellRendererParams;
  phoneNumber: string;
  constructor() { }

  ngOnInit() {}

  agInit(params: ICellRendererParams){
    this.params = params;

    // tabla recarga

    if (params.colDef.headerName == 'Celular'){      
      // console.log('Tasa Total',params.data.tasaPonderadaPromedio )
      this.phoneNumber = params.data.vCelular == null ? this.phonefaormat('0000000000') : this.phonefaormat(params.data.vCelular);
    }else if (params.colDef.headerName == 'Telefono'){
      this.phoneNumber = params.data.vTelefono == null ? this.phonefaormat('0000000000') : this.phonefaormat(params.data.vTelefono);
    }


    
  }
  phonefaormat(rawNum){
    
    let newStr = "";
    let i = 0;

    for (; i < Math.floor(rawNum.length / 2) - 1; i++) {
      newStr = newStr + rawNum.substr(i * 2, 2) + "-";
    }

    return newStr + rawNum.substr(i * 2);

  }
  refresh(params: any): boolean {
    this.cellData = params.value;
    return true;
  }

}
