import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import { ReportesService } from 'src/app/reportes/services/reportes.service';
import { DateService } from 'src/app/reportes/shared/date.service';
@Component({
    selector: 'app-modal-fecha',
    templateUrl: './modal-fecha.component.html',
    styleUrls: ['./modal-fecha.component.scss'],
})
export class ModalFechaComponent implements OnInit {
    fechaInicio: string;
    fechaFin: string;
    sendDateInicio: string;
    sendDateFin: string;
    firstDayAtMonth: any;
    actualDay: any;
    constructor(public modalController: ModalController,
                private dateService: DateService,
                private repservice: ReportesService,
                private seviceReport:ReportesService) {
    }

    ngOnInit() {
        this.getDay();
    }

    getDay(){
        this.firstDayAtMonth = this.dateService.getinitDate();
        this.actualDay = new Date();

    }
    onChange(type: string, $event: any){
        if (type == 'fechaInicio') {
            let dateIni
            dateIni = this.dateService.getDateFormat($event);
            // dateIni = dateIni.slice(0,7)
            this.seviceReport.fechaInicio = dateIni;
            console.log('this.sendDateIniciothis.sendDateIniciothis.sendDateInicio',this.seviceReport.fechaInicio)
        } else {
            let dateFin
            dateFin= this.dateService.getDateFormat($event);
            this.seviceReport.fechafin = dateFin;
        }
        
    }
    sendNewsDates(){
        //voy a cerrar el modal y a consumir el servicio
        this.getDataForTable();
    }

    /*
    consume getREPColocacionTable service
     */
    async getDataForTable(){
        // this.seviceReport.enviarMensaje();
        this.dismiss()
    }

    /*
    close modal
     */
    public dismiss(): void {
        this.modalController.dismiss({
            'dismissed': true
        });
    }
}
