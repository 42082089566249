import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { recarga_prospecto } from 'src/app/reportes/models/recarga_prospecto';
import { RecargaService } from 'src/app/reportes/pages/recarga/recarga.service';

@Component({
  selector: 'app-url-solicitud-recarga',
  templateUrl: './url-solicitud-recarga.component.html',
  styleUrls: ['./url-solicitud-recarga.component.scss'],
})
export class UrlSolicitudRecargaComponent implements ICellRendererAngularComp {

  cellData: any;
  params: ICellRendererParams;
  name: string;
  public number
  public id_solicitud;
  public show;
  public url;

  constructor(private recargaService: RecargaService) {
   
  }

  ngOnInit() { }

  agInit(params: ICellRendererParams){


    
    this.params = params;
    let step, coach, encode, url;
    this.id_solicitud = params.data.solicitud_plataforma
    coach = params.data.id;
    // console.log(this.params)
    if (params.colDef.headerName == 'Sol plataforma') {

      if (params.data.step != 'N/A' && params.data.step != 'CONTRATO FIRMADO' && params.data.step) {

        this.show = true;
        let stepBd = JSON.parse(params.data.step)
        step = { ...stepBd, ...coach };
        encode = btoa(JSON.stringify(step));
        this.url = 'https://solicitud.ayudatelta.com/#/solicitud?EXT=' + encode;

      } else if (params.data.step == 'CONTRATO FIRMADO') {
        this.show = true;
        step = {
          formulario:5,
          solicitud:this.id_solicitud,
          tasa:null,
          nav:6,
          firma:null, 
          id_coach:coach
        };
        encode = btoa(JSON.stringify(step));
        this.url = 'https://solicitud.ayudatelta.com/#/solicitud?EXT=' + encode;

      } else {
        this.show = false
        this.url = 'N/A'
      }

      // console.log('params')

    }



   }

  onInputBoxChanged(value) {

  }

  urlformat(url){

  }


  converText(title){
    return title.toUpperCase();
  }
  refresh(params: any): boolean {
    this.cellData = params.value;
    return true;
  }

}