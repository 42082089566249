import { Component, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
//shared
import { DateService } from 'src/app/reportes/shared/date.service';
//services
import { ReportesService } from 'src/app/reportes/services/reportes.service';
//Cell Render

import * as data from '../../../shared/reglasSemaforizacion.json';
import { MoneyCellsCarteraComponent } from './cells-table-cartera/money-cells-cartera/money-cells-cartera.component';
import { NumberCellsCarteraComponent } from './cells-table-cartera/number-cells-cartera/number-cells-cartera.component';
import { WordsCellsCarteraComponent } from './cells-table-cartera/words-cells-cartera/words-cells-cartera.component';
import { DateCellsCarteraComponent } from './cells-table-cartera/date-cells-cartera/date-cells-cartera.component';
import { CustomTooltipComponent } from '../../colocacion/tabla-colocacion/cells-table/custom-tooltip/custom-tooltip.component';
import { PercentCellsCarteraComponent } from './cells-table-cartera/percent-cells-cartera/percent-cells-cartera.component';
import { ColorCellsCarteraComponent } from './cells-table-cartera/color-cells-cartera/color-cells-cartera.component';
import { CustomTooltipCellsCarteraComponent } from './cells-table-cartera/custom-tooltip-cells-cartera/custom-tooltip-cells-cartera.component';
import { CarteraService } from 'src/app/reportes/pages/cartera/cartera.service';
import { WordsCellsComponent } from '../../cell-render/words-cells/words-cells.component';
import { PercentCellsComponent } from '../../cell-render/percent-cells/percent-cells.component';
import { MoneyCellsComponent } from '../../cell-render/money-cells/money-cells.component';
import { NumberCellsComponent } from '../../cell-render/number-cells/number-cells.component';
@Component({
    selector: 'app-tabla-cartera',
    templateUrl: './tabla-cartera.component.html',
    styleUrls: ['./tabla-cartera.component.scss'],
})
export class TablaCarteraComponent implements OnInit {

    public rowClass;
    public getRowClass;
    public rowClassRules;
    public getRowStyle;

    public colorReglas;

    private initDate: string;
    public switchGrafico: string;
    private finalDate: string;
    public year1Date: string;
    public defaultColDef;
    public sideBar;
    // public arrayInfo = Array<Colocacion>();
    public arrayInfo: any = [];
    public pinnedBottomRowData;
    rowHeight: number;
    rowDef;
    chartData;
    params: any;
    gridApi;
    gridColumnApi;
    gridOptions;
    context: any;
    minRowHeight;
    currentRowHeight: any;
    frameworkComponents: any;
    filterSelect: string;
    searchValue: string;
    minWidth: string;
    minWidthP: string;
    maxWidth: string;
    public tooltipShowDelay;

    filtros = [
        'empresa',
        'vendedor',
        'supervisor',
        'convenioActual',
        'organizacion',
        'mercado',
        'sectorProducto',
        'producto'
    ]

    categorias = [
        'organizacion',
        'mercado',
        'sectorProducto',
        'producto'
    ]


    constructor(
        private repservice: CarteraService,
        private dateService: DateService
    ) {
        this.colorReglas = data.data
        // this.vColor = 5.5
        this.context = { componentParent: this };
        this.getConfig();
        this.minWidth = '3px';
        this.minWidthP = '2px';
        this.maxWidth = '1px';
        this.initDate = this.dateService.getinitDate();
        this.finalDate = this.dateService.getFinaldate();
        this.year1Date = this.dateService.FinTreceMeses;

    }



    ngOnInit() {


        // this.repservice.getREPColocacionTableTotal(this.initDate, this.finalDate)
        //     .subscribe((resp: Colocacion) => {
        //         // this.pinnedBottomRowData = this.createData(resp['data']);
        //     });

        this.repservice.fechaInicio = this.year1Date;
        this.repservice.fechafin = this.finalDate;
        this.repservice.enviarMensaje()
        this.repservice.enviarMensajeSubject.subscribe(response => {

            console.log('respuesta del subject ********************', response)
            if (response) {
                this.switchGrafico = this.repservice.switchGraficos
                let agrupador = response['agrupador']
                this.arrayInfo = response['data']
                this.params.api.setRowData(response['data'])
                this.pinnedBottomRowData = this.createFooterData(this.params);


                // console.log('agrupadoragrupadoragrupadoragrupadoragrupadoragrupadoragrupadoragrupador', agrupador)

                // if (agrupador == 'year') {
                //     this.params.columnApi.setColumnVisible('year', true)
                //     this.params.columnApi.setColumnVisible('yearMonth', false)
                // } else {
                //     this.params.columnApi.setColumnVisible('yearMonth', true)
                //     this.params.columnApi.setColumnVisible('year', false)
                // }

                if (response['filtros']) {
                    let filtro
                    filtro = response['filtros'];
                    let regexp = new RegExp('catalogo_empresa.', 'g')
                    filtro = filtro.map(ele => ele.replace(regexp, ''))
                    // console.log('Filtros desde la tabla colocacion', filtro)
                    this.filtros.map(ele => {

                        // console.log('Filtros desde la tabla colocacion', ele ,' include',filtro.includes(ele))
                        if (filtro.includes(ele)) {
                            // console.log('Filtros desde la tabla colocacion', this.params.columnApi.setColumnVisible(ele, true))
                            this.params.columnApi.setColumnVisible(ele, true)
                        } else {
                            this.params.columnApi.setColumnVisible(ele, false)
                        }
                    });
                }


            }
        })


    }

    ngOnChanges() {

        // console.log('ngOnChanges' , this.dataRepo)
        // this.repservice.getREPColocacionTable2(this.initDate, this.finalDate).subscribe(data => {
        //     this.arrayInfo = data['data'];
        //     // this.params.api.setRowData(this.arrayInfo);
        //     // console.log('dataChidaaaaa',data['data'])
        // })
        // if (this.arrayInfo != null) {

        //     // this.params.api.setRowData(this.arrayInfo);
        // }
        // console.log('this.arrayInfo', this.arrayInfo)
    }

    getConfig() {
        this.rowClass = 'my-blue-class';
        this.getRowClass = params => {
            if (params.node.rowIndex % 2 === 0) {
                return 'my-shaded-effect';
            }
        },
            this.getRowStyle = function (params) {
                if (params.node.rowPinned === 'bottom') {
                    return { "background-color": "red" };
                }
            },
            this.rowDef = this.getColumns();
        this.sideBar = 'filters';
        this.defaultColDef = {
            flex: 1,
            minWidth: 150,
            filter: true,
            floatingFilter: true,
            tooltipComponent: 'customTooltip'
        };
        this.rowHeight = 50
        this.frameworkComponents = this.getFrameworkComponents();
        // this.rowClassRules = {
        //     'rag-green': 'data.age < 20',
        //     'rag-amber': 'data.age >= 20 && data.age < 25',
        //     'rag-red': 'data.age >= 25',
        // },
        this.tooltipShowDelay = 0;
        this.gridOptions = {

            isExternalFilterPresent: this.isExternalFilterPresent,
            doesExternalFilterPass: this.doesExternalFilterPass,
            columnDefs: this.rowDef,
            components: {
                moneyCells: MoneyCellsComponent,
                numberCells: NumberCellsComponent,
                percentCell: PercentCellsComponent,
                wordsCell: WordsCellsCarteraComponent,
                DateCells: DateCellsCarteraComponent,
                customTooltip: CustomTooltipCellsCarteraComponent
            },
            // rowClassRules: {

            //     'rag-green': 'data.diasAperturaPonderadoPromedio < 4000',
            //     'rag-amber': 'data.diasAperturaPonderadoPromedio >= 4000',
            // 'rag-red': 'data.age >= 25',

            // "row-fail": params => params.api.getValue("result", params.node) < 60,
            // "row-pass": params => params.api.getValue("result", params.node) >= 60

            // 'bg-red': (params) => {
            //     if (params.rowIndex < 2) {
            //         return true;
            //     }
            // },
            // },
            defaultColDef: {
                filter: true,
                floatingFilter: true,
                resizable: true,
                sortable: true,
                hide: true,
                tooltipComponent: 'customTooltip',
            },
        };
        // this.gridApi.setPinnedBottomRowData(['',123,123,4325,2345])
    }

    // region :: create table
    getColumns() {

        // let col
        return [
            {
                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Historico',
                field: 'hist',
                cellRenderer: 'DateCells',
                sortable: true,
                resizable: true,
                // filter: 'agDateColumnFilter',
                // filterParams,
                floatingFilter: true,
                hide: false,
                // cellStyle: {color: 'red', 'background-color': 'green'}
            },
            {
                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Empresa',
                field: 'empresa',
                cellRenderer: 'wordsCell',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                hide: true,
            },
            {

                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Convenio Actual',
                field: 'convenioActual',
                cellRenderer: 'wordsCell',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                hide: true
            },
            {

                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Vendedor',
                field: 'vendedor',
                cellRenderer: 'wordsCell',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                hide: true
            },
            {

                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Supervisor',
                field: 'supervisor',
                cellRenderer: 'wordsCell',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                hide: true
            },
            {
                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Organizacion',
                field: 'organizacion',
                cellRenderer: 'wordsCell',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                hide: true,
                filter: true
            },
            {

                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Mercado',
                field: 'mercado',
                cellRenderer: 'wordsCell',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                hide: true
            },
            {

                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Sector Producto',
                field: 'sectorProducto',
                cellRenderer: 'wordsCell',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                hide: true
            },
            {

                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Producto',
                field: 'producto',
                cellRenderer: 'wordsCell',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                hide: true
            },
            

            {
                colId: 'numeroCreditos',
                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Numero Creditos',
                field: 'numeroCreditos',
                cellRenderer: 'numberCells',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
            },
            {
                colId: 'capitalPendiente',
                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Capital Pendiente',
                field: 'capitalPendiente',
                cellRenderer: 'moneyCells',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
            },
            {
                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Cartera Sana',
                field: 'carteraSana',
                cellRenderer: 'moneyCells',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
            },


            
            {
                colId: 'carteraSanaPorcentaje',
                width: 100,
                minWidth: 90,
                maxWidth: 150,
                headerName: '%',
                field: 'carteraSanaPorcentaje',
                cellRenderer: 'percentCell',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
                // floatingFilterComponent: 'colorFilter',
                // headerTooltip: 'tasaPonderadaPromedio',
                // tooltipComponentParams: { campo: 'Tasa Total', reglas: this.colorReglas },
                // cellClass: params => {
                //     if (Object.values(params.data).shift() == 'Total') {

                //         return params.value;
                //     } else {
                //         return ragCellClassRules(params.value, this.colorReglas, 'Tasa Total')
                //     }
                // }
                // cellClassRules: ragCellClassRules('Tasa Total',this.colorReglas)
            },
            {
                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Resolucion Finiquito Admin',
                field: 'resolucionFiniquitoAdmin',
                cellRenderer: 'moneyCells',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
            },
            {
                colId: 'resolucionFiniquitoAdminPorcentaje',
                width: 100,
                minWidth: 90,
                maxWidth: 150,
                headerName: '%',
                field: 'resolucionFiniquitoAdminPorcentaje',
                cellRenderer: 'percentCell',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
            }, ,
            {
                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Resolucion Finiquito',
                field: 'resolucionFiniquito',
                cellRenderer: 'moneyCells',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
            },
            {
                colId: 'resolucionFiniquitoPorcentaje',
                width: 100,
                minWidth: 90,
                maxWidth: 150,
                headerName: '%',
                field: 'resolucionFiniquitoPorcentaje',
                cellRenderer: 'percentCell',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
            },
            {
                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Ex empleados Ayudate',
                field: 'exempleadosAyudate',
                cellRenderer: 'moneyCells',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
            },
            {
                colId: 'exempleadosAyudatePorcentaje',
                width: 100,
                minWidth: 90,
                maxWidth: 150,
                headerName: '%',
                field: 'exempleadosAyudatePorcentaje',
                cellRenderer: 'percentCell',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
            },
            {
                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Cartera Vendida',
                field: 'carteraVendida',
                cellRenderer: 'moneyCells',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
            },
            {
                colId: 'carteraVendidaPorcentaje',
                width: 100,
                minWidth: 90,
                maxWidth: 150,
                headerName: '%',
                field: 'carteraVendidaPorcentaje',
                cellRenderer: 'percentCell',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
                // floatingFilterComponent: 'colorFilter',
                // headerTooltip: 'tasaPonderadaPromedio',
                // tooltipComponentParams: { campo: 'Tasa Total', reglas: this.colorReglas },
                // cellClass: params => {
                //     if (Object.values(params.data).shift() == 'Total') {

                //         return params.value;
                //     } else {
                //         return ragCellClassRules(params.value, this.colorReglas, 'Tasa Total')
                //     }
                // }
                // cellClassRules: ragCellClassRules('Tasa Total',this.colorReglas)
            },
            {
                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Difuntos',
                field: 'difuntos',
                cellRenderer: 'moneyCells',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
            },
            {
                colId: 'difuntosPorcentaje',
                width: 100,
                minWidth: 90,
                maxWidth: 150,
                headerName: '%',
                field: 'difuntosPorcentaje',
                cellRenderer: 'percentCell',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
            },
            {
                colId: 'plazoPonderadoPromedio',
                minWidth: this.minWidth,
                maxWidth: this.minWidthP,
                headerName: 'Plazo ponderado promedio',
                field: 'plazoPonderadoPromedio',
                cellRenderer: 'numberCells',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
            },

            {
                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Tasa Ponderada',
                field: 'tasaPonderada',
                cellRenderer: 'moneyCells',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
                hide: true
            },
            {
                colId: 'tasaPonderadaPromedio',
                width: 100,
                minWidth: 90,
                maxWidth: 150,
                headerName: 'tasa promedio',
                field: 'tasaPonderadaPromedio',
                cellRenderer: 'percentCell',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
                // hide: true
                // floatingFilterComponent: 'colorFilter',
                // headerTooltip: 'tasaPonderadaPromedio',
                // tooltipComponentParams: { campo: 'Tasa Total', reglas: this.colorReglas },
                // cellClass: params => {
                //     if (Object.values(params.data).shift() == 'Total') {

                //         return params.value;
                //     } else {
                //         return ragCellClassRules(params.value, this.colorReglas, 'Tasa Total')
                //     }
                // }
                // cellClassRules: ragCellClassRules('Tasa Total',this.colorReglas)
            },
            {
                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'plazoPonderado',
                field: 'plazoPonderado',
                cellRenderer: 'moneyCells',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
                hide: true
            },
            {
                colId: 'numeroCreditosAux',
                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'numeroCreditosAux',
                field: 'numeroCreditosAux',
                cellRenderer: 'moneyCells',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
                hide: true
            },

            

        ]
    }




    getFrameworkComponents() {
        return {
            moneyCells: MoneyCellsComponent,
            numberCells: NumberCellsComponent,
            percentCell: PercentCellsComponent,
            wordsCell: WordsCellsComponent,
            // wordsCell: WordsCellsCarteraComponent,
            DateCells: DateCellsCarteraComponent,
            customTooltip: CustomTooltipCellsCarteraComponent,
            colorFilter: ColorCellsCarteraComponent,
        };
    }

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }



    onFilterChanged(params: GridOptions): void {
        // console.log(this.numberOfRows = params.api?.getDisplayedRowCount());
        // console.log('hola')
    }

    onGridReady(params) {
        this.params = params;
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.minRowHeight = params.api.getSizesForCurrentTheme().rowHeight;
        this.currentRowHeight = this.minRowHeight;
        params.api.sizeColumnsToFit();

    }



    createFooterData(gridApi?, event?: GridOptions) {
        let result = [];
        let model;
        let visibleRows = [];
        let dataGrafico = [];

        // console.log('este es el vento de los filtros', event)



        if (gridApi) {
            model = gridApi?.columnApi?.columnModel.gridApi.rowModel;
            visibleRows = model.rowsToDisplay;
        }

        // console.log('this.switchGrafico',this.switchGrafico)
        if (this.switchGrafico == 'cantidad') {
            this.graficoCantidad(visibleRows)
        } else if (this.switchGrafico == 'Porcentaje') {
            this.graficoPorcentaje(visibleRows)
            console.log('entro al else')
            // alert('te la creiste wey aun no esta xd')
        }



        let numeroCreditos = sumP(visibleRows, 'numeroCreditos')
        let capitalPendiente = sumP(visibleRows, 'capitalPendiente')
        let capitalPendientetotal = sum(visibleRows, 'capitalPendiente')

        let carteraSana = sumP(visibleRows, 'carteraSana')
        let carteraSanaPorcentaje = sum(visibleRows, 'carteraSana')
        carteraSanaPorcentaje = (carteraSanaPorcentaje / capitalPendientetotal) * 100

        let resolucionFiniquitoAdmin = sumP(visibleRows, 'resolucionFiniquitoAdmin')
        let resolucionFiniquitoAdminPorcentaje = sum(visibleRows, 'resolucionFiniquitoAdmin')
        resolucionFiniquitoAdminPorcentaje = (resolucionFiniquitoAdminPorcentaje / capitalPendientetotal) * 100


        let resolucionFiniquito = sumP(visibleRows, 'resolucionFiniquito')
        let resolucionFiniquitoPorcentaje = sum(visibleRows, 'resolucionFiniquito')
        resolucionFiniquitoPorcentaje = (resolucionFiniquitoPorcentaje / capitalPendientetotal) * 100


        let exempleadosAyudate = sumP(visibleRows, 'exempleadosAyudate')
        let exempleadosAyudatePorcentaje = sum(visibleRows, 'exempleadosAyudate')
        exempleadosAyudatePorcentaje = (exempleadosAyudatePorcentaje / capitalPendientetotal) * 100

        let carteraVendida = sumP(visibleRows, 'carteraVendida')
        let carteraVendidaPorcentaje = sum(visibleRows, 'carteraVendida')
        carteraVendidaPorcentaje = (carteraVendidaPorcentaje / capitalPendientetotal) * 100

        let difuntos = sumP(visibleRows, 'difuntos')
        let difuntosPorcentaje = sum(visibleRows, 'difuntos')
        difuntosPorcentaje = (difuntosPorcentaje / capitalPendientetotal) * 100

        // let tasaPonderada = sumP(visibleRows, 'tasaPonderada')
        let tasaPonderadaPromedio = sum(visibleRows, 'tasaPonderada')
        tasaPonderadaPromedio = (tasaPonderadaPromedio / capitalPendientetotal) * 100

        // let plazoPonderado = sumP(visibleRows, 'plazoPonderado')
        let plazoPonderadoPromedio = sum(visibleRows, 'plazoPonderado')
        plazoPonderadoPromedio = (plazoPonderadoPromedio / capitalPendientetotal) * 100

        result.push({
            hist: 'Total',
            numeroCreditos,
            capitalPendiente,
            carteraSana,
            carteraSanaPorcentaje,
            resolucionFiniquitoAdmin,
            resolucionFiniquitoAdminPorcentaje,
            resolucionFiniquito,
            resolucionFiniquitoPorcentaje,
            exempleadosAyudate,
            exempleadosAyudatePorcentaje,
            carteraVendida,
            carteraVendidaPorcentaje,
            difuntos,
            difuntosPorcentaje,
            tasaPonderadaPromedio,
            plazoPonderadoPromedio
        });
        // console.log('result:', result);
        this.pinnedBottomRowData = result;
        return result;
    }

    graficoCantidad(visibleRows) {
        let groupBy = this.repservice.agrupadores;
        let carteraSanaGrafica = agrupador(visibleRows, 'carteraSana', groupBy)
        let cobranzaAdministrativaGrafica = agrupador(visibleRows, 'resolucionFiniquitoAdmin', groupBy)


        let resolucionFiniquitoGrafica = agrupador(visibleRows, 'resolucionFiniquito', groupBy)
        let exempleadosAyudateGrafica = agrupador(visibleRows, 'exempleadosAyudate', groupBy)

        let cobranzaextraJudicial = Totales(resolucionFiniquitoGrafica, exempleadosAyudateGrafica)

        let difuntosGrafica = agrupador(visibleRows, 'difuntos', groupBy)
        let carteraVendidaGrafica = agrupador(visibleRows, 'carteraVendida', groupBy)

        let carteraVendidaYDifuntos = Totales(difuntosGrafica, carteraVendidaGrafica)


        let grafica = []
        carteraSanaGrafica.map((curr, indx) => {
            if (grafica) {
                grafica.push({
                    "Historico": curr[0],
                    "cobranzaAdministrativa": cobranzaAdministrativaGrafica[indx][1],
                    "carteraSana": carteraSanaGrafica[indx][1],
                    "cobranzaextraJudicial": cobranzaextraJudicial[indx][1],
                    "carteraVendidaYDifuntos": carteraVendidaYDifuntos[indx][1],
                    "Total": cobranzaAdministrativaGrafica[indx][1] +
                        carteraSanaGrafica[indx][1] +
                        cobranzaextraJudicial[indx][1] +
                        carteraVendidaYDifuntos[indx][1]
                    // 'dispersionRecargas': totalTablaDispersionRecargas[indx][1],
                    // 'meta': totalTablaPresupuesto[indx][1],
                    // 'Total': totalCreditosNuevosRecargas[indx][1],
                })
            }
        })
        console.log('cobranzaextraJudicial: ', grafica)
        this.repservice.enviarToatalesGraficaSubject.next(grafica)
    }

    graficoPorcentaje(visibleRows) {
        // console.log('visibleRows',visibleRows)
        let groupBy = this.repservice.agrupadores;
        let carteraSanaGrafica = agrupador(visibleRows, 'carteraSanaPorcentaje', groupBy)



       

        let capitalPendientetotal = agrupador(visibleRows, 'capitalPendiente', groupBy)
        let carteraSanaPorcentaje = agrupador(visibleRows, 'carteraSana', groupBy)
        let resolucionFiniquitoAdmin = agrupador(visibleRows, 'resolucionFiniquitoAdmin', groupBy) 

        let exempleadosAyudate = agrupador(visibleRows, 'exempleadosAyudate', groupBy) 
        let resolucionFiniquito = agrupador(visibleRows, 'resolucionFiniquito', groupBy) 

        let carteraVendida = agrupador(visibleRows, 'carteraVendida', groupBy) 
        let difuntos = agrupador(visibleRows, 'difuntos', groupBy) 



        let grafica = []
        carteraSanaGrafica.map((curr, indx) => {

            
            let cobranzaAdministrativaGrafica = (resolucionFiniquitoAdmin[indx][1] / capitalPendientetotal[indx][1]) * 100
            
            let cobranzaextraJudicial = ((exempleadosAyudate[indx][1] + resolucionFiniquito[indx][1])/capitalPendientetotal[indx][1])* 100
            
            let carteraSanaPorcentajea = (carteraSanaPorcentaje[indx][1] / capitalPendientetotal[indx][1]) * 100
            
            let carteraVendidaYDifuntoGrafica = ((carteraVendida[indx][1] + difuntos[indx][1])/capitalPendientetotal[indx][1])* 100
            
            let toal = (cobranzaAdministrativaGrafica + carteraSanaPorcentajea + cobranzaextraJudicial + carteraVendidaYDifuntoGrafica)
            if (grafica) {
                grafica.push({
                    "Historico": curr[0],
                    "cobranzaAdministrativa": cobranzaAdministrativaGrafica,
                    "carteraSana": carteraSanaPorcentajea,
                    "cobranzaextraJudicial":cobranzaextraJudicial ,
                    "carteraVendidaYDifuntos": carteraVendidaYDifuntoGrafica,
                    "Total": toal
                    // 'dispersionRecargas': totalTablaDispersionRecargas[indx][1],
                    // 'meta': totalTablaPresupuesto[indx][1],
                    // 'Total': totalCreditosNuevosRecargas[indx][1],
                })
            }
        })
        // console.log('cobranzaextraJudicial: ', grafica)
        this.repservice.enviarToatalesGraficaSubject.next(grafica)
    }

    getComisionTotal(visibleRows, filtro) {
        // let fil = filtro
        let total;
        let resul;
        total = visibleRows.reduce((acc, cur) => { acc += cur.data[filtro]; return acc; }, 0)
        resul = isNaN(total) == true ? 0 : total;
        return parseInt(resul, 0);
    }


    isExternalFilterPresent() {
        return filterWord !== '';
    }

    doesExternalFilterPass(node) {
        let cellData;
        cellData = node.data.estatusRecomendados;
        switch (filterWord) {
            case '0':
                return cellData == filterWord;
            case '1':
                return cellData == filterWord;
            case '2':
                return cellData == filterWord;
            case '3':
                return cellData == filterWord;
            default:
                return node;
        }
    }

}

function headerTooltipStyle(reglas, campo) {
    let headerToolti
    let redRule, amberRule, greenRule;

    reglas.forEach(element => {
        if (element.Campo == campo) {
            redRule = element.red
            amberRule = element.amber
            greenRule = element.green
        }
    });

    return ` <div class="custom-tooltip" [style.background-color]="color">
<p>
  <span>{{ data.athlete }}</span>
</p>
<p><span>Country: </span>{{ data.country }}</p>
<p><span>Total: </span>{{ data.total }}</p>
</div>`
    return (
        '<div class="header-toltip">' + '<span >  Reglas </span>' + '</div>' +
        '<ul>' +
        '<li class="rag-red"><span class="span-tooltip"> Rojo: ' + redRule + '</span></li>' +

        '<li class="rag-amber"><span class="span-tooltip"> Amarilo: $' + amberRule + '</span></li>' +

        '<li class="rag-green"><span class="span-tooltip"> Verde: $' + greenRule + '</span></li>' +

        '</ul>'
        // '<div class="arrow_box"> <div class="circulo"> </div>' +'<span>' + w.globals.seriesNames[0] + ': ' + creditosNuevos[dataPointIndex].toFixed(2) + '</span> ' + '</div>' +
        // '<div class="arrow_box">' + '<span>' + w.globals.seriesNames[1] + ': ' + creditosRecargas[dataPointIndex].toFixed(2) + '</span>' + '</div>' +
        // '<div class="arrow_box">' + '<span>' + w.globals.seriesNames[2] + ': ' + totalCreditosNuevosRecargas[dataPointIndex].toFixed(2) + '</span>' + '</div>' +
        // '<div class="arrow_box">' + '<span>' + w.globals.seriesNames[3] + ': ' + metasMeses[dataPointIndex].toFixed(2) + '</span>' + '</div>'
    )
    // return headerToolti
    // console.log('ragCellClassRules2',params,'  ', reglas)

}

function ragCellClassRules(params, reglas, campo) {
    let clascs
    reglas.filter(ele => {
        if (ele.Campo == campo) {
            // console.log(params);
            let red = ele.red.replaceAll('x', params)
            let amber = ele.amber.replaceAll('x', params)
            let green = ele.green.replaceAll('x', params)
            // console.log('red', red)
            clascs = eval(red) ? 'rag-red' : eval(amber) ? 'rag-amber' : eval(green) ? 'rag-green' : ''

        }
    })

    return clascs
    // console.log('ragCellClassRules2',params,'  ', reglas)

}



let filterWord = '';

var filterParams = {
    comparator: function (filterLocalDateAtMidnight, cellValue) {
        var dateAsString = cellValue;
        if (dateAsString == null) return -1;
        var dateParts = dateAsString.split('-');
        var cellDate = new Date(
            Number(dateParts[0]),
            Number(dateParts[1]) - 1,
        );

        let date = new Date(filterLocalDateAtMidnight)
        let yyyy = date.getFullYear()
        let dd = ('0' + (date.getMonth() + 1)).slice(-2)

        let ndate = ` ${yyyy}-${dd}`;
        filterLocalDateAtMidnight = new Date(ndate)

        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
            return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
            return 1;
        }
    },
    browserDatePicker: true,
    minValidYear: 2014,
    // maxValidYear: 2021,
};

function sum(values, col) {
    var sum = 0;
    values.forEach(function (value) { sum += value.data[col] });
    return sum;
}

function sumP(values, col) {
    let lenght = 0;
    var sum = 0;
    values.forEach(function (value) { sum += value.data[col]; lenght++ });

    // console.log(sum,' ',lenght)
    return (sum / lenght);
}

function Totales(resolucionFiniquitoGrafica, exempleadosAyudateGrafica) {
    let result = []
    resolucionFiniquitoGrafica.map((ele, idx) => {
        let exempleadosAyudat = exempleadosAyudateGrafica[idx][1]
        // console.log(exempleadosAyudateGrafica[idx][1])
        result.push([
            ele[0],
            ele[1] + exempleadosAyudat
        ])
    })
    return result;
}



function agrupador(data, col, groupBy) {
    let arreglo = []
    data.map(ele => arreglo.push(ele.data))

    // data.forEach(function (value) { arreglo = value.data });
    let agrupador = Object.values(arreglo)


    let obj
    obj = arreglo.reduce((total, obj) => {
        let key = obj[groupBy]
        if (!total[key]) {
            total[key] = [];
        }
        let totales = obj[col]
        total[key].push(totales);
        return total;
    }, []);



    let result = Object.keys(obj).map(function (key) {
        let valor = obj[key].reduce((acc, curr) => acc + curr)
        // console.log(valor)
        return [key, valor];
    });
    // console.log('agrupadoragrupadoragrupadoragrupadoragrupador ',result)

    return result;
}

function Total(data, groupBy) {
    // data = data.value.data
    let arreglo = []
    data.map(ele => arreglo.push(ele.data))
    // data.forEach(function (value) { arreglo = value.data });

    let obj
    obj = arreglo.reduce((total, obj) => {
        let key = obj[groupBy];
        if (!total[key]) {
            total[key] = [];
        }
        let dispersionRecargas = obj.dispersionRecargas
        let dispersionNuevos = obj.dispersionNuevos
        total[key].push(dispersionRecargas, dispersionNuevos);
        return total;
    }, []);

    var result = Object.keys(obj).map(function (key) {
        let valor = obj[key].reduce((acc, curr) => acc + curr)
        // console.log(valor)
        return [key, valor];
    });
    // console.log('data de la funcion agrupador arreglo',arreglo)
    // console.log('data de la funcion agrupador obj',obj)
    // console.log('data de la funcion result',result)
    return result;
}
