import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ComposicionPagosService } from '../../../pages/composicionPagos/composicion-pagos.service';
import * as moment from 'moment';
import { DateService } from '../../../shared/date.service';
import { ToastService } from 'src/app/toast.service';
import { exportXlsService } from 'src/app/reportes/services/export-xls.service';

@Component({
  selector: 'app-filtro-composicion-pagos',
  templateUrl: './filtro-composicion-pagos.component.html',
  styleUrls: ['./filtro-composicion-pagos.component.scss'],
})
export class FiltroComposicionPagosComponent implements OnInit {

  selected: { startdDate: moment.Moment, endDate: moment.Moment };
  selected2: { startdDate: moment.Moment, endDate: moment.Moment };

  @Output() mostarSegundoGrafico = new EventEmitter<boolean>(false);
  public statusChange = true
  private accFiltros = [];

  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }

  first = '2014-01-01';

  x = moment().diff(this.first, 'years');

  // maxDate: moment.Moment = moment();
  minDate: moment.Moment = moment().subtract(this.x + 1, 'years');

  public Filters = {
    filtros: [],
  }

  filtros = [
    {
      val: 'Creditos',
      value: 'iCreditId'
    },
    {
      val: 'Nombre',
      value: 'vName'
    },
    // {
    //   val: 'Apellido',
    //   value: 'vApellido'
    // },
    {
      val: 'Gestor',
      value: 'vGestor'
    },
    {
      val: 'Cambio Exmpleado',
      value: 'Fecha_Cambio_Exempleado'
    },
    {
      val: 'Movement Date',
      value: 'vLMovementDateO'
    },
    {
      val: 'Convenio Original',
      value: 'vConvenio_Original'
    },
    {
      val: 'Empresa Original',
      value: 'vPerCon_Original'
    },
    {
      val: 'Convenio Actual',
      value: 'vConvenio_Actual'
    },
    {
      val: 'Gestion Cobranza',
      value: 'iDias_Inactividad,vFecha_ConGes,vComentarios_ConGes,vGestorId'
    },
    {
      val: 'Info laboral',
      value: 'ultimo_empleo,Fecha_inicio,Fecha_fin,Nombre,Fecha_actualizacion,company_name,IMSSError,Curp'
    },
    {
      val: 'Importe de pagos',
      value: 'totalAdministrativo,totalExtrajudicial'
    },
    {
      val: 'Reasignacion Gestor',
      value: 'asignacion_Principal,condiciones_Credito'
    },
  ]


  constructor(
    private _composicionPagosService: ComposicionPagosService,
    private dateService: DateService,
    private toastS: ToastService,
    private exportXls: exportXlsService,
  ) { }

  ngOnInit() { }

  radioChecked(evt) {
    var target = evt.target.value;

    this._composicionPagosService.agrupadores = target

  }
  async ionchenge(e) {
    let value = e.target.checked
    this.statusChange = value
    this.mostarSegundoGrafico.emit(value);

  }

  radioCheckedDate(evt) {
    var target = evt.target.value;
    this._composicionPagosService.typeGroup = target
  }

  onChangeFilter(event: any) {
    let optionSelected = event['detail']['value'];
    this._composicionPagosService.filtro = optionSelected

    // if (!this._composicionPagosService.fechaInicio || !this._composicionPagosService.fechafin){
    //   // this._composicionPagosService.DatePayment = false
    // }
    // this._composicionPagosService.enviarMensaje();
  }

  changeExmpleadosMovent(event: any) {
    let sDate = event.startDate?._d
    let eDate = event.endDate?._d
    if (sDate) {
      sDate = this.dateService.getDateFormat(sDate)
      this._composicionPagosService.fechaInicio = sDate
    }
     if (eDate) {
      eDate = this.dateService.getDateFormat(eDate)
      this._composicionPagosService.fechafin = eDate;
    } 

    if (!sDate && !eDate) {
      this._composicionPagosService.paymentDateI = ''
      this._composicionPagosService.paymentDateF = ''
    }

  }

  generateExcel() {
    // this.reportesService.generateExcel()
    // this.exportXls.generateExcel()
    this.exportXls.generateExcel( 'Composicion pagos');
  }

  paymentDate(event: any) {
    let sDate = event.startDate?._d
    let eDate = event.endDate?._d

    console.log(sDate, eDate)
    if (sDate) {
      sDate = this.dateService.getDateFormat(sDate)
      this._composicionPagosService.paymentDateI = sDate
    }
     if (eDate) {
      eDate = this.dateService.getDateFormat(eDate)
      this._composicionPagosService.paymentDateF  = eDate;
    } 
    if (!sDate && !eDate) {
      this._composicionPagosService.paymentDateI = ''
      this._composicionPagosService.paymentDateF = ''
    }
  }

  async applyFilter() {
    let filtro = this._composicionPagosService.filtro ? this._composicionPagosService.filtro : []
    let genral = [this._composicionPagosService.typeGroup, this._composicionPagosService.agrupadores, this._composicionPagosService.fechaInicio, this._composicionPagosService.fechafin, this._composicionPagosService.paymentDateF, this._composicionPagosService.paymentDateI]

    let accFil = [filtro, genral]
    this._composicionPagosService.searchSet = true

    if (this.accFiltros.length == 0) {

      this.toastS.loaderShow('Obteniendo informacion');
      this.accFiltros = [filtro, genral]
      let data = await this._composicionPagosService.getData();

      console.log('this._composicionPagosService.getData()', data)

      data.code = 200  && this.toastS.loaderHide();
      // this._composicionPagosService.enviarToatalesGraficaSubject.subscribe(response => {
      //   // if (response) {
      //   //   console.log('se cierra el loader del true', response['code'] == 200)
      //   //   setTimeout(() => {
      //   //     this.toastS.loaderHide();
      //   //   }, 1000);
      //   // }
      // })
    } else {

      let a = this.accFiltros.map((ele, idx) => ele.join('') == accFil[idx].join(''))
      if (a.includes(false)) {
        this.toastS.loaderShow('Obteniendo informacion');
        this.accFiltros = []
        console.log('false')
        this.accFiltros = [filtro, genral]
        let data = await this._composicionPagosService.getData();
        console.log('this._composicionPagosService.getData()',data)
        data.code = 200 && this.toastS.loaderHide();
        // this._composicionPagosService.enviarToatalesGraficaSubject.subscribe(response => {
        //   if (response) {

        //     console.log('se cierra el loader del true', response['code'] == 200)
        //     setTimeout(() => {
        //       this.toastS.loaderHide();
        //     }, 10000);
        //   }
        // })
      }
    }
  }

}
