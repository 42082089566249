import { Component, OnInit } from '@angular/core';
// import { IDoesFilterPassParams, IFilterParams } from 'ag-grid-community';
// import {AgFilterComponent} from 'ag-grid-angular';

import { AgFloatingFilterComponent } from 'ag-grid-angular';

import { IFloatingFilterParams } from 'ag-grid-community';

import * as data from '../../../../../shared/reglasSemaforizacion.json';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-color-filter',
  templateUrl: './color-filter.component.html',
  styleUrls: ['./color-filter.component.scss'],
})
export class ColorFilterComponent implements AgFloatingFilterComponent {

  params;
  year: string = 'All';
  rowsData = new Array();
  currentValue: Number | null | string = null;
  public colorReglas;
  public filtro
  public filtros
  

  constructor() {
    this.colorReglas = data.data
    this.filtros = [
      {
        value: 'red',
        val: 'Rojo'
      },
      {
        value: 'amber',
        val: 'Amarillo'
      },
      {
        value: 'green',
        val: 'Verde'
      },
    ]
  }

  agInit(params: IFloatingFilterParams): void {
    this.params = HttpParams
  }

  onParentModelChanged(parentModel: any) {
    // When the filter is empty we will receive a null value here
    if (!parentModel) {
      this.currentValue = null;
    } else {
      this.currentValue = parentModel.filter;
    }
  }

  filter(colId, value) {
    let color
    let less, greater

    // .match(/[0-9]*\.[0-9]*/g);
    // less = num[1].replace(/\D/g,'').trim();
    this.colorReglas.forEach(element => {
      if (element.Campo == colId) {
        if (value == 'amber') {
          color = element[value];
          let num = color.split('&')
          less = num[1].match(/[0-9]*\.[0-9]*/g);
          greater = num[0].match(/[0-9]*\.[0-9]*/g);
        } else {
          color = element[value];
          color = color.match(/[0-9]*\.[0-9]*/g);
        }

      }
    });
    this.currentValue = Number(color);


    if (value == 'red') {
      // console.log('colorcolorcolor',this.params)
      // console.log('colorcolorcolor',this.params.parentFilterInstance)

      this.params.parentFilterInstance((instance: any) => {
        instance.onFloatingFilterChanged('lessThan',this.currentValue);
      });

    //   {
    //     filterType: 'number',
    //     type: 'inRange',
    //     filter: 35,
    //     filterTo: 40
    // }

    } else if (value == 'amber') {
      let filtroCampo = this.params.api.getFilterInstance(colId)!;
      
      greater = Number(greater);
      less = Number(less);

      filtroCampo.setModel({
        condition1: {
          type: 'greaterThan',
          filter: greater,
          filterTo: null,
        },
        operator: 'and',
        condition2: {
          type: 'lessThan',
          filter: less,
          filterTo: null,
        },
      });

      this.params.api.onFilterChanged()
      
            
    } else if (value == 'green') {



      this.params.parentFilterInstance((instance: any) => {
        instance.onFloatingFilterChanged('lessThan', this.currentValue);
      });
    }

  }

  filterDiasApertura(colId, value) {
    let color
    let less, greater;

    // .match(/[0-9]*\.[0-9]*/g);
    // less = num[1].replace(/\D/g,'').trim();
    this.colorReglas.forEach(element => {
      if (element.Campo == colId) {
        if (value == 'amber') {
          color = element[value];
          let num = color.split('&')
          less = num[1].match(/[0-9]*\.[0-9]*/g);
          greater = num[0].match(/[0-9]*\.[0-9]*/g);
        } else {
          color = element[value];
          color = color.match(/[0-9]*\.[0-9]*/g);
        }

      }
    });
    this.currentValue = Number(color);


    if (value == 'red') {

      this.params.parentFilterInstance((instance: any) => {
        instance.onFloatingFilterChanged('greaterThan', this.currentValue);
      });

    } else if (value == 'amber') {
      let filtroCampo = this.params.api.getFilterInstance(colId)!;
      
      greater = Number(greater);
      less = Number(less);

      filtroCampo.setModel({
        condition1: {
          type: 'greaterThan',
          filter: greater,
          filterTo: null,
        },
        operator: 'and',
        condition2: {
          type: 'lessThan',
          filter: less,
          filterTo: null,
        },
      });

      this.params.api.onFilterChanged()
    } else if (value == 'green') {
      this.params.parentFilterInstance((instance: any) => {
        instance.onFloatingFilterChanged('lessThan', this.currentValue);
      });
    }
  }


  onInputBoxChanged(value) {
    let colid = this.params.column.colId
    if (value == 'Limpiar') {
      this.params.parentFilterInstance((instance: any) => {
        instance.onFloatingFilterChanged(null, null);
      });
      return;
    } else if (colid == 'Cumplimiento Meta') {
      this.filter(colid, value)
    } else if (colid == 'Tasa Total') {
      this.filter(colid, value)
    } else if (colid == 'Dispersión Promedio') {
      this.filter(colid, value)
    } else if (colid == 'Plazo Promedio') {
      this.filter(colid, value)

    } else if (colid == 'porcentaje cartera') {
      this.colorReglas = data.semaforizacionComposicionPagos
      this.filters(colid, value)

    } else if (colid == 'DiasAperturadoPonderadoPromedio') {
      this.filterDiasApertura(colid, value)
      // this.currentValue = Number(5);
      // this.params.parentFilterInstance((instance: any) => {
      //   instance.onFloatingFilterChanged('greaterThan', this.currentValue);
      // });
    }

  }

  filters(colId, value){


    let color
    let less, greater

    // .match(/[0-9]*\.[0-9]*/g);
    // less = num[1].replace(/\D/g,'').trim();
    this.colorReglas.forEach(element => {
      if (element.Campo == colId) {
        if (value == 'amber') {
          color = element[value];
          let num = color.split('&')
          less = num[1].match(/[0-9]*\.[0-9]*/g);
          greater = num[0].match(/[0-9]*\.[0-9]*/g);
        } else {
          color = element[value];
          color = color.match(/[0-9]*\.[0-9]*/g);
        }

      }
    });
    this.currentValue = Number(color);

    if (value == 'red') {

      this.params.parentFilterInstance((instance: any) => {
        instance.onFloatingFilterChanged('greaterThan', this.currentValue);
      });

      //   {
      //     filterType: 'number',
      //     type: 'inRange',
      //     filter: 35,
      //     filterTo: 40
      // }

    } else if (value == 'amber') {
      let filtroCampo = this.params.api.getFilterInstance(colId)!;

      greater = Number(greater);
      less = Number(less);

      filtroCampo.setModel({
        condition1: {
          type: 'greaterThan',
          filter: greater,
          filterTo: null,
        },
        operator: 'and',
        condition2: {
          type: 'lessThan',
          filter: less,
          filterTo: null,
        },
      });

      this.params.api.onFilterChanged()


    } else if (value == 'green') {


      this.params.parentFilterInstance((instance: any) => {
        instance.onFloatingFilterChanged('lessThan', this.currentValue);
      });
    }


  }

}


// reglas.filter(ele => {
//   if (ele.Campo == campo) {
//       // console.log(params);
//       let red = ele.red.replaceAll('x', params)
//       let amber = ele.amber.replaceAll('x', params)
//       let green = ele.green.replaceAll('x', params)
//       // console.log('red', red)
//       clascs = eval(red) ? 'rag-red' : eval(amber) ? 'rag-amber' : eval(green) ? 'rag-green' : ''

//   }
// })




// import { Component, OnInit } from '@angular/core';
// import { IDoesFilterPassParams, IFilterParams } from 'ag-grid-community';
// import {AgFilterComponent} from 'ag-grid-angular';
// import * as data from '../../../../shared/reglasSemaforizacion.json';

// @Component({
//   selector: 'app-color-filter',
//   templateUrl: './color-filter.component.html',
//   styleUrls: ['./color-filter.component.scss'],
// })
// export class ColorFilterComponent implements AgFilterComponent {
//   params;
//   year: string = 'All';
//   rowsData  =  new Array();
//   filtro: string = 'All';
//   public colorReglas;
//   filtros = [
//     {
//         val: 'Rojo',
//         value: 'Rojo'
//     },
//     {
//         val: 'Amarillo',
//         value: 'Amarillo'
//     },
//     {
//         val: 'Verde',
//         value: 'Verde'
//     },
//   ]

//   constructor() { 
//     this.colorReglas = data.data
//     console.log('hola')
//   }

//   agInit(params: IFilterParams): void {
//     this.params = params

//     console.log('hola agInit')
//     console.log('ColorFilterComponent params',this.params)
//   }

//   isFilterActive(): boolean {
//     console.log('isFilterActive')
//     return true;
//   }

//   doesFilterPass(params: IDoesFilterPassParams): boolean {
//     console.log('doesFilterPass',params)
//     return true;
//     // return params.data.year >= 2010;
//   }

//   getModel() {}

//   setModel(model: any) {}

//   updateFilter() {
//     console.log('updateFilter',this.params)
//     this.params.filterParams.filterChangedCallback();
//   }

//   onChangeFilter(){
//     console.log('onChangeFilter', this.filtro)
//   }

// }



