import { Component, OnInit } from '@angular/core';
import { ITooltipAngularComp } from 'ag-grid-angular';
import { ITooltipParams } from 'ag-grid-community';
import { ComposicionPagosService } from 'src/app/reportes/pages/composicionPagos/composicion-pagos.service';

@Component({
  selector: 'app-custom-header-composicionpagos',
  templateUrl: './custom-header-composicionpagos.component.html',
  styleUrls: ['./custom-header-composicionpagos.component.scss'],
})
export class CustomHeaderComposicionpagosComponent implements ITooltipAngularComp {


  bajas

  private params: { campo: string } & ITooltipParams;
  constructor(

    private _composicionPagosService: ComposicionPagosService,
  ) { 
    this.bajas = this._composicionPagosService.bajas
    console.log('customHeader ===================>', this.bajas);
  }

  ngOnInit() {}

  agInit(params: { campo: string } & ITooltipParams): void {
    console.log('customHeader', { params });
   
  }
}
