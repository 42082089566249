import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ReportesService } from '../../../services/reportes.service';
import { ToastService } from 'src/app/toast.service';

import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexYAxis,
  ApexPlotOptions,
  ApexResponsive,
  ApexXAxis,
  ApexLegend,
  ApexFill,
  ApexTooltip,
  ApexStroke,
  ApexGrid
} from "ng-apexcharts";
import { DateService } from '../../../shared/date.service';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  responsive: ApexResponsive[];
  xaxis: ApexXAxis;
  legend: ApexLegend;
  yaxis: ApexYAxis | ApexYAxis[];
  tooltip: ApexTooltip | ApexTooltip[];
  fill: ApexFill;
  stroke: ApexStroke;
  grid: ApexGrid;
};


@Component({
  selector: 'app-grafico-colocacion',
  templateUrl: './grafico-colocacion.component.html',
  styleUrls: ['./grafico-colocacion.component.scss'],
})
export class GraficoColocacionComponent implements OnInit {

  @ViewChild("chart") chart: ChartComponent;
  @Input() showGraficoAcction: boolean;
  // public totalyaxis;
  public chartOptions: Partial<ChartOptions>;
  private initDate: string;
  private Finaldate: string;

  constructor(
    private repservice: ReportesService,
    private dateService: DateService,
    private toastS: ToastService
  ) {
    // this.toastS.loaderShow('Obteniendo informacion');
  }

  data: any;

  arraySeries = [];


  ngOnInit() {
    this.initDate = this.dateService.FinTreceMeses;
    this.Finaldate = this.dateService.getFinaldate();

    // console.log( 'hola' ,this.repservice.data);

    this.repservice.enviarToatalesGraficaSubject.subscribe(response => {
      // console.log('si hay respuesda desa el grafico', response)
      if (response) {
        this.data = response
        this.getArrayApex()
        // this.toastS.loaderHide();
      } else {

        // this.showComponents = false;
      }

      // if (response) {
      //   this.data = response
      //   this.getArrayApex()
      // }
    })

    // this.repservice.getsDataTable(this.initDate, this.Finaldate)
    //   .subscribe((resp: Colocacion) => {
    //     if (resp['code'] == 200) {

    //       this.getArrayApex()
    //     }
    //   });

    this.chartOptions = {
      series: [...this.arraySeries],

      chart: {
        type: "line",
        height: 350,
        width: '100%',
        stacked: true,
        background: '#fff',
        fontFamily: 'ayudate',
        foreColor: '#151618',
        redrawOnParentResize: true,
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 400,
          animateGradually: {
            enabled: true,
            delay: 150
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350
          }
        },
      },

      stroke: {
        curve: "smooth"
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          horizontal: false
        }
      },

      xaxis: {
        type: "category",
        categories: []
      },
      yaxis: [
        {
          decimalsInFloat: 2
        }

      ],
      legend: {
        position: "right",
        offsetY: 40
      },
      fill: {
        opacity: 1
      },
    };
  }


  getArrayApex() {
    let creditosNuevos = this.getDataNuevos(this.data);
    let creditosRecargas = this.getDataRecargas(this.data);
    let totalCreditosNuevosRecargas = this.getTotalCreditosNuevosRecargas(this.data);
    let metasMeses = this.getDataMeta(this.data);
    let categorias = this.getCategories(this.data);

    let totalyaxis = this.mathYaxis(creditosNuevos, creditosRecargas, totalCreditosNuevosRecargas, metasMeses)
    // console.log('creditosNuevos',creditosNuevos)
    // console.log('creditosRecargas',creditosRecargas)
    // console.log('totalCreditosNuevosRecargas',totalCreditosNuevosRecargas)
    // console.log('metasMeses',metasMeses)
    this.arraySeries = [
      {
        name: 'Monto Créditos Nuevos',
        type: 'column',
        data: creditosNuevos,
        color: '#3476E4'
      },
      {
        name: 'Monto Créditos recargas',
        type: 'column',
        data: creditosRecargas,
        color: '#00CEFF'
      },
      {
        name: 'Total',
        type: 'radialBar',
        data: totalCreditosNuevosRecargas,
        color: '#ffd000'
      },
      {
        name: 'Meta',
        type: 'line',
        data: metasMeses,
        color: '#05db8e'
      }
    ];
    // console.log('arraySeries', this.arraySeries);

    this.chartOptions = {

      series: [...this.arraySeries],
      chart: {
        type: "line",
        height: '350px',
        width: '100%',
        stacked: true,
        background: '#fff',
        fontFamily: 'ayudate',
        foreColor: '#151618',
        redrawOnParentResize: true,
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 400,
          animateGradually: {
            enabled: true,
            delay: 150
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350
          }
        },
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false,
        },

      },

      stroke: {
        curve: "smooth"
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],

      plotOptions: {
        bar: {
          horizontal: false
        },
      },
      xaxis: {
        type: "category",
        categories: categorias
      },
      yaxis: [
        {
          decimalsInFloat: 2,
          max: totalyaxis,
          labels: {
            formatter: labelFormatter,
          }
        }
      ],
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {

          let creditosN = moneyFormatMxn(creditosNuevos[dataPointIndex])
          let creditosR = moneyFormatMxn(creditosRecargas[dataPointIndex])
          let total = moneyFormatMxn(totalCreditosNuevosRecargas[dataPointIndex])
          let metas = moneyFormatMxn(metasMeses[dataPointIndex])
          // console.log('..............w', w)
          return (
            '<div class="header-toltip">' + '<span >' + w.globals.categoryLabels[dataPointIndex] + '</span>' + '</div>' +
            '<ul>' +
            '<li class="li-toltip-dark-blue"><span class="span-tooltip"> ' + w.globals.seriesNames[0] + ': ' + creditosN + '</span></li>' +

            '<li class="li-toltip-blue"><span class="span-tooltip"> ' + w.globals.seriesNames[1] + ': ' + creditosR + '</span></li>' +

            '<li class="li-toltip-yellow"><span class="span-tooltip"> ' + w.globals.seriesNames[2] + ': ' + total + '</span></li>' +

            '<li class="li-toltip-green"><span class="span-tooltip"> ' + w.globals.seriesNames[3] + ': ' + metas + '</span></li>' +
            '</ul>'
            // '<div class="arrow_box"> <div class="circulo"> </div>' +'<span>' + w.globals.seriesNames[0] + ': ' + creditosNuevos[dataPointIndex].toFixed(2) + '</span> ' + '</div>' +
            // '<div class="arrow_box">' + '<span>' + w.globals.seriesNames[1] + ': ' + creditosRecargas[dataPointIndex].toFixed(2) + '</span>' + '</div>' +
            // '<div class="arrow_box">' + '<span>' + w.globals.seriesNames[2] + ': ' + totalCreditosNuevosRecargas[dataPointIndex].toFixed(2) + '</span>' + '</div>' +
            // '<div class="arrow_box">' + '<span>' + w.globals.seriesNames[3] + ': ' + metasMeses[dataPointIndex].toFixed(2) + '</span>' + '</div>'
          )
        }
      },

      legend: {
        position: "right",
        offsetY: 40
      },
      fill: {
        opacity: 1
      },




    };



    // return arraySeries;

  }

  mathYaxis(creditosNuevos, creditosRecargas, totalCreditosNuevosRecargas, metasMeses) {
    let Cn = Math.max(...creditosNuevos);
    let Cr = Math.max(...creditosRecargas);
    let Tn = Math.max(...totalCreditosNuevosRecargas);
    let Mm = Math.max(...metasMeses);

    let suma = Math.max(Cn, Cr, Tn, Mm)

    let val = parseInt(suma.toString().split('').shift()) + 1
    let longitud = Math.round(suma).toString().length
    // Math.round(      
    let result = val.toString().padEnd(longitud, '0')

    return parseInt(result)
  }

  getCategories(array) {
    let nuevo = array
    let arrayMetas = [];
    let f;
    nuevo.forEach(element => {
      f = element.yearMonth
      return arrayMetas.push(f);
    });
    return arrayMetas;
  }

  getDataNuevos(array) {
    // console.log('khmartinez**************************',array)
    let nuevo = array
    let arrayCreditosNuevos = [];
    let f;
    nuevo.forEach(element => {
      f = element.dispersionNuevos

      return arrayCreditosNuevos.push(f);
    });
    return arrayCreditosNuevos;
  }

  getDataRecargas(array) {
    let nuevo = array
    let arrayCreditosRecargas = [];
    let f;
    nuevo.forEach(element => {
      f = element.dispersionRecargas
      return arrayCreditosRecargas.push(f);
    });
    return arrayCreditosRecargas;
  }

  getDataMeta(array) {
    // console.log('arrayyyyyyyyy',array)
    let nuevo = array
    // console.log('nuevoooooooooo',nuevo)
    let arrayMetas = [];
    let f;
    nuevo.forEach(element => {
      f = element.meta
      return arrayMetas.push(f);
    });
    return arrayMetas;
  }

  getTotalCreditosNuevosRecargas(array) {
    let nuevo = array
    let arrayMetas = [];
    let f;
    nuevo.forEach(element => {
      f = element.Total
      return arrayMetas.push(f);
    });
    return arrayMetas;
  }
}

let labelFormatter = function (value) {
  // console.log('labelFormatter',value)
  let val 
  val = Math.abs(value);
  if (val >= 1000000) {
    val = (val / 1000000).toFixed(1) + " M";
  }
  return val;
};


function moneyFormatMxn(cant) {
  const formatter = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      minimumFractionDigits: 2
  });
  return formatter.format(cant);
}