import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ComposicionPagosService } from 'src/app/reportes/pages/composicionPagos/composicion-pagos.service';
import { DateService } from 'src/app/reportes/shared/date.service';


import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexYAxis,
  ApexPlotOptions,
  ApexResponsive,
  ApexXAxis,
  ApexLegend,
  ApexFill,
  ApexTooltip,
  ApexStroke,
  ApexGrid
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  responsive: ApexResponsive[];
  xaxis: ApexXAxis;
  legend: ApexLegend;
  yaxis: ApexYAxis | ApexYAxis[];
  tooltip: ApexTooltip | ApexTooltip[];
  fill: ApexFill;
  stroke: ApexStroke;
  grid: ApexGrid;
};

@Component({
  selector: 'app-grafico-composicion-pagos',
  templateUrl: './grafico-composicion-pagos.component.html',
  styleUrls: ['./grafico-composicion-pagos.component.scss'],
})
export class GraficoComposicionPagosComponent implements OnInit {


  @ViewChild("chart") chart: ChartComponent;
  @Input() showGrafico: boolean;
  // public totalyaxis;
  public chartOptions: Partial<ChartOptions>;
  private initDate: string;
  private Finaldate: string;
  data: any;
  arraySeries = [];

  constructor(
    private _composicionPagosService: ComposicionPagosService,
    private dateService: DateService
  ) { }

  ngOnInit() {

    this.initDate = this.dateService.FinTreceMeses;
    this.Finaldate = this.dateService.getFinaldate();

    this._composicionPagosService.enviarToatalesGraficaSubject.subscribe(response => {
      if (response) {
        this.data = response
        this.getArrayApex()
      } 
    })


    this.chartOptions = {
      series: [...this.arraySeries],

      chart: {
        type: "line",
        height: '300px',
        width: '100%',
        stacked: true,
        background: '#fff',
        fontFamily: 'ayudate',
        foreColor: '#151618',
        redrawOnParentResize: true,
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 400,
          animateGradually: {
            enabled: true,
            delay: 150
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350
          }
        },
      },

      stroke: {
        curve: "smooth"
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0
            },
            chart: {
              height: 250
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          horizontal: false
        }
      },

      xaxis: {
        type: "category",
        categories: []
      },
      yaxis: [
        {
          decimalsInFloat: 2
        }

      ],
      legend: {
        position: "right",
        offsetY: 40
      },
      fill: {
        opacity: 1
      },
    };
  }

  getArrayApex() {
    let totalSaldoBaja = this.getData(this.data, 'totalSaldoBaja');
    let totalCobranzaAdministrativa = this.getData(this.data, 'totalCobranzaAdministrativa');
    let totalCobranzaExtrajudicial = this.getData(this.data, 'totalCobranzaExtrajudicial');
    let totalNK_Pendiente = this.getData(this.data, 'totalNK_Pendiente');
    
    let categorias = this.getCategories(this.data);

  //   let totalyaxis = this.mathYaxis(creditosNuevos, creditosRecargas, totalCreditosNuevosRecargas, metasMeses)
    this.arraySeries = [
      {
        name: 'Saldo total baja',
        type: 'column',
        data: totalSaldoBaja,
        color: '#3476E4'
      },
      {
        name: 'NK_Pendiente total',
        type: 'column',
        data: totalNK_Pendiente,
        color: '#00CEFF'
      },
      {
        name: 'Cobranza total administrativa',
        type: 'column',
        data: totalCobranzaAdministrativa,
        color: '#05db8e'
      },
      {
        name: 'Cobranza total extrajudicial',
        type: 'column',
        data: totalCobranzaExtrajudicial,
        color: '#ffd000'
      },
    ];

    this.chartOptions = {

      series: [...this.arraySeries],
      chart: {
        type: "line",
        height: '300px',
        width: '100%',
        stacked: true,
        background: '#fff',
        fontFamily: 'ayudate',
        foreColor: '#151618',
        redrawOnParentResize: true,
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 400,
          animateGradually: {
            enabled: true,
            delay: 150
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350
          }
        },
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false,
        },

      },

      stroke: {
        curve: "smooth"
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },

      responsive: [
        {
          breakpoint: 500,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0
            },
            chart: {
              height: 350
            }
          }
        },
        {
          breakpoint: 600,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0
            },
            chart: {
              height: 320
            }
          }
        },
        {
          breakpoint: 992,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0
            },
            chart: {
              height: 450
            }
          }
        },
        {
          breakpoint: 1200,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0
            },
            chart: {
              height: 350
            }
          }
        }
      ],

      plotOptions: {
        bar: {
          horizontal: false
        },
      },
      xaxis: {
        type: "category",
        categories: categorias
      },
      yaxis: [
        {
          decimalsInFloat: 2,
          // max: totalyaxis,
          // labels: {
          //   formatter: labelFormatter,
          // }
        }
      ],
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {

          let nTotalSaldoBaja = moneyFormatMxn(totalSaldoBaja[dataPointIndex])
          let ntotalNK_Pendiente = moneyFormatMxn(totalNK_Pendiente[dataPointIndex])
          let ntotalCobranzaAdministrativa = moneyFormatMxn(totalCobranzaAdministrativa[dataPointIndex])
          let ntotalCobranzaExtrajudicial = moneyFormatMxn(totalCobranzaExtrajudicial[dataPointIndex])
          
          return (
            '<div class="header-toltip">' + '<span >' + w.globals.categoryLabels[dataPointIndex] + '</span>' + '</div>' +
            '<ul>' +
            '<li class="li-toltip-dark-blue"><span class="span-tooltip"> ' + w.globals.seriesNames[0] + ': ' + nTotalSaldoBaja + '</span></li>' +

            '<li class="li-toltip-blue"><span class="span-tooltip"> ' + w.globals.seriesNames[1] + ': ' + ntotalNK_Pendiente + '</span></li>' +

            '<li class="li-toltip-green"><span class="span-tooltip"> ' + w.globals.seriesNames[2] + ': ' + ntotalCobranzaAdministrativa + '</span></li>' +

            '<li class="li-toltip-yellow"><span class="span-tooltip"> ' + w.globals.seriesNames[3] + ': ' + ntotalCobranzaExtrajudicial + '</span></li>' +
            '</ul>'
            // '<div class="arrow_box"> <div class="circulo"> </div>' +'<span>' + w.globals.seriesNames[0] + ': ' + creditosNuevos[dataPointIndex].toFixed(2) + '</span> ' + '</div>' +
            // '<div class="arrow_box">' + '<span>' + w.globals.seriesNames[1] + ': ' + creditosRecargas[dataPointIndex].toFixed(2) + '</span>' + '</div>' +
            // '<div class="arrow_box">' + '<span>' + w.globals.seriesNames[2] + ': ' + totalCreditosNuevosRecargas[dataPointIndex].toFixed(2) + '</span>' + '</div>' +
            // '<div class="arrow_box">' + '<span>' + w.globals.seriesNames[3] + ': ' + metasMeses[dataPointIndex].toFixed(2) + '</span>' + '</div>'
          )
        }
      },

      legend: {
        position: "right",
        offsetY: 40
      },
      fill: {
        opacity: 1
      },




    };



    // return arraySeries;
    

  }

  getData(array, columna) {
    let nuevo = array
    let arrayCreditosNuevos = [];
    let f;
    nuevo.forEach(element => {
      f = element[columna]

      return arrayCreditosNuevos.push(f);
    });
    return arrayCreditosNuevos;
  }

  getCategories(array) {
    let nuevo = array
    let arrayMetas = [];
    let f;
    nuevo.forEach(element => {
      f = element.yearMonth
      return arrayMetas.push(f);
    });
    return arrayMetas;
  }

}



function moneyFormatMxn(cant) {
  const formatter = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
    minimumFractionDigits: 2
  });
  return formatter.format(cant);
}

