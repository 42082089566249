import { Injectable } from '@angular/core';
import { combineLatest } from 'rxjs';
import { HttpService } from '../http.service';
import { StorageService } from '../storage.service';


@Injectable({
  providedIn: 'root'
})
export class CatalogosService {

  constructor(private http:HttpService,private storage:StorageService) { }

  async cargarCatalogos(){
    let catalogos = await combineLatest([
      this.http.get('catalogos/bancos'),
      this.http.get('catalogos/periocidades_pagos'),
      this.http.get('catalogos/tipos_contrato'),
      this.http.get('catalogos/estados')
    ]).toPromise();
    this.storage.setData('bancos',catalogos[0]['data']);
    this.storage.setData('periocidades_pagos',catalogos[1]['data']);
    this.storage.setData('tipos_contrato',catalogos[2]['data']);
    this.storage.setData('estados',catalogos[3]['data']);
  }
  async cargarMunicipios(estado:number):Promise<any>{
    return await this.http.get('catalogos/municipios',{estado_id:estado}).toPromise();
  }
  async cargarColonias(estado:number,municipio:number):Promise<any>{
    return await this.http.get('catalogos/colonias',{estado_id:estado,municipio_id:municipio}).toPromise();
  }
  async cargarSepomex(cp:string):Promise<any>{
    return await this.http.get('catalogos/sepomex',{cp}).toPromise();
  }
  async cargarSepomexId(id:number):Promise<any>{
    return await this.http.get('catalogos/sepomex_id',{id}).toPromise();
  }

  async cargarSepomexByAdressZell(state:string, city:string, township:string):Promise<any>{
    return await this.http.get('catalogos/sepomex_adress_zell',{state, city, township}).toPromise();
  }
}
