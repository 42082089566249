import { Component, OnInit } from '@angular/core';
import { MenuController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { StorageService } from './storage.service';
import { ToastService } from './toast.service';
import { MenuService } from './menu.service';
import { LoginService } from './services/login.service';
import { CatalogosService } from './services/catalogos.service';
import { SolicitudesService } from './services/solicitudes.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public signed: boolean;
  public recarga: boolean;
  public ocrScanner: boolean;
  public link_externo: boolean;
  public isReport: boolean;
  public appPages: Array<Object>;
  public step: Object;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private location: Location,
    private router: Router,
    private storage: StorageService,
    private toast: ToastService,
    private menu: MenuController,
    private loginS: LoginService,
    private solicitudesS: SolicitudesService,
    private catalogosS: CatalogosService,
    public menuS: MenuService
  ) {
    this.ocrScanner = false;
    this.menuS.nombre_completo = "";
    this.menuS.correo = "";
    this.menuS.titleModule = "";
    this.menuS.step = null;
    this.recarga = false;
    this.link_externo = false;
  }

  ngOnInit() {
    this.initializeApp();
  }

  private initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.router.events.subscribe(path => {
        if (path instanceof NavigationEnd) {
          this.loader();
        }
      });
    });
  }

  private loader(): void {

    this.signed = (
      this.location.path() != '/login_user' &&
      this.location.path() != '/login_admin' &&
      this.location.path() != '/registro' &&
      this.location.path() != '/solicitud' &&
      this.location.path() != '/solicitud-recarga' &&
      this.location.path() != '/confirmacion-referenciador' &&
      this.location.path() != '/recomienda-gana' &&
      this.location.path() != '/recomendador' &&
      !this.location.path().startsWith('/encuesta-credito?EXT=') &&
      !this.location.path().startsWith('/solicitud?EXT=') &&
      !this.location.path().startsWith('/solicitud-recarga?EXT=') &&
      !this.location.path().startsWith('/solicitud?RYG=') &&
      !this.location.path().startsWith('/reporte') &&
      !this.location.path().startsWith('/reporte/col')
    );
    this.link_externo = (
      this.location.path().startsWith('/solicitud?EXT=') ||
      this.location.path().startsWith('/solicitud?RYG=')
    );

    this.recarga = (this.location.path().startsWith('/solicitud-recarga?EXT='));
    // this.isReport = (this.location.path().startsWith('/reporte'));
    // this.isReport = (this.location.path().startsWith('/reporte/col'));
    this.loadData();
    this.loadPages();
    this.loadTitles();
    this.loadCatalogos();
  }
  private sessionExistDataEmpty(auth, solicitante): boolean {
    return (auth != undefined && auth != '') &&
      (solicitante == undefined || solicitante == '') &&
      (this.menuS.correo == undefined || this.menuS.correo == '');
  }
  private sessionExistDataExist(auth, solicitante): boolean {
    return (auth != undefined &&
      auth != '' &&
      solicitante != undefined &&
      solicitante != '');
  }
  async setDataMenu(solicitante, solicitudes) {
    this.menuS.correo = solicitante['correo'];
    this.menuS.nombre_completo = solicitante['nombre'] + ' ' +
      solicitante['apellido_paterno'] + ' ' +
      solicitante['apellido_materno'];
    if (solicitudes != undefined && solicitudes != '' && solicitudes.length > 0) {
      if (solicitudes[0]['step'] == "CONTRATO FIRMADO") {
        this.menuS.step = { formulario: 1, nav: 7 };
      } else {
        this.menuS.step = JSON.parse(solicitudes[0]['step']);
      }
    }
  }
  async loadData() {
    let auth = await this.storage.getData('auth');
    let solicitante = await this.storage.getData('solicitante');
    let solicitudes = await this.storage.getData('solicitudes');
    if (this.sessionExistDataEmpty(auth, solicitudes)) {
      this.toast.loaderShow('Cargando información');
      await this.solicitudesS.cargarDatosCliente();
      solicitante = await this.storage.getData('solicitante');
      solicitudes = await this.storage.getData('solicitudes');
      this.setDataMenu(solicitante, solicitudes);
      this.toast.loaderHide();
    } else if (this.sessionExistDataExist(auth, solicitudes)) {
      this.setDataMenu(solicitante, solicitudes);
    }
  }
  private loadCatalogos() {
    this.catalogosS.cargarCatalogos();
  }
  private loadTitles(): void {
    this.appPages.forEach((v, i) => {
      if (this.location.path() == v['url']) {
        this.menuS.modulo_seleccionado = i;
        this.menuS.submodulo_seleccionado = -1;
        this.menuS.titleModule = v['title'];
      }
      if (v['appModules'] != undefined) {
        v['appModules'].forEach((v2, i2) => {
          if (this.location.path() == v2['url']) {
            this.menuS.modulo_seleccionado = i;
            this.menuS.submodulo_seleccionado = i2;
            this.menuS.titleModule = v2['title'];
            this.appPages[i]['show'] = true;
          }
        });
      }
    });
  }
  async closeSessionCliente() {
    this.toast.loaderShow('Cerrando sesión');
    this.menuS.correo = '';
    this.menuS.nombre_completo = '';
    await this.loginS.cerrarSesionCliente();
    this.toast.loaderHide();
    this.router.navigate(['/login_user']);
  }
  public showSubmodules(): void {
    this.appPages.forEach((v, i) => {
      if (this.menuS.modulo_seleccionado != i && v['appModules'] != undefined) {
        v['show'] = false;
      }
    });
    this.appPages[this.menuS.modulo_seleccionado]['show'] = !this.appPages[this.menuS.modulo_seleccionado]['show'];
  }
  public disabledSolicitudModules(module: number, submodule: number): boolean {
    return (
      (
        this.appPages[module] != undefined &&
        this.appPages[module]['appModules'] != undefined &&
        this.menuS.step != undefined &&
        this.menuS.step['nav'] <= submodule
      ) ||
      (
        this.appPages[module] != undefined &&
        this.appPages[module]['appModules'] != undefined &&
        this.menuS.step == undefined && submodule > 0
      ));
  }
  public menuStep(j: number) {
    return this.menuS != null && this.menuS.step != null && (j + 1) < this.menuS.step['nav'];
  }
  public clickPage(i: number, submodule?: boolean) {
    this.menuS.modulo_seleccionado = i;
    this.showSubmodules();
    if (!submodule) {
      this.menu.close();
      this.menuS.titleModule = this.appPages[i]['title'];
    }
  }
  public clickModule(i: number, j: number) {
    this.menuS.submodulo_seleccionado = j;
    this.menu.close();
    this.menuS.titleModule = this.appPages[i]['appModules'][j]['title'];
  }
  private loadPages(): void {
    this.appPages = [
      {
        title: 'Principal',
        url: '/principal',
        icon: 'home-outline',
        show: false
      },
      {
        title: 'Pide tu crédito',
        url: this.recarga ? '/solicitud_recarga' : '/solicitud_nuevo',
        icon: 'card-outline',
        show: false,
        appModules: [
          {
            title: 'Información personal',
            url: '/solicitud/info-personal',
            icon: 'briefcase-outline',
          },
          {
            title: 'Información empleo',
            url: '/solicitud/info-empleo',
            icon: 'people-outline',
          },
          {
            title: 'Nómina',
            url: '/solicitud/nomina',
            icon: 'document-outline',
          },
          {
            title: 'Otros',
            url: '/solicitud/otros',
            icon: 'card-outline',
          },
          {
            title: 'Condiciones del crédito',
            url: '/solicitud/credito',
            icon: 'cash-outline',
          },
          {
            title: 'Firma digital',
            url: '/solicitud/firma',
            icon: 'pencil-outline',
          },
          {
            title: 'Archivos',
            url: '/solicitud/archivos',
            icon: 'document-attach-outline',
          }
        ]
      },
      {
        title: 'Adelanta tu nomina',
        url: '/adelanta_nomina',
        icon: 'cash-outline',
        show: false
      },
      {
        title: 'Recomienda y gana',
        url: '/recomienda-gana',
        icon: 'rocket-outline',
        show: false
      },
      {
        title: 'Universidad Ayúdate',
        url: '/educacion_financiera',
        icon: 'book-outline',
        show: false
      },
      {
        title: 'Estado de cuenta',
        url: '/estado_cuenta',
        icon: 'document-text-outline',
        show: false
      },
    ];
  }
}