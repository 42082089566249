import { Component, OnInit } from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';


@Component({
  selector: 'app-number-cells',
  templateUrl: './number-cells.component.html',
  styleUrls: ['./number-cells.component.scss'],
})
export class NumberCellsComponent implements OnInit, ICellRendererAngularComp {
  cellData: any;
  params: ICellRendererParams;
  number: string;
  constructor() { }

  ngOnInit() {}

  agInit(params: ICellRendererParams){
    this.params = params;
    if (params.colDef.headerName == 'Plazo Promedio'){
      this.number = params.data.plazoPonderadoPromedio == null ? this.numberFormat(0) : this.numberPrecision(params.data.plazoPonderadoPromedio);
    }else if (params.colDef.field == 'diasAperturaPonderadoPromedio'){
      // console.log('params.colDef ',params.colDef)
      // console.log('Tasa Total',params.data.diasAperturaPonderadoPromedio )
      this.number = params.data.diasAperturaPonderadoPromedio == null ? this.numberFormat(0) :this.numberPrecision( params.data.diasAperturaPonderadoPromedio);
    }else if (params.colDef.headerName == 'tasa Ponderada'){
      this.number = params.data.tasaPonderada == null ? this.numberFormat(0) : this.numberFormat(params.data.tasaPonderada);
    }else if (params.colDef.headerName == 'numero Creditos'){
      this.number = params.data.numeroCreditos == null ? this.numberFormat(0) : this.numberFormat(params.data.numeroCreditos);
    }else if (params.colDef.headerName == 'plazo Ponderado'){
      this.number = params.data.plazoPonderado == null ? this.numberFormat(0) : this.numberFormat(params.data.plazoPonderado);
    } else if (params.colDef.headerName == 'Maxima Dispersion'){
      this.number = params.data.MaxDispersion == null ? this.numberFormat(0) : this.numberFormat(params.data.MaxDispersion);
    }

    //cartera Componets
    else if (params.colDef.headerName == 'Numero Creditos'){      
      // console.log('colDef',params.data.numeroCreditos)
      // console.log()
      if(params.data.numeroCreditos ===''){
        this.number = params.data.numeroCreditos == null ? this.numberFormat(0) : this.numberFormat(params.data.percentafaormat);
        
      }else{
        this.number = params.data.numeroCreditos == null ? this.numberFormat(0) : this.numberFormat(params.data.numeroCreditos);

      }
    } else if (params.colDef.colId == 'plazoPonderadoPromedio'){
      this.number = params.data.plazoPonderadoPromedio == null ? this.numberFormat(0) : this.numberFormat(params.data.plazoPonderadoPromedio);
    }


    //recarga
    
    else if (params.colDef.headerName == 'Id Personal'){
      this.number = params.data.iPersonId == null ? 0 : params.data.iPersonId;
    } else if (params.colDef.headerName == 'Importe'){
      this.number = params.data.importe == null ? this.numberFormat(0) : this.numberFormat(params.data.importe);
    } else if (params.colDef.headerName == 'Plazo'){
      this.number = params.data.iPlazo == null ? 0 : params.data.iPlazo;
    } else if (params.colDef.headerName == 'Monto liquidar'){
      this.number = params.data.nMonto_Liquidar == null ? this.numberFormat(0) : this.numberFormat(params.data.nMonto_Liquidar);
    } else if (params.colDef.headerName == 'Id credito'){
      this.number = params.data.iCreditId == null ? 0 : params.data.iCreditId;
    } else if (params.colDef.headerName == 'Sol zell'){
      this.number = params.data.solicitud_Recarga_zell == null ? 0 : params.data.solicitud_Recarga_zell;
    } 


    else if (params.colDef.headerName == 'Importe credito'){
      this.number = params.data.nImporte_Credito == null ? this.numberFormat(0) : this.numberFormat(params.data.nImporte_Credito) ;
    } 
    
    
    if (params.colDef.headerName == 'Saldo baja'){
      this.number = params.data.saldoBaja == null ? this.numberFormat(0) : this.numberFormat(params.data.saldoBaja) ;
    } 
    if (params.colDef.headerName == 'Total Administrativo'){
      this.number = params.data.totalAdministrativo == null ? this.numberFormat(0) : this.numberFormat(params.data.totalAdministrativo) ;
    } 
    if (params.colDef.headerName == 'Capital administrativa'){
      this.number = params.data.cobranzaAdministrativa == null ? this.numberFormat(0) : this.numberFormat(params.data.cobranzaAdministrativa) ;
    } 
    if (params.colDef.headerName == 'Capital extrajudicial'){
      this.number = params.data.cobranzaExtrajudicial == null ? this.numberFormat(0) : this.numberFormat(params.data.cobranzaExtrajudicial) ;
    } 
    if (params.colDef.headerName == 'Total Extrajudicial'){
      this.number = params.data.totalExtrajudicial == null ? this.numberFormat(0) : this.numberFormat(params.data.totalExtrajudicial) ;
    } 
    if (params.colDef.headerName == 'NK pendiente'){
      this.number = params.data.nK_Pendiente == null ? this.numberFormat(0) : this.numberFormat(params.data.nK_Pendiente) ;
    } 
    if (params.colDef.headerName == 'Dias de inactividad'){
      this.number = params.data.iDias_Inactividad == null ? this.numberFormat(0) : this.numberFormat(params.data.iDias_Inactividad) ;
    } 

    // composicion de pago historico

    if (params.colDef.colId == 'aumentoExmpleado') {
      this.number = params.data.aumentoExmpleado == null ? this.numberFormat(0) : this.numberFormat(params.data.aumentoExmpleado);
    }
    if (params.colDef.colId == 'salidaAdministrativa') {
      this.number = params.data.salidaAdministrativa == null ? this.numberFormat(0) : this.numberFormat(params.data.salidaAdministrativa);
    }
    if (params.colDef.colId == 'totalExtrajudicial') {
      this.number = params.data.totalExtrajudicial == null ? this.numberFormat(0) : this.numberFormat(params.data.totalExtrajudicial);
    }
    if (params.colDef.colId == 'totalAdministrativo') {
      this.number = params.data.totalAdministrativo == null ? this.numberFormat(0) : this.numberFormat(params.data.totalAdministrativo);
    }
    if (params.colDef.colId == 'salidaExtrajudicial') {
      this.number = params.data.salidaExtrajudicial == null ? this.numberFormat(0) : this.numberFormat(params.data.salidaExtrajudicial);
    }
    if (params.colDef.colId == 'entrada_exempleado') {
      this.number = params.data.entrada_exempleado == null ? this.numberFormat(0) : this.numberFormat(params.data.entrada_exempleado);
    }
    if (params.colDef.colId == 'nK_Pendiente') {
      this.number = params.data.nK_Pendiente == null ? this.numberFormat(0) : this.numberFormat(params.data.nK_Pendiente);
    }
    if (params.colDef.colId == 'totalExmpleado') {
      this.number = params.data.totalExmpleado == null ? this.numberFormat(0) : this.numberFormat(params.data.totalExmpleado);
    }


    if (params.colDef.colId == 'tasa_interes') {
      this.number = params.data.tasa_interes == null ? this.numberFormat(0) : this.numberFormat(params.data.tasa_interes);
    }
    
    if (params.colDef.colId == 'rh_estatus') {
      this.number = params.data.rh_estatus == null ? this.numberFormat(0) : this.numberFormat(params.data.rh_estatus);
    }
    if (params.colDef.colId == 'monto_max') {
      this.number = params.data.monto_max == null ? this.numberFormat(0) : this.numberFormat(params.data.monto_max);
    }
    if (params.colDef.colId == 'plazo_maximo') {
      this.number = params.data.plazo_maximo == null ? this.numberFormat(0) : this.numberFormat(params.data.plazo_maximo);
    }
    if (params.colDef.colId == 'antiguedad_min') {
      this.number = params.data.antiguedad_min == null ? this.numberFormat(0) : this.numberFormat(params.data.antiguedad_min);
    }
  }
  

  // num.toPrecision(3)
  percentafaormat(monto){
    // monto= String(monto).match(/^\d{1,3}(\.\d{4})*/g)
    return monto+'%'
  }

  numberPrecision(num){
    // console.log('num',typeof(num))
    num= String(num).match(/^\d{1,3}(\.\d{4})*/g)
    return num
  }

  IdFormat(){

  }

  numberFormat(cant){
    const formatter = new Intl.NumberFormat('es-MX', {
      minimumFractionDigits: 2,
      currency:'MXN'
    });
    return formatter.format(cant);
    // Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(monto)
  }
  refresh(params: any): boolean {
    this.cellData = params.value;
    return true;
  }

}
