import { Injectable } from '@angular/core';
//import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { Storage } from '@ionic/storage';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
    private storage:Storage,
    private platform:Platform
  ) { }

  async getData(key:string):Promise<any>{
    if(this.platform.is('cordova')){
      return new Promise(resolve => {
        this.storage.get(key).then((data)=>{
          resolve(data);
        },(error)=>{
          resolve(null);
        });
      });
    }else{
      let data = localStorage.getItem(key);
      return new Promise(resolve => {
        resolve((data!="undefined" && data!=undefined && data!=''?JSON.parse(data):null));
      });
    }
  }
  async setData(key:string,data:Object):Promise<any>{
    if(this.platform.is('cordova')){
      return new Promise(resolve => {
        this.storage.set(key, data).then(()=>{
          resolve(true);
        },(error)=>{
          resolve(null);
        });
      });
    }else{
      return new Promise(resolve => {
        localStorage.setItem(key,JSON.stringify(data));
        resolve(true);
      });
    }
  }
  async removeData(key:string):Promise<any>{
    if(this.platform.is('cordova')){
      return new Promise(resolve => {
        this.storage.remove(key).then(()=>{
          resolve(true);
        },(error)=>{
          resolve(null);
        });
      });
    }else{
      return new Promise(resolve => {
        localStorage.removeItem(key);
        resolve(true);
      });
    }
  }
  public removeAll():void{
    if(this.platform.is('cordova')){
      this.storage.clear();
    }else{
      localStorage.clear();
    }
  }
}
