import { Component, OnInit } from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';


@Component({
  selector: 'app-number-cells-cartera',
  templateUrl: './number-cells-cartera.component.html',
  styleUrls: ['./number-cells-cartera.component.scss'],
})
export class NumberCellsCarteraComponent implements OnInit {
  cellData: any;
  params: ICellRendererParams;
  number: string;
  constructor() { }

  ngOnInit() {}

  agInit(params: ICellRendererParams){
    this.params = params;
    if (params.colDef.headerName == 'Numero Creditos'){      
      // console.log('colDef',params.data.numeroCreditos)
      // console.log()
      if(params.data.numeroCreditos ===''){
        this.number = params.data.numeroCreditos == null ? this.percentafaormat(0) : this.numberFormat(params.data.percentafaormat);
        
      }else{
        this.number = params.data.numeroCreditos == null ? this.numberFormat(0) : this.numberFormat(params.data.numeroCreditos);

      }
    }else if (params.colDef.headerName == 'Plazo Promedio'){
      this.number = params.data.plazoPonderadoPromedio == null ? this.numberFormat(0) : this.numberPrecision(params.data.plazoPonderadoPromedio);
    }
    
  }
  

  // num.toPrecision(3)

  numberPrecision(num){
    // console.log('num',typeof(num))
    num= String(num).match(/^\d{1,3}(\.\d{4})*/g)
    return num
  }
  percentafaormat(monto){
    // monto= String(monto).match(/^\d{1,3}(\.\d{4})*/g)
    return monto+'%'
  }

  numberFormat(cant){
    const formatter = new Intl.NumberFormat('es-MX', {
      minimumFractionDigits: 2,
      currency:'MXN'
    });
    return formatter.format(cant);
    // Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(monto)
  }
  refresh(params: any): boolean {
    this.cellData = params.value;
    return true;
  }
}
