import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { resourceLimits } from 'worker_threads';
import { Colocacion } from '../../../Interface/colocacion';
import { ReportesService } from '../../../services/reportes.service';
import { DateService } from '../../../shared/date.service';
import { DateCellsComponent } from './cells-table/date-cells/date-cells.component';
import { MoneyCellsComponent } from '../../cell-render/money-cells/money-cells.component';
import { NumberCellsComponent } from '../../cell-render/number-cells/number-cells.component';
import { PercentCellsComponent } from '../../cell-render/percent-cells/percent-cells.component';
import { CustomTooltipComponent } from './cells-table/custom-tooltip/custom-tooltip.component';
import { WordsCellsComponent } from '../../cell-render/words-cells/words-cells.component';
import { ColorFilterComponent } from './cells-table/color-filter/color-filter.component'; 

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import * as data from '../../../shared/reglasSemaforizacion.json';
import { type } from 'os';

@Component({
    selector: 'app-tabla-colocacion',
    templateUrl: './tabla-colocacion.component.html',
    styleUrls: ['./tabla-colocacion.component.scss'],
})
export class TablaColocacionComponent implements OnInit {



    public rowClass;
    public getRowClass;
    public rowClassRules;
    public getRowStyle;

    public colorReglas;

    private initDate: string;
    private finalDate: string;
    public year1Date: string;
    public defaultColDef;
    public sideBar;
    // public arrayInfo = Array<Colocacion>();
    public arrayInfo: any = [];
    public pinnedBottomRowData;
    rowHeight: number;
    rowDef;
    chartData;
    params: any;
    gridApi;
    gridColumnApi;
    gridOptions;
    context: any;
    minRowHeight;
    currentRowHeight: any;
    frameworkComponents: any;
    filterSelect: string;
    searchValue: string;
    minWidth: string;
    maxWidth: string;
    public tooltipShowDelay;

    filtros = [
        'empresa',
        'vendedor',
        'supervisor',
        'convenio',
        'organizacion',
        'mercado',
        'sectorProducto',
        'producto'
    ]



    categorias = [
        'organizacion',
        'mercado',
        'sectorProducto',
        'producto'
    ]


    @Input() showGraficoAcction: boolean;
    

    constructor(
        private repservice: ReportesService,
        private dateService: DateService
    ) {
        this.colorReglas = data.data
        // this.vColor = 5.5
        this.context = { componentParent: this };
        this.getConfig();
        this.minWidth = '3px';
        this.maxWidth = '1px';
        this.initDate = this.dateService.getinitDate();
        this.finalDate = this.dateService.getFinaldate();
        this.year1Date = this.dateService.FinTreceMeses;

    }



    ngOnInit() {


        // this.repservice.getREPColocacionTableTotal(this.initDate, this.finalDate)
        //     .subscribe((resp: Colocacion) => {
        //         // this.pinnedBottomRowData = this.createData(resp['data']);
        //     });

        this.repservice.fechaInicio = this.year1Date;
        this.repservice.fechafin = this.finalDate;
        this.repservice.enviarMensaje()
        this.repservice.enviarMensajeSubject.subscribe(response => {

            if (response) {
                let agrupador = response['agrupador']
                this.arrayInfo = response['data']
                this.params.api.setRowData(response['data'])
                this.pinnedBottomRowData = this.createFooterData(this.params);


                // console.log('agrupadoragrupadoragrupadoragrupadoragrupadoragrupadoragrupadoragrupador', agrupador)

                if (agrupador == 'year') {
                    this.params.columnApi.setColumnVisible('year', true)
                    this.params.columnApi.setColumnVisible('yearMonth', false)
                } else {
                    this.params.columnApi.setColumnVisible('yearMonth', true)
                    this.params.columnApi.setColumnVisible('year', false)
                }

                // if (response['categoies']) {
                //     let cotegory = response['categoies']
                //     let regexp = new RegExp('catalogo_empresa.', 'g')
                //     cotegory = cotegory.map(ele => ele.replace(regexp, ''))
                //     this.categorias.map(ele => {                        
                //         if (cotegory.includes(ele)) {                            
                //             this.params.columnApi.setColumnVisible(ele, true)
                //         } else {
                //             this.params.columnApi.setColumnVisible(ele, false)
                //         }
                //     });
                // }

                if (response['filtros']) {
                    let filtro
                    filtro = response['filtros'];
                    console.log('Filtros desde la tabla colocacion', filtro)
                    let regexp = new RegExp('catalogo_empresa.', 'g')
                    filtro = filtro.map(ele => ele.replace(regexp, ''))
                    console.log('Filtros desde la tabla colocacion', filtro)
                    this.filtros.map(ele => {
                        // console.log('Filtros desde la tabla colocacion', ele ,' include',filtro.includes(ele))
                        // console.log('Filtros desde la tabla colocacion', ele)
                        if (filtro.includes(ele)) {
                            this.params.columnApi.setColumnVisible(ele, true)
                        } else {
                            this.params.columnApi.setColumnVisible(ele, false)
                        }
                    });
                }


            }
        })


    }

    public style: any = {
        width: '100% !important',
        height: '45vh',
    };


    ngOnChanges(changes): void {
        console.log('grafico change detected', changes, this.showGraficoAcction)

        if (!this.showGraficoAcction) {
            this.style = {
                width: '100% !important',
                height: '85vh',
            };
        } else {
            this.style = {
                width: '100% !important',
                height: '45vh',
            };
        }
    }

    getConfig() {
        this.rowClass = 'my-blue-class';
        this.getRowClass = params => {
            if (params.node.rowIndex % 2 === 0) {
                return 'my-shaded-effect';
            }
        },
            this.getRowStyle = function (params) {
                if (params.node.rowPinned === 'bottom') {
                    return { "background-color": "red" };
                }
            },
            this.rowDef = this.getColumns();
        this.sideBar = 'filters';
        this.defaultColDef = {
            flex: 1,
            minWidth: 150,
            filter: true,
            floatingFilter: true,
            tooltipComponent:'customTooltip'
        };
        this.rowHeight = 50
        this.frameworkComponents = this.getFrameworkComponents();
        // this.rowClassRules = {
        //     'rag-green': 'data.age < 20',
        //     'rag-amber': 'data.age >= 20 && data.age < 25',
        //     'rag-red': 'data.age >= 25',
        // },
        this.tooltipShowDelay = 0;
        this.gridOptions = {

            isExternalFilterPresent: this.isExternalFilterPresent,
            doesExternalFilterPass: this.doesExternalFilterPass,
            columnDefs: this.rowDef,
            components: {
                moneyCells: MoneyCellsComponent,
                numberCells: NumberCellsComponent,
                percentCell: PercentCellsComponent,
                wordsCell: WordsCellsComponent,
                DateCells: DateCellsComponent,
                customTooltip:CustomTooltipComponent
            },
            // rowClassRules: {

            //     'rag-green': 'data.diasAperturaPonderadoPromedio < 4000',
            //     'rag-amber': 'data.diasAperturaPonderadoPromedio >= 4000',
            // 'rag-red': 'data.age >= 25',

            // "row-fail": params => params.api.getValue("result", params.node) < 60,
            // "row-pass": params => params.api.getValue("result", params.node) >= 60

            // 'bg-red': (params) => {
            //     if (params.rowIndex < 2) {
            //         return true;
            //     }
            // },
            // },
            defaultColDef: {
                filter: true,
                floatingFilter: true,
                resizable: true,
                sortable: true,
                hide: true,
                tooltipComponent:'customTooltip',
            },
        };
        // this.gridApi.setPinnedBottomRowData(['',123,123,4325,2345])
    }

    // region :: create table
    getColumns() {

        // let col
        return [
            {
                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Año / mes',
                field: 'yearMonth',
                cellRenderer: 'DateCells',
                sortable: true,
                resizable: true,
                // filter: 'agDateColumnFilter',
                // filterParams,
                floatingFilter: true,
                hide: false,
                // cellStyle: {color: 'red', 'background-color': 'green'}
            },
            {
                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Año',
                field: 'year',
                cellRenderer: 'DateCells',
                sortable: true,
                resizable: true,
                // filter: 'agDateColumnFilter',
                // filterParams,
                floatingFilter: true,
                hide: true,
                // cellStyle: {color: 'red', 'background-color': 'green'}
            },
            {
                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Empresa',
                field: 'empresa',
                cellRenderer: 'wordsCell',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                hide: true,
            },
            {

                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Convenio',
                field: 'convenio',
                cellRenderer: 'wordsCell',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                hide: true
            },
            {

                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Vendedor',
                field: 'vendedor',
                cellRenderer: 'wordsCell',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                hide: true
            },
            {

                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Supervisor',
                field: 'supervisor',
                cellRenderer: 'wordsCell',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                hide: true
            },
            {
                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Organizacion',
                field: 'organizacion',
                cellRenderer: 'wordsCell',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                hide: true,
                filter: true
            },
            {

                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Mercado',
                field: 'mercado',
                cellRenderer: 'wordsCell',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                hide: true
            },
            {

                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Sector Producto',
                field: 'sectorProducto',
                cellRenderer: 'wordsCell',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                hide: true
            },
            {

                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Producto',
                field: 'producto',
                cellRenderer: 'wordsCell',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                hide: true
            },
            {
                colId: 'dispersionNuevo',
                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Dispersión Créditos Nuevos',
                field: 'dispersionNuevos',
                cellRenderer: 'moneyCells',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
                valueGetter: a1000ValueGetter,
            },
            {
                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Dispersión Créditos Recargas',
                field: 'dispersionRecargas',
                cellRenderer: 'moneyCells',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
            },
            {
                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Dispersión Total',
                field: 'totalDispersado',
                cellRenderer: 'moneyCells',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                // filter: 'agNumberColumnFilter',
            },
            {
                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Compras de deuda',
                field: 'nProvedores',
                cellRenderer: 'moneyCells',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                // filter: 'agNumberColumnFilter',
            },
            {
                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Dispersion mas compras',
                field: 'totalDispersado2',
                cellRenderer: 'moneyCells',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                // filter: 'agNumberColumnFilter',
            },
            {
                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Meta',
                field: 'presupuesto',
                cellRenderer: 'moneyCells',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
            }, 
            {
                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Maxima Dispersion',
                field: 'MaxDispersion',
                cellRenderer: 'numberCells',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
                hide: false
            },
            {        
                colId: 'Cumplimiento Meta',
                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Cumplimiento Meta',
                field: 'cumplimientoMeta',
                cellRenderer: 'percentCell',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                floatingFilterComponent: 'colorFilter',
                filter: 'agNumberColumnFilter',        
                headerTooltip: 'cumplimientoMeta',
                tooltipComponentParams: { campo: 'Cumplimiento Meta', reglas : this.colorReglas},
                cellClass: params => {
                    if (Object.values(params.data).shift() == 'Total') {

                        return params.value;
                    } else {
                        return ragCellClassRules(params.value, this.colorReglas, 'Cumplimiento Meta')
                    }
                }

                // cellClassRules: {
                //     'rag-green': 'x < 20',
                //     'rag-amber': 'x >= 20 && x < 25',
                //     'rag-red': 'x >= 25',
                // },
                // cellClassRules: ragCellClassRules('Cumplimiento Meta',this.colorReglas)
            },
            {
                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Dispersión Promedio',
                colId: 'Dispersión Promedio',
                field: 'dispersionPromedio',
                cellRenderer: 'moneyCells',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
                headerTooltip: 'plazoPonderadoPromedio',
                floatingFilterComponent: 'colorFilter',
                tooltipComponentParams: { campo: 'Dispersión Promedio', reglas : this.colorReglas},
                cellClass: params => {
                    if (Object.values(params.data).shift() == 'Total') {

                        return params.value;
                    } else {
                        return ragCellClassRules(params.value, this.colorReglas, 'Dispersión Promedio')
                    }
                }
            },
            {
                
                colId: 'Tasa Total',
                floatingFilterComponent: 'colorFilter',
                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Tasa Total',
                field: 'tasaPonderadaPromedio',
                cellRenderer: 'percentCell',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
                headerTooltip: 'tasaPonderadaPromedio',
                tooltipComponentParams: { campo: 'Tasa Total', reglas : this.colorReglas},
                cellClass: params => {
                    if (Object.values(params.data).shift() == 'Total') {

                        return params.value;
                    } else {
                        return ragCellClassRules(params.value, this.colorReglas, 'Tasa Total')
                    }
                }
                // cellClassRules: ragCellClassRules('Tasa Total',this.colorReglas)
            },
            {                
                colId: 'Plazo Promedio',
                floatingFilterComponent: 'colorFilter',        
                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Plazo Promedio',
                field: 'plazoPonderadoPromedio',
                cellRenderer: 'numberCells',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
                headerTooltip: 'plazoPonderadoPromedio',
                tooltipComponentParams: { campo: 'Plazo Promedio', reglas : this.colorReglas},
                cellClass: params => {
                    if (Object.values(params.data).shift() == 'Total') {

                        return params.value;
                    } else {
                        return ragCellClassRules(params.value, this.colorReglas, 'Plazo Promedio')
                    }
                }

                // cellClassRules: { 'rag-amber': 'x>1' }
                // cellClassRules: ragCellClassRules('Plazo Promedio',this.colorReglas)
            },
            {        
                colId: 'DiasAperturadoPonderadoPromedio',
                floatingFilterComponent: 'colorFilter',
                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'Días Apertura Ponderado Promedio',
                field: 'diasAperturaPonderadoPromedio',
                cellRenderer: 'numberCells',
                sortable: true,
                resizable: true,               
                filter: 'agNumberColumnFilter',
                headerTooltip: 'diasAperturaPonderadoPromedio',
                tooltipComponentParams: { campo: 'DiasAperturadoPonderadoPromedio', reglas : this.colorReglas},
                // headerTooltip: headerTooltipStyle(this.colorReglas, 'DiasAperturadoPonderadoPromedio'),
                // tooltipField: 'diasAperturaPonderadoPromedio',
                cellClass: params => {
                    if (Object.values(params.data).shift() == 'Total') {

                        return params.value;
                    } else {
                        return ragCellClassRules(params.value, this.colorReglas, 'DiasAperturadoPonderadoPromedio')
                    }
                }
                // cellClassRules: ragCellClassRules('DiasAperturadoPonderadoPromedio',this.colorReglas)
            },
            {
                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'tasa Ponderada',
                field: 'tasaPonderada',
                cellRenderer: 'numberCells',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
                hide: true
            },
            {
                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'numero Creditos',
                field: 'numeroCreditos',
                cellRenderer: 'numberCells',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
                hide: true
            },
            {
                minWidth: this.minWidth,
                maxWidth: this.maxWidth,
                headerName: 'plazo Ponderado',
                field: 'plazoPonderado',
                cellRenderer: 'numberCells',
                sortable: true,
                resizable: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
                hide: true
            }

        ]
    }




    getFrameworkComponents() {
        return {
            moneyCells: MoneyCellsComponent,
            numberCells: NumberCellsComponent,
            percentCell: PercentCellsComponent,
            wordsCell: WordsCellsComponent,
            DateCells: DateCellsComponent,
            customTooltip:CustomTooltipComponent,
            colorFilter:ColorFilterComponent,
        };
    }

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }



    onFilterChanged(params: GridOptions): void {
        // console.log(this.numberOfRows = params.api?.getDisplayedRowCount());
        // console.log('hola')
    }

    onGridReady(params) {
        this.params = params;
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.minRowHeight = params.api.getSizesForCurrentTheme().rowHeight;
        this.currentRowHeight = this.minRowHeight;
        params.api.sizeColumnsToFit();

    }

    // createData(obj) {

    //     var result = [];
    //     let totaldispersionNuevos = obj[0].totalDispersado;
    //     let totaldispersionRecargas = obj[0].dispersionRecargas;
    //     let totaltotalDispersado = obj[0].totalDispersado;
    //     let totalpresupuesto = obj[0].presupuesto;
    //     let totaldispersionPromedio = obj[0].dispersionPromedio;
    //     let totalplazoPonderadoPromedio = obj[0].plazoPonderadoPromedio;
    //     let totaldiasAperturaPonderadoPromedio = obj[0].diasAperturaPonderadoPromedio;
    //     let totaltasaPonderadaPromedio = obj[0].tasaPonderadaPromedio;
    //     for (var i = 0; i < 1; i++) {
    //         result.push({
    //             empresa: 'Total',

    //             dispersionNuevos: totaldispersionNuevos,
    //             dispersionRecargas: totaldispersionRecargas,
    //             totalDispersado: totaltotalDispersado,
    //             presupuesto: totalpresupuesto,
    //             dispersionPromedio: totaldispersionPromedio,
    //             plazoPonderadoPromedio: totalplazoPonderadoPromedio,
    //             tasaPonderadaPromedio: totaltasaPonderadaPromedio,
    //             diasAperturaPonderado: totaldiasAperturaPonderadoPromedio
    //         });
    //     }
    //     return result;
    // }

    createFooterData(gridApi?, event?: GridOptions) {
        let result = [];
        let model;
        let visibleRows = [];
        let dataGrafico = [];

        // console.log('este es el vento de los filtros', event)



        if (gridApi) {
            model = gridApi.columnApi.columnModel.gridApi.rowModel;
            visibleRows = model.rowsToDisplay;
        }
        let groupBy = this.repservice.agrupadores;
        let totalTablaDispersionNuevos = agrupador(visibleRows, 'dispersionNuevos', groupBy)
        let totalTablaDispersionRecargas = agrupador(visibleRows, 'dispersionRecargas', groupBy)
        let totalTablaPresupuesto = agrupador(visibleRows, 'presupuesto', groupBy)
        let totalCreditosNuevosRecargas = Total(visibleRows, groupBy)


        let grafica = []
        totalTablaDispersionNuevos.map((curr, indx) => {
            if (grafica) {
                grafica.push({
                    "yearMonth": curr[0],
                    "dispersionNuevos": totalTablaDispersionNuevos[indx][1],
                    'dispersionRecargas': totalTablaDispersionRecargas[indx][1],
                    'meta': totalTablaPresupuesto[indx][1],
                    'Total': totalCreditosNuevosRecargas[indx][1],
                })
            }
        })
        this.repservice.enviarToatalesGraficaSubject.next(grafica)


        let totalTasaPonderada = sum(visibleRows, 'tasaPonderada')
        let totalDispersado = sum(visibleRows, 'totalDispersado')
        let nProvedores = sum(visibleRows, 'nProvedores')
        let totalDispersado2 = sum(visibleRows, 'totalDispersado2')
        let numeroCreditos = sum(visibleRows, 'numeroCreditos')
        let plazoPonderado = sum(visibleRows, 'plazoPonderado')
        let diasAperturaPonderado = sum(visibleRows, 'diasAperturaPonderado')

        //dispersionPromedio => suma(totalDispersado) / suma(numeroCreditos)
        let dispersionPromedio = (totalDispersado / numeroCreditos);
        dispersionPromedio = isNaN(dispersionPromedio) == true ? 0 : dispersionPromedio

        //tasaPonderadaPromedio => suma(tasaPonderada) / suma(totalDispersado)
        let tasaPonderadaPromedio = (totalTasaPonderada / totalDispersado)
        tasaPonderadaPromedio = isNaN(tasaPonderadaPromedio) == true ? 0 : tasaPonderadaPromedio

        //plazoPonderadoPromedio =>  suma(plazoPonderado)/ suma(totalDispersado)
        let plazoPonderadoPromedio = (plazoPonderado / totalDispersado)
        plazoPonderadoPromedio = isNaN(plazoPonderadoPromedio) == true ? 0 : plazoPonderadoPromedio

        //diasAperturaPonderadoPromedio => suma(diasAperturaPonderado)/suma(totalDispersado)
        let diasAperturaPonderadoPromedio = (diasAperturaPonderado / totalDispersado)
        diasAperturaPonderadoPromedio = isNaN(diasAperturaPonderadoPromedio) == true ? 0 : diasAperturaPonderadoPromedio

        let presupuesto = this.getComisionTotal(visibleRows, 'presupuesto');
        let cump = (totalDispersado / presupuesto) * 100
        let cumplimientoMeta = !isFinite(cump)  ? 0 : cump
        // console.log(totalDispersado , 'infinity',presupuesto)
        // console.log('infinity',cumplimientoMeta)
        // console.log('infinity',isFinite(cump))

        result.push({
            yearMonth: 'Total',
            year: 'Total',
            dispersionNuevos: this.getComisionTotal(visibleRows, 'dispersionNuevos'),
            dispersionRecargas: this.getComisionTotal(visibleRows, 'dispersionRecargas'),
            totalDispersado,
            nProvedores,
            totalDispersado2,
            presupuesto,
            cumplimientoMeta,
            dispersionPromedio,
            tasaPonderadaPromedio,
            plazoPonderadoPromedio,
            diasAperturaPonderadoPromedio
        });
        // console.log('result:', result);
        this.pinnedBottomRowData = result;
        return result;
    }

    getComisionTotal(visibleRows, filtro) {
        // let fil = filtro
        let total;
        let resul;
        total = visibleRows.reduce((acc, cur) => { acc += cur.data[filtro]; return acc; }, 0)
        resul = isNaN(total) == true ? 0 : total;
        return parseInt(resul, 0);
    }


    isExternalFilterPresent() {
        return filterWord !== '';
    }

    doesExternalFilterPass(node) {
        let cellData;
        cellData = node.data.estatusRecomendados;
        switch (filterWord) {
            case '0':
                return cellData == filterWord;
            case '1':
                return cellData == filterWord;
            case '2':
                return cellData == filterWord;
            case '3':
                return cellData == filterWord;
            default:
                return node;
        }
    }

}

function headerTooltipStyle(reglas, campo) {
    let headerToolti
    let redRule,amberRule,greenRule;

    reglas.forEach(element => {
        if(element.Campo == campo){
            redRule     = element.red
            amberRule   = element.amber
            greenRule   = element.green
        }        
    });

    return  ` <div class="custom-tooltip" [style.background-color]="color">
    <p>
      <span>{{ data.athlete }}</span>
    </p>
    <p><span>Country: </span>{{ data.country }}</p>
    <p><span>Total: </span>{{ data.total }}</p>
  </div>`
    return (
        '<div class="header-toltip">' + '<span >  Reglas </span>' + '</div>' +
        '<ul>' +
        '<li class="rag-red"><span class="span-tooltip"> Rojo: ' + redRule + '</span></li>' +

        '<li class="rag-amber"><span class="span-tooltip"> Amarilo: $' + amberRule + '</span></li>' +

        '<li class="rag-green"><span class="span-tooltip"> Verde: $' + greenRule + '</span></li>' +

        '</ul>'
        // '<div class="arrow_box"> <div class="circulo"> </div>' +'<span>' + w.globals.seriesNames[0] + ': ' + creditosNuevos[dataPointIndex].toFixed(2) + '</span> ' + '</div>' +
        // '<div class="arrow_box">' + '<span>' + w.globals.seriesNames[1] + ': ' + creditosRecargas[dataPointIndex].toFixed(2) + '</span>' + '</div>' +
        // '<div class="arrow_box">' + '<span>' + w.globals.seriesNames[2] + ': ' + totalCreditosNuevosRecargas[dataPointIndex].toFixed(2) + '</span>' + '</div>' +
        // '<div class="arrow_box">' + '<span>' + w.globals.seriesNames[3] + ': ' + metasMeses[dataPointIndex].toFixed(2) + '</span>' + '</div>'
      )
    // return headerToolti
    // console.log('ragCellClassRules2',params,'  ', reglas)

}

function ragCellClassRules(params, reglas, campo) {
    let clascs
    reglas.filter(ele => {
        if (ele.Campo == campo) {
            // console.log(params);
            let red = ele.red.replaceAll('x', params)
            let amber = ele.amber.replaceAll('x', params)
            let green = ele.green.replaceAll('x', params)
            // console.log('red', red)
            clascs = eval(red) ? 'rag-red' : eval(amber) ? 'rag-amber' : eval(green) ? 'rag-green' : ''

        }
    })

    return clascs
    // console.log('ragCellClassRules2',params,'  ', reglas)

}



let filterWord = '';

var filterParams = {
    comparator: function (filterLocalDateAtMidnight, cellValue) {
        var dateAsString = cellValue;
        if (dateAsString == null) return -1;
        var dateParts = dateAsString.split('-');
        var cellDate = new Date(
            Number(dateParts[0]),
            Number(dateParts[1]) - 1,
        );

        let date = new Date(filterLocalDateAtMidnight)
        let yyyy = date.getFullYear()
        let dd = ('0' + (date.getMonth() + 1)).slice(-2)

        let ndate = ` ${yyyy}-${dd}`;
        filterLocalDateAtMidnight = new Date(ndate)

        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
            return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
            return 1;
        }
    },
    browserDatePicker: true,
    minValidYear: 2014,
    // maxValidYear: 2021,
};

function sum(values, col) {
    var sum = 0;
    values.forEach(function (value) { sum += value.data[col] });
    return sum;
}

function agrupador(data, col, groupBy) {
    // data = data.value.data
    let arreglo = []
    data.map(ele => arreglo.push(ele.data))
    // data.forEach(function (value) { arreglo = value.data });
    let agrupador = Object.values(arreglo)
    // console.log('totalTablaDispersionNuevostotalTablaDispersionNuevostotalTablaDispersionNuevos',Object.values(arreglo))
    // if(obj['yearMonth']){

    // }
    let obj
    obj = arreglo.reduce((total, obj) => {
        let key = obj[groupBy]
        if (!total[key]) {
            total[key] = [];
        }
        let totales = obj[col]
        total[key].push(totales);
        return total;
    }, []);

    var result = Object.keys(obj).map(function (key) {
        let valor = obj[key].reduce((acc, curr) => acc + curr)
        // console.log(valor)
        return [key, valor];
    });
    // console.log('data de la funcion agrupador arreglo',arreglo)
    // console.log('data de la funcion agrupador obj',obj)
    // console.log('data de la funcion result',result)
    return result;
}

function Total(data, groupBy) {
    // data = data.value.data
    let arreglo = []
    data.map(ele => arreglo.push(ele.data))
    // data.forEach(function (value) { arreglo = value.data });

    let obj
    obj = arreglo.reduce((total, obj) => {
        let key = obj[groupBy];
        if (!total[key]) {
            total[key] = [];
        }
        let dispersionRecargas = obj.dispersionRecargas
        let dispersionNuevos = obj.dispersionNuevos
        total[key].push(dispersionRecargas, dispersionNuevos);
        return total;
    }, []);

    var result = Object.keys(obj).map(function (key) {
        let valor = obj[key].reduce((acc, curr) => acc + curr)
        // console.log(valor)
        return [key, valor];
    });
    // console.log('data de la funcion agrupador arreglo',arreglo)
    // console.log('data de la funcion agrupador obj',obj)
    // console.log('data de la funcion result',result)
    return result;
}

var a1000ValueGetter = function (params) {
    // console.log('params',params.columnApi.getValueColumns())
    // console.log('params',params.api['columnModel']['rowModel']['rowsToDisplay'])
    // return params.data.a + ' 1000'; 
    // var total = params.getValue('dispersionNuevo');
    // console.log('params',total)
    // console.log('params',params)
   return  1000;
  };

