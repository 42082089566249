import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarteraComponent } from './cartera.component';

//estilo
import { IonicModule } from '@ionic/angular';

//Componets
import { FiltrosCarteraComponent } from '../../components/cartera/filtros-cartera/filtros-cartera.component';
import { GraficoCarteraComponent } from '../../components/cartera/grafico-cartera/grafico-cartera.component';
import { TablaCarteraComponent } from '../../components/cartera/tabla-cartera/tabla-cartera.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//CellRender
import { MoneyCellsCarteraComponent } from '../../components/cartera/tabla-cartera/cells-table-cartera/money-cells-cartera/money-cells-cartera.component';
import { NumberCellsCarteraComponent } from '../../components/cartera/tabla-cartera/cells-table-cartera/number-cells-cartera/number-cells-cartera.component';
import { PercentCellsCarteraComponent } from '../../components/cartera/tabla-cartera/cells-table-cartera/percent-cells-cartera/percent-cells-cartera.component';
import { WordsCellsCarteraComponent } from '../../components/cartera/tabla-cartera/cells-table-cartera/words-cells-cartera/words-cells-cartera.component';
import { DateCellsCarteraComponent } from '../../components/cartera/tabla-cartera/cells-table-cartera/date-cells-cartera/date-cells-cartera.component';
import { ColorCellsCarteraComponent } from '../../components/cartera/tabla-cartera/cells-table-cartera/color-cells-cartera/color-cells-cartera.component';
import { CustomTooltipCellsCarteraComponent } from '../../components/cartera/tabla-cartera/cells-table-cartera/custom-tooltip-cells-cartera/custom-tooltip-cells-cartera.component';

//Otras importaciones
import { AgGridModule } from 'ag-grid-angular';
import { NgApexchartsModule } from 'ng-apexcharts';
import { HammerModule } from '@angular/platform-browser';
import { NwCalendarModule } from 'nw-calendar';
import { WordsCellsComponent } from '../../components/cell-render/words-cells/words-cells.component';
import { PercentCellsComponent } from '../../components/cell-render/percent-cells/percent-cells.component';
import { NumberCellsComponent } from '../../components/cell-render/number-cells/number-cells.component';


const cellComponent = [
  // MoneyCellsCarteraComponent,
  NumberCellsCarteraComponent,
  // PercentCellsCarteraComponent,
  // WordsCellsCarteraComponent,
  DateCellsCarteraComponent,
  ColorCellsCarteraComponent,
  CustomTooltipCellsCarteraComponent
];

@NgModule({
  declarations: [
    CarteraComponent,
    FiltrosCarteraComponent,
    GraficoCarteraComponent,
    TablaCarteraComponent,
    cellComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    FormsModule,
    HammerModule,
    NwCalendarModule,
    NgxDaterangepickerMd.forRoot(),
    AgGridModule.withComponents(
      [
        // PercentCellsCarteraComponent,
        // WordsCellsCarteraComponent,
        // MoneyCellsCarteraComponent,
        NumberCellsComponent,
        WordsCellsComponent,
        PercentCellsComponent,
        
        CustomTooltipCellsCarteraComponent,
        NumberCellsCarteraComponent,
        DateCellsCarteraComponent,
        ColorCellsCarteraComponent,
        CustomTooltipCellsCarteraComponent
      ]),
  ],
  exports: [
    CarteraComponent,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class CarteraModule { }
