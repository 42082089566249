import { Injectable } from '@angular/core';
import * as data from '../shared/reglasSemaforizacion.json';
import { ReportesService } from './reportes.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class ReporteExcelService {

  private dataXls;
  private reglasSemaforizacion;
  constructor(private reportesService: ReportesService) {
    this.reglasSemaforizacion = data.data
  }


  

  generateExcel() {
    this.dataXls = this.reportesService.xlsData
    // console.log('ReporteExcelService', this.reportesService.xlsData)
    // console.log(this.reglasSemaforizacion, this.dataXls)
    let data = []
    let filtros
    // console.log('generateExcel ',this.dataXls)
    filtros = [
      'empresa',
      'vendedor',
      'supervisor',
      'convenio',
      'catalogo_empresa.organizacion',
      'catalogo_empresa.mercado',
      'catalogo_empresa.sectorProducto',
      'catalogo_empresa.producto'
    ]

    let header = [
      "Dispersion creditos nuevos",
      "Dispersion creditos Recargas",
      "Dispersion Total",
      "Meta",
      "Cumplimiento Meta",
      "Dispersion Promedio",
      "Tasa Total",
      "Plazo Promedio",
      "Dias Apertura Ponderado Promedio"
    ]

    
    if (this.reportesService.filtro) {
      // this.filtro.map(ele => )
      // this.filtro.map(ele => this.filtro)
      // console.log(this.dataXls)
      // console.log(this.filtro)
      let info
      info = this.dataXls[0]
      filtros = filtros.reverse()
      filtros.map(ele => {
        if (info.hasOwnProperty(ele)) {
          ele = ele.replace('catalogo_empresa.', '')
          header.unshift(ele)
        }
      })
    }
    header.unshift(this.reportesService.agrupadores)
    // console.log('header',header)

    console.log(this.dataXls)
    this.dataXls.forEach(d => {

      let convenio, vendedor, supervisor, organizacion, mercado, sectorProducto, producto
      convenio = this.validateNull(d, 'convenio')
      vendedor = this.validateNull(d, 'vendedor')
      supervisor = this.validateNull(d, 'supervisor')
      organizacion = this.validateNull(d, 'catalogo_empresa.organizacion')
      mercado = this.validateNull(d, 'catalogo_empresa.mercado')
      sectorProducto = this.validateNull(d, 'catalogo_empresa.sectorProducto')
      producto = this.validateNull(d, 'catalogo_empresa.producto')

      data.push([
        d.yearMonth || null,
        d.year || null,
        d.empresa || null,
        vendedor || null,
        supervisor || null,
        convenio || null,
        organizacion || null,
        mercado || null,
        sectorProducto || null,
        producto || null,
        d.dispersionNuevos || 0,
        d.dispersionRecargas || 0,
        d.totalDispersado || 0,
        d.presupuesto || 0,
        d.cumplimientoMeta / 100 || 0,
        d.dispersionPromedio || 0,
        d.tasaPonderadaPromedio / 100 || 0,
        d.plazoPonderadoPromedio || 0,
        d.diasAperturaPonderadoPromedio || 0
      ])
      // console.log('diasAperturaPonderadoPromedio ',d.diasAperturaPonderadoPromedio)
    })

    console.log(data)

    // console.log('generateExcel ', this.dataXls)

    const title = 'Reportes Colocacion';



    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Reportes Colocacion');

    // Add new row
    let titleRow = worksheet.addRow([title]);
    // Set font, size and style in title row.
    titleRow.font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true };
    // Blank Row
    worksheet.addRow([]);
    //Add row with current date
    // let subTitleRow = worksheet.addRow(['Date : ' + new Date(), 'medium']);
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ffffff' },
        bgColor: { argb: 'ffffff' }
      }
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      }
    });



    // console.log('data', data)---------------
    //Eliminar nulos
    data = data.map((ele) => ele.filter(elemnt => {
      // console.log('data elemnt',elemnt)
      if (elemnt == 0) {
        return '0'
      } else if (elemnt === '') {
        return 'N/A'
      } else if (elemnt !== null) {
        return elemnt
      }
    }))

    let headerLenght = header.length
    // console.log('data', data)---------------
    // Add Data and Conditional Formatting
    console.log(header, headerLenght)
    
    console.log(data )
    data.forEach(d => {
      // console.log(d)------------------
      let row = worksheet.addRow(d);

      this.numberFormatter(row, headerLenght)
      this.Color(this.reglasSemaforizacion, row, headerLenght, 'DiasAperturadoPonderadoPromedio')

      this.numberFormatter(row, headerLenght - 1)
      this.Color(this.reglasSemaforizacion, row, headerLenght - 1, 'Plazo Promedio')

      this.percentFormatter(row, headerLenght - 2)
      this.Color(this.reglasSemaforizacion, row, headerLenght - 2, 'Tasa Total')

      // console.log('Dispersión Promedio',d.cumplimientoMeta)
      this.currencyFormatter(row, headerLenght - 3)
      this.Color(this.reglasSemaforizacion, row, headerLenght - 3, 'Dispersión Promedio')

      this.percentFormatter(row, headerLenght - 4)
      this.Color(this.reglasSemaforizacion, row, headerLenght - 4, 'Cumplimiento Meta')

      this.currencyFormatter(row, headerLenght - 5)
      this.currencyFormatter(row, headerLenght - 6)
      this.currencyFormatter(row, headerLenght - 7)
      this.currencyFormatter(row, headerLenght - 8)
      // worksheet.getCell('B1').value = 0.016;
      // worksheet.getCell('B1').numFmt = '0.00%';
      // console.log(row)
    });

    

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Reportes-Colocacion.xlsx');
    });
  }

  validateNull(d, campo) {
    let result
    if (d.hasOwnProperty(campo) && !d[campo]) {
      result = 'N/A'
    } else {
      result = d[campo]
    }
    return result
  }

  numberFormatter(row, headerLenght) {
    let qty = row.getCell(headerLenght);
    return qty.style = {
      numFmt: '0.0000'
    }
  }

  currencyFormatter(row, headerLenght) {
    let qty = row.getCell(headerLenght);
    return qty.style = {
      numFmt: '$#,##0.00_);[Red]($#,##0.00)'
      // numFmt: '#,##0.00_);(#,##0.00)'
    }
  }

  percentFormatter(row, headerLenght) {
    let qty = row.getCell(headerLenght);
    return qty.style = {
      numFmt: '0.0000%'
    }
  }

  Color(reglas, row, headerLenght, campo) {
    let qty = row.getCell(headerLenght);
    let color = '048520';
    let valor
    reglas.filter(ele => {
      if (ele.Campo == campo) {
        if (ele.Campo == 'Tasa Total' || ele.Campo == 'Cumplimiento Meta') {
          // console.log('qtyqtyqtyqtyqtyqty3','qty.value * 100')
          valor = qty.value * 100
        } else {

          valor = qty.value
        }
        // console.log('qtyqtyqtyqtyqtyqty3', valor)

        // console.log(params);
        let red = ele.red.replaceAll('x', valor)
        let amber = ele.amber.replaceAll('x', valor)
        let green = ele.green.replaceAll('x', valor)
        // console.log('red', red)
        color = eval(red) ? color = 'db2323' : eval(amber) ? color = 'ffff00' : eval(green) ? color = '048520' : color

      }
    })

    // return qty.style = {
    //     numFmt: '0.00',
    //     fill:
    //     {
    //         type: 'pattern',
    //         pattern: 'solid',
    //         bgColor: { argb: 'FF00FF00' }
    //     }
    // }
    return qty.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: color }
    }

  }

}
