import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecargaComponent } from './recarga.component';
import { TablaRecargaComponent } from '../../components/recarga/tabla-recarga/tabla-recarga.component';
import { FiltrosRecargaComponent } from '../../components/recarga/filtros-recarga/filtros-recarga.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Ag Grid
import { AgGridModule } from 'ag-grid-angular';

// Cel Render
import { MoneyCellsComponent } from '../../components/cell-render/money-cells/money-cells.component';
import { NumberCellsComponent } from '../../components/cell-render/number-cells/number-cells.component';
import { PercentCellsComponent } from '../../components/cell-render/percent-cells/percent-cells.component';
import { WordsCellsComponent } from '../../components/cell-render/words-cells/words-cells.component';
import { DateCellsComponent } from '../../components/colocacion/tabla-colocacion/cells-table/date-cells/date-cells.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { HammerModule } from '@angular/platform-browser';
import { NwCalendarModule } from 'nw-calendar';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { InputRenderComponent } from '../../components/cell-render/input-render/input-render.component';
import { CellRendercustomComponent } from '../../components/recarga/tabla-recarga/cells-table-recarga/cell-rendercustom/cell-rendercustom.component';
import { TipoMensajeComponent } from '../../components/recarga/tabla-recarga/cells-table-recarga/tipo-mensaje/tipo-mensaje.component';
import { PhonenumberCellsComponent } from '../../components/cell-render/phonenumber-cells/phonenumber-cells.component';
import { AnchorTagCellsComponent } from '../../components/cell-render/anchor-tag-cells/anchor-tag-cells.component';
import { MsjCellComponent } from '../../components/recarga/tabla-recarga/cells-table-recarga/msj-cell/msj-cell.component';
import { ModalQrcodeComponent } from '../../components/recarga/modal-qrcode/modal-qrcode.component';
import { UrlSolicitudRecargaComponent } from '../../components/recarga/tabla-recarga/cells-table-recarga/url-solicitud-recarga/url-solicitud-recarga.component';

const cellComponent = [
  CellRendercustomComponent,
  TipoMensajeComponent,
  MsjCellComponent,
  ModalQrcodeComponent,
  UrlSolicitudRecargaComponent
];

@NgModule({
  declarations: [
    RecargaComponent,
    TablaRecargaComponent,
    FiltrosRecargaComponent,
    cellComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    AgGridModule.withComponents(
      [
        // CustomTooltipComponent,
        MoneyCellsComponent,
        NumberCellsComponent,
        PercentCellsComponent,
        WordsCellsComponent,
        DateCellsComponent,
        InputRenderComponent,
        CellRendercustomComponent,
        TipoMensajeComponent,        
        PhonenumberCellsComponent,
        AnchorTagCellsComponent,
        MsjCellComponent,
        UrlSolicitudRecargaComponent
        // ModalFechaComponent,
        // ColorFilterComponent,
        // CustomTooltipComponent
      ]),
    NgApexchartsModule,
    HammerModule,
    NwCalendarModule,
    NgxDaterangepickerMd.forRoot(),
    
  ],
  exports: [
    RecargaComponent
  ],
  entryComponents: [
    ModalQrcodeComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class RecargaModule { }
