import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Observable, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { environment } from "../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  features: any = {}
  constructor(private http: HttpClient) { }
  getFeatureFlags(): Observable<FeatureFlagResponse> {
    return this.http.get<any>(environment.FeatureFlagURL).pipe(
      tap((features) => this.features = features),
      catchError((error) => {
        console.error('Error fetching feature flags:', error);
        // Retorna un valor por defecto para evitar que la inicialización falle
        return of({
          featureFlag: false
        });
      })
    )
  }
  getFeature(feature: FeatureFlagKeys): boolean {
    console.log(this.features[feature])
    return this.features[feature] || false;
  }
}

type FeatureFlagResponse = {
  featureFlag: boolean;
  newSolicitud: boolean;
  loginUser: boolean;
  guardarVideo: boolean;
  guardarVideoFirma: boolean;
  solicitudVideos: boolean;
  changeEstatusZell: boolean;
  firma: boolean;
  firmaLocation: boolean;
  firmaBitacora: boolean;
  firmaContratoCifrado: boolean;
  firmaNomipay: boolean;
  firmaContratoSinCifrar: boolean;
  RYGRecomendados: boolean;
  RYGRecomendador: boolean;
  RYGPoliticas: boolean;
  RYGCanjearRecompensa: boolean;
  statusZell: boolean;
  changeStatusZell: boolean;
  catalogosGlobales: boolean;
  catalogosMunicipios: boolean;
  catalogosColonias: boolean;
  catalogosSepomex: boolean;
  catalogosSepomexId: boolean;
  catalogosSepomexAdressZell: boolean;
  enominaReserve: boolean;
  enominaConsultarMargen: boolean;
  nomipayReserve: boolean;
  nomipayConsultarFicha: boolean;
  nomipayRegisterContract: boolean;
  nomipayUploadLetterAccept: boolean;
  transcribe: boolean;
  transcribeVideo: boolean;
  transcribeVideoWait: boolean;
  getTransciptionStatus: boolean;
  saveVideoTranscription: boolean;
  getTransciptionStatusWait: boolean;
  loggerModule: boolean;
  saveError: boolean;
  saveDeviceInfo: boolean;
}
type _FeatureFlagKeys = keyof FeatureFlagResponse

export type FeatureFlagKeys = {
  [K in _FeatureFlagKeys]: K;
}[_FeatureFlagKeys]