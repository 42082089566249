import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-composicion-pagos',
  templateUrl: './composicion-pagos.component.html',
  styleUrls: ['./composicion-pagos.component.scss'],
})
export class ComposicionPagosComponent implements OnInit {

  // public showHistGrafico: boolean = true

  @Input() showHistGrafico: boolean = true
  @Input() showGrafico: boolean = true

  constructor() { }

  ngOnInit() {}

  showimage(event){

    this.showHistGrafico = event
  }

  showGraph(event){
    console.log('showGraph', event)

    this.showGrafico = event
  }


}
