import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  titleModule:string;
  step:Object;
  correo:string;
  nombre_completo:string;
  modulo_seleccionado:number;
  submodulo_seleccionado:number;
  tipo_sesion:string;
  constructor() { }
  // chris estuvo aqui

  
}
