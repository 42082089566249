import { Location } from '@angular/common';
import { Route } from '@angular/compiler/src/core';
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanDeactivate, CanLoad, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private http:HttpService,
    private router:Router,
    private location:Location,
    private toast:ToastService
  ){
    
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.checkSession();
    return true;
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    this.checkSession();
    return true;
  }
  checkSession(){
    setTimeout(() => {
      if(this.http==undefined || this.http.headers==undefined){
        this.checkSession();
      }else{
        this.http.get('login/sesion_cliente_existe').subscribe((response)=>{
          if(response['code']==200 && this.location.path()!='' && this.location.path()=='/login_user'){
            this.router.navigate(['/principal']);
          }else if(response['code']!=200 && this.location.path()!=''){
            this.router.navigate(['/login_user']);
          }
        },(error)=>{
          if(this.location.path()!='/login_user' && this.location.path()!=''){
            this.router.navigate(['/login_user']);
          }
        });
      }
    }, 100);
  }
}
