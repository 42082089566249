import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { CarteraService } from './cartera.service';
@Injectable({
  providedIn: 'root'
})
export class CarteraExcelService {


  private dataXls;
  private reglasSemaforizacion;
  constructor(private reportesService: CarteraService) {
  }


  

  generateExcel() {
    this.dataXls = this.reportesService.xlsData
    // console.log('ReporteExcelService', this.reportesService.xlsData)
    // console.log(this.reglasSemaforizacion, this.dataXls)
    let data = []
    let filtros
    // console.log('generateExcel ',this.dataXls)

    
    filtros = [
      'empresa',
      'vendedor',
      'supervisor',
      'convenioActual',
      'catalogo_empresa.organizacion',
      'catalogo_empresa.mercado',
      'catalogo_empresa.sectorProducto',
      'catalogo_empresa.producto'
    ]

    let header = [
      "Numero Creditos",
      "Capital Pendiente",
      "Cartera Sana",
      "%",
      "Resolucion Finiquito Admin",
      "%",
      "Resolucion Finiquito",
      "%",
      "Ex empleados Ayudate",
      "%",
      "Cartera Vendida",
      "%",
      "Difuntos",
      "%",     
      "Plazo ponderado promedio", 
    ]
    
    if (this.reportesService.filtro) {
      // this.filtro.map(ele => )
      // this.filtro.map(ele => this.filtro)
      // console.log(this.dataXls)
      // console.log(this.filtro)
      let info
      info = this.dataXls[0]
      filtros = filtros.reverse()
      filtros.map(ele => {
        if (info.hasOwnProperty(ele)) {
          ele = ele.replace('catalogo_empresa.', '')
          header.unshift(ele)
        }
      })
    }
    header.unshift(this.reportesService.agrupadores)
    console.log('header',header)

    this.dataXls.forEach(d => {

      data.push([
        d.hist || null,
        d.empresa || null,
        d.convenioActual || null,
        d.empresa || null,
        d.vendedor || null,
        d.supervisor || null,
        d.organizacion || null,
        d.mercado || null,
        d.sectorProducto || null,
        d.producto || null,   
        d.numeroCreditos || 0,
        d.capitalPendiente || 0,
        d.carteraSana || 0,
        d.carteraSanaPorcentaje / 100 || 0,
        d.resolucionFiniquitoAdmin  || 0,
        d.resolucionFiniquitoAdminPorcentaje / 100  || 0,
        d.resolucionFiniquito || 0,
        d.resolucionFiniquitoPorcentaje / 100 || 0,
        d.exempleadosAyudate || 0,
        d.exempleadosAyudatePorcentaje / 100 || 0,
        d.carteraVendida || 0,
        d.carteraVendidaPorcentaje / 100 || 0,
        d.difuntos || 0,
        d.difuntosPorcentaje / 100 || 0,



        d.plazoPonderadoPromedio || 0,
      ])
      // console.log('diasAperturaPonderadoPromedio ',d.diasAperturaPonderadoPromedio)
    })
    // console.log('Dispersión Promedio ',data)

    // console.log('generateExcel ', this.dataXls)

    const title = 'Reporte Cartera';



    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Reporte Cartera');

    // Add new row
    let titleRow = worksheet.addRow([title]);
    // Set font, size and style in title row.
    titleRow.font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true };
    // Blank Row
    worksheet.addRow([]);
    //Add row with current date
    // let subTitleRow = worksheet.addRow(['Date : ' + new Date(), 'medium']);
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ffffff' },
        bgColor: { argb: 'ffffff' }
      }
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      }
    });



    // console.log('data', data)---------------
    //Eliminar nulos
    data = data.map((ele) => ele.filter(elemnt => {
      // console.log('data elemnt',elemnt)
      if (elemnt == 0) {
        return '0'
      } else if (elemnt === '') {
        return 'N/A'
      } else if (elemnt !== null) {
        return elemnt
      }
    }))
    console.log(data)

    let headerLenght = header.length
    console.log('data',headerLenght ,data)
    // Add Data and Conditional Formatting
    data.forEach(d => {
      // console.log(d)------------------
      let row = worksheet.addRow(d);

      this.numberFormatter(row, headerLenght)
      // this.Color(this.reglasSemaforizacion, row, headerLenght, 'DiasAperturadoPonderadoPromedio')

      this.percentFormatter(row, headerLenght - 1)
      // this.Color(this.reglasSemaforizacion, row, headerLenght - 1, 'Plazo Promedio')

      this.currencyFormatter(row, headerLenght - 2)
      this.percentFormatter(row, headerLenght - 3)

      this.currencyFormatter(row, headerLenght - 4)
      this.percentFormatter(row, headerLenght - 5)

      this.currencyFormatter(row, headerLenght - 6)
      this.percentFormatter(row, headerLenght - 7)

      this.currencyFormatter(row, headerLenght - 8)
      this.percentFormatter(row, headerLenght - 9)

      this.currencyFormatter(row, headerLenght - 10)
      this.percentFormatter(row, headerLenght - 11)

      this.currencyFormatter(row, headerLenght - 12)
      this.currencyFormatter(row, headerLenght - 13)

      this.currencyFormatter(row, headerLenght - 14)
      this.currencyFormatter(row, headerLenght - 15)
    });

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Reportes-Cartera.xlsx');
    });
  }


  numberFormatter(row, headerLenght) {
    let qty = row.getCell(headerLenght);
    return qty.style = {
      numFmt: '0.0000'
    }
  }

  currencyFormatter(row, headerLenght) {
    let qty = row.getCell(headerLenght);
    return qty.style = {
      numFmt: '$#,##0.00_);[Red]($#,##0.00)'
      // numFmt: '#,##0.00_);(#,##0.00)'
    }
  }

  percentFormatter(row, headerLenght) {
    let qty = row.getCell(headerLenght);
    return qty.style = {
      numFmt: '0.0000%'
    }
  }


}
