import { Component, OnInit } from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import { recarga_prospecto } from 'src/app/reportes/models/recarga_prospecto';
import { RecargaService } from 'src/app/reportes/pages/recarga/recarga.service';

@Component({
  selector: 'app-cell-rendercustom',
  templateUrl: './cell-rendercustom.component.html',
  styleUrls: ['./cell-rendercustom.component.scss'],
})
export class CellRendercustomComponent implements ICellRendererAngularComp {

  cellData: any;
  params: ICellRendererParams;
  name: string;
  public filtros
  public filtro

  prospecto: recarga_prospecto={
    credito :0,
    estatus:""
  };

  constructor(private recargaService: RecargaService) {
    this.filtros = [
      {
        value:'Interesado',
        val:'Interesado'
      },
      {
        value:'No Interesado',
        val:'No Interesado'
      },
      {
        value:'No contesta',
        val:'No contesta'
      },
      {
        value:'No sirve cell',
        val:'No sirve cell'
      },
      {
        value:'No autoriza RH',
        val:'No autoriza RH'
      },
      {
        value:'No tiene capacidad',
        val:'No tiene capacidad'
      },
      {
        value:'Exempleado',
        val:'Exempleado'
      },
      {
        value:'No es el cliente',
        val:'No es el cliente'
      },
      {
        value:'Mensaje enviado',
        val:'Mensaje Enviado'
      } 
    ]
   }

  ngOnInit() {}

  agInit(params: ICellRendererParams){

    // //Gobales
    this.params = params;
    // if (params.colDef.headerName == 'Empresa'){
    //   this.name = params.data.empresa == null ? '' : this.converText(params.data.empresa);
    // } else if (params.colDef.headerName == 'Mercado'){
    //   this.name = params.data.mercado == null ? '' : this.converText(params.data.mercado);
    // }else if (params.colDef.headerName == 'Vendedor'){
    //   this.name = params.data.vendedor == null ? '' : this.converText(params.data.vendedor);
    // }else if (params.colDef.headerName == 'Supervisor'){
    //   this.name = params.data.supervisor == null ? '' : this.converText(params.data.supervisor);
    // }else if (params.colDef.headerName == 'Organizacion'){
    //   this.name = params.data.organizacion == null ? '' : this.converText(params.data.organizacion);
    // }else if (params.colDef.headerName == 'Sector Producto'){
    //   this.name = params.data.sectorProducto == null ? '' : this.converText(params.data.sectorProducto);
    // }else if (params.colDef.headerName == 'Producto'){
    //   this.name = params.data.producto == null ? '' : this.converText(params.data.producto);
    // }
    

  }
  onInputBoxChanged(value) {

    if(value !== "Limpiar"){
      this.prospecto.credito = this.params.data.iCreditId
      this.prospecto.estatus = value

      this.recargaService.guardarProspecto(this.prospecto).subscribe(ele => {
        if(ele['code']==200){
          console.log('guardarProspecto================================================ ', ele['creditos']['credito'])
          this.recargaService.UpdateData(ele['creditos'])
        }
      })

      console.log('onInputBoxChanged',this.prospecto)
    }

  }

  
  converText(title){
    return title.toUpperCase();
  }
  refresh(params: any): boolean {
    this.cellData = params.value;
    return true;
  }

}