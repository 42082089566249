
import { Component, OnInit } from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import { Router } from '@angular/router';

@Component({
  selector: 'app-money-cells',
  templateUrl: './money-cells.component.html',
  styleUrls: ['./money-cells.component.scss'],
})
export class MoneyCellsComponent implements OnInit,ICellRendererAngularComp {
  cellData: any;
  params: ICellRendererParams;
  money: string;
  datos  =  new Array();
  gridApi;
  url: any;

  rowsData  =  new Array();
  rowData  =  new Array();

  constructor(private router : Router) {
   }

  ngOnInit() {}

  agInit(params: ICellRendererParams){
    this.params = params;
    this.rowsData = params.api['columnModel']['rowModel']['rowsToDisplay'];

    this.url = this.router.url.split('/reportes/')[1]
    // thsi.url
    
    this.rowsData.forEach(element => {
      if(element.data.yearMonth !='Total')
      this.rowData.push(element.data);
    });
    
    if (params.colDef.headerName == 'Dispersión Créditos Nuevos' ){
      if(params.data.yearMonth !='Total'){
        this.money = params.data.dispersionNuevos == null ?  this.moneyFormatWithoutMxn(0) : this.moneyFormatWithoutMxn(params.data.dispersionNuevos);
      }
      this.money = params.data.dispersionNuevos == null ? this.moneyFormatWithoutMxn(0) : this.moneyFormatWithoutMxn(params.data.dispersionNuevos);
    } else if (params.colDef.headerName == 'Dispersión Créditos Recargas'){
      this.money = params.data.dispersionRecargas == null ? this.moneyFormatWithoutMxn(0) : this.moneyFormatWithoutMxn(params.data.dispersionRecargas);



    } else if (params.colDef.headerName == 'Dispersión Total'){
      this.money = params.data.totalDispersado == null ? this.moneyFormatWithoutMxn(0) : this.moneyFormatWithoutMxn(params.data.totalDispersado);
    } else if (params.colDef.headerName == 'Compras de deuda'){
      this.money = params.data.nProvedores == null ? this.moneyFormatWithoutMxn(0) : this.moneyFormatWithoutMxn(params.data.nProvedores);
    } else if (params.colDef.headerName == 'Dispersion mas compras'){
      this.money = params.data.totalDispersado2 == null ? this.moneyFormatWithoutMxn(0) : this.moneyFormatWithoutMxn(params.data.totalDispersado2);
    } else if (params.colDef.headerName == 'Meta'){
      this.money = params.data.presupuesto == null ? this.moneyFormatWithoutMxn(0) : this.moneyFormatWithoutMxn(params.data.presupuesto);
    }else if (params.colDef.headerName == 'Dispersión Promedio'){
      this.money = params.data.dispersionPromedio == null ? this.moneyFormatWithoutMxn(0) : this.moneyFormatWithoutMxn(params.data.dispersionPromedio);
    }

    //Cartera componets

    if(this.url == 'cartera'){

      if (params.colDef.headerName == 'Capital Pendiente' ){
        this.money = params.data.capitalPendiente == null ? this.moneyFormatWithoutMxn(0) : this.moneyFormatWithoutMxn(params.data.capitalPendiente);
      } else if (params.colDef.headerName == 'Cartera Sana'){
        this.money = params.data.carteraSana == null ? this.moneyFormatWithoutMxn(0) : this.moneyFormatWithoutMxn(params.data.carteraSana);
      } else if (params.colDef.headerName == 'Resolucion Finiquito Admin'){
        this.money = params.data.resolucionFiniquitoAdmin == null ? this.moneyFormatWithoutMxn(0) : this.moneyFormatWithoutMxn(params.data.resolucionFiniquitoAdmin);
      } else if (params.colDef.headerName == 'Cartera Sana1'){
        this.money = params.data.carteraSana == null ? this.moneyFormatWithoutMxn(0) : this.moneyFormatWithoutMxn(params.data.carteraSana);
      } else if (params.colDef.headerName == 'Cartera Sana1'){
        this.money = params.data.carteraSana == null ? this.moneyFormatWithoutMxn(0) : this.moneyFormatWithoutMxn(params.data.carteraSana);
      }  else if (params.colDef.headerName == 'Resolucion Finiquito'){
        this.money = params.data.resolucionFiniquito == null ? this.moneyFormatWithoutMxn(0) : this.moneyFormatWithoutMxn(params.data.resolucionFiniquito);
      }  else if (params.colDef.headerName == 'Ex empleados Ayudate'){
        this.money = params.data.exempleadosAyudate == null ? this.moneyFormatWithoutMxn(0) : this.moneyFormatWithoutMxn(params.data.exempleadosAyudate);
      }  else if (params.colDef.headerName == 'Cartera Vendida'){
        this.money = params.data.carteraVendida == null ? this.moneyFormatWithoutMxn(0) : this.moneyFormatWithoutMxn(params.data.carteraVendida);
      }  else if (params.colDef.headerName == 'Difuntos'){
        this.money = params.data.difuntos == null ? this.moneyFormatWithoutMxn(0) : this.moneyFormatWithoutMxn(params.data.difuntos);
      }  else if (params.colDef.headerName == 'tasaPonderada'){
        this.money = params.data.tasaPonderada == null ? this.moneyFormatWithoutMxn(0) : this.moneyFormatWithoutMxn(params.data.tasaPonderada);
      }  else if (params.colDef.headerName == 'plazoPonderado'){
        this.money = params.data.plazoPonderado == null ? this.moneyFormatWithoutMxn(0) : this.moneyFormatWithoutMxn(params.data.plazoPonderado);
      }  else if (params.colDef.headerName == 'numeroCreditosAux'){
        this.money = params.data.numeroCreditosAux == null ? this.moneyFormatWithoutMxn(0) : this.moneyFormatWithoutMxn(params.data.numeroCreditosAux);
      }  else if (params.colDef.headerName == 'Numero Creditos'){      
        if(params.data.numeroCreditos ===''){
          this.money = params.data.numeroCreditos == null ? this.percentafaormat(0) : this.percentafaormat(params.data.numeroCreditos);
          
        }else{
          this.money = params.data.numeroCreditos == null ? this.moneyFormatWithoutMxn(0) : this.moneyFormatWithoutMxn(params.data.numeroCreditos);
  
        }
      } else if (params.colDef.headerName == 'Tasa Ponderada') {
        this.money = params.data.tasaPonderada == null ? this.moneyFormatWithoutMxn(0) : this.moneyFormatWithoutMxn(params.data.tasaPonderada);
      } 
    }
  }

  percentafaormat(monto){
    monto= String(monto).match(/^\d{1,3}(\.\d{4})*/g)
    return monto+'%'
  }

  moneyFormatWithoutMxn(cant) {
    const formatter = new Intl.NumberFormat('es-MX',{
      style:'currency',
      currency:'MXN',
      minimumFractionDigits: 0
    });
    return formatter.format(cant);
  }
  refresh(params: any): boolean {
    this.cellData = params.value;
    return true;
  }

}
