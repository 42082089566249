import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  private initDate: string;
  private Finaldate: string;
  private TreceMesesF: string;
  private paymentDateI: string;

  constructor() {
    this.obtenerfecha()
  }

  getinitDate() {
    return this.initDate;
  }
  
  getFinaldate() {
    return this.Finaldate;
  }

  getPaymentDate() {
    return this.paymentDateI;
  }

  get FinTreceMeses() {
    return this.TreceMesesF
  }

  obtenerfecha() {

    let MyDate = new Date();

    this.paymentDateI = '2014-01-01'

    this.initDate = MyDate.getFullYear() + '-'
      + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-'
      + ('01').slice(-2);

    this.TreceMesesF = (MyDate.getFullYear() - 1) + '-'
      + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-'
      + ('01').slice(-2);

    this.Finaldate = MyDate.getFullYear() + '-'
      + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-'
      + ('0' + MyDate.getDate()).slice(-2);
  }
  getDateFormat(date: Date) {
    let myDate = new Date(date);
    return myDate.getFullYear() + '-'
      + ('0' + (myDate.getMonth() + 1)).slice(-2) + '-'
      + ('0' + myDate.getDate()).slice(-2);
  }

}
