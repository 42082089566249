import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recarga',
  templateUrl: './recarga.component.html',
  styleUrls: ['./recarga.component.scss'],
})
export class RecargaComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
