import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { IAfterGuiAttachedParams, ICellRendererParams } from 'ag-grid-community';
import { recarga_prospecto } from 'src/app/reportes/models/recarga_prospecto';
import { RecargaService } from 'src/app/reportes/pages/recarga/recarga.service';

@Component({
  selector: 'app-tipo-mensaje',
  templateUrl: './tipo-mensaje.component.html',
  styleUrls: ['./tipo-mensaje.component.scss'],
})

export class TipoMensajeComponent implements OnInit,ICellRendererAngularComp {
  cellData: any;
  params: ICellRendererParams;
  name: string;
  public filtros;
  public filtro;
  public url;
  public celular;
  public data;

  urlBase = 'https://web.whatsapp.com/send?phone=';

  public templatesMsj;

  prospecto: recarga_prospecto = {
    credito: 0,
    estatus: ""
  };

  constructor(
    private recargaService: RecargaService
  ) {

    this.templatesMsj = this.recargaService.templateMsj;
  }
  ngOnInit() { }

  agInit(params: ICellRendererParams) {
    this.params = params; 
    this.data = this.params.data
    if (params.colDef.headerName == 'Tipo de mensaje') {
      this.url = params.data.sendMsj == null ? 0 : params.data.sendMsj;
    }
    
    // console.log('vamos que se renderiza',this.params)

  }

  setAnchorTag(value=null){
    console.log('value',value)
    console.log('this.params',this.params)

    
  }

  onInputBoxChanged(value) {
    
    let msj, url, fisrNameClient, firstNameVen, importe, hash, idCredit;
    url = ''
    let celular = this.params.data.vCelular;
    if (value !== "Limpiar" || !celular.includes('(')){
      
      fisrNameClient = this.data.nombre.split(' ')[0].toLowerCase()
      firstNameVen = this.data.vendedor.toLowerCase() 
      console.log('khmartinez', firstNameVen)
      importe = this.data.importe
      hash = this.data.hash_recargas
      idCredit = this.data.iCreditId

      msj = value.replaceAll('{1}', fisrNameClient)
                .replaceAll('{2}', firstNameVen)
                .replaceAll('{3}', importe)
                .replaceAll('{4}', idCredit)
                .replaceAll('{5}', hash);

      url = this.urlBase + '+52' + celular + '&text=' + msj;
    }
    this.url = url;
    
  }

  updateStatus(){
    console.log('this.params',{data:this.data})
    this.prospecto.credito = this.params.data.iCreditId
    this.prospecto.estatus = 'Mensaje enviado'
    this.recargaService.guardarProspecto(this.prospecto).subscribe(ele => {
      if(ele['code']==200){
        console.log('guardarProspecto================================================ ', ele['creditos']['credito'])
        this.recargaService.UpdateData(ele['creditos'])
      }
    })
  }

  converText(title) {
    return title.toUpperCase();
  }
  refresh(params: any): boolean {
    this.cellData = params.value;
    return true;
  }

}