import { Component } from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import { Router } from '@angular/router';

@Component({
  selector: 'app-input-render',
  templateUrl: './input-render.component.html',
  styleUrls: ['./input-render.component.scss'],
})
export class InputRenderComponent implements ICellRendererAngularComp {
  cellData: any;
  params: ICellRendererParams;
  data: string;
  datos  =  new Array();
  gridApi;
  url: any;

  rowsData  =  new Array();
  rowData  =  new Array();

  constructor(private router : Router) {
   }

  agInit(params: ICellRendererParams){
    this.params = params;
    // console.log('this.params input ', this.params)
    // this.rowsData = params.api['columnModel']['rowModel']['rowsToDisplay'];

    // this.url = this.router.url.split('/reportes/')[1]
    // // thsi.url
    
    // this.rowsData.forEach(element => {
    //   if(element.data.yearMonth !='Total')
    //   this.rowData.push(element.data);
    // });
    
    // if (params.colDef.headerName == 'Sol plataforma' ){
    //   this.data = params.data.Sol_plataforma     
    // } else if (params.colDef.headerName == 'Sol zell'){
    //   this.data = params.data.sol_zell;
    // } else if (params.colDef.headerName == 'Estatus zell'){
    //   this.data = params.data.Estatus_zell;
    // } else if (params.colDef.headerName == 'Tipo de mensaje'){
    //   this.data = params.data.Tipo_mensaje;
    // }
    
  }

  percentafaormat(monto){
    monto= String(monto).match(/^\d{1,3}(\.\d{4})*/g)
    return monto+'%'
  }

  moneyFormatWithoutMxn(cant) {
    const formatter = new Intl.NumberFormat('es-MX',{
      style:'currency',
      currency:'MXN',
      minimumFractionDigits: 2
    });
    return formatter.format(cant);
  }
  refresh(params: any): boolean {
    this.cellData = params.value;
    return true;
  }

  hoda(valor){
    // event['detail']['value']
    console.log( this.params.api.getRowNode('40398')!);
    // console.log(valor,'Mi data es ',this.params.setDataValue());
  }
}
