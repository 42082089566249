import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { GridOptions } from 'ag-grid-community';
import { RecargaExcelService } from 'src/app/reportes/pages/recarga/recarga-excel.service';
import { RecargaService } from 'src/app/reportes/pages/recarga/recarga.service';
import { DateService } from 'src/app/reportes/shared/date.service';
import { AnchorTagCellsComponent } from '../../cell-render/anchor-tag-cells/anchor-tag-cells.component';
import { InputRenderComponent } from '../../cell-render/input-render/input-render.component';
import { MoneyCellsComponent } from '../../cell-render/money-cells/money-cells.component';
import { NumberCellsComponent } from '../../cell-render/number-cells/number-cells.component';
import { PercentCellsComponent } from '../../cell-render/percent-cells/percent-cells.component';
import { PhonenumberCellsComponent } from '../../cell-render/phonenumber-cells/phonenumber-cells.component';
import { WordsCellsComponent } from '../../cell-render/words-cells/words-cells.component';
import { ModalQrcodeComponent } from '../modal-qrcode/modal-qrcode.component';
import { CellRendercustomComponent } from './cells-table-recarga/cell-rendercustom/cell-rendercustom.component';
import { MsjCellComponent } from './cells-table-recarga/msj-cell/msj-cell.component';
import { TipoMensajeComponent } from './cells-table-recarga/tipo-mensaje/tipo-mensaje.component';
import { UrlSolicitudRecargaComponent } from './cells-table-recarga/url-solicitud-recarga/url-solicitud-recarga.component';
import { DateCellsComponent } from '../../colocacion/tabla-colocacion/cells-table/date-cells/date-cells.component';

@Component({
  selector: 'app-tabla-recarga',
  templateUrl: './tabla-recarga.component.html',
  styleUrls: ['./tabla-recarga.component.scss'],
})
export class TablaRecargaComponent implements OnInit {


  public rowClass;
  public getRowClass;
  public rowClassRules;
  public getRowStyle;

  public colorReglas;
  public idMsj
  public condition = false;
  private initDate: string;
  public switchGrafico: string;
  private finalDate: string;
  public year1Date: string;
  public defaultColDef;
  public sideBar;
  // public arrayInfo = Array<Colocacion>();
  public arrayInfo: any = [];
  public pinnedBottomRowData;
  rowHeight: number;
  rowDef;
  chartData;
  params: any;
  gridApi;
  gridColumnApi;
  gridOptions;
  context: any;
  minRowHeight;
  currentRowHeight: any;
  frameworkComponents: any;
  filterSelect: string;
  searchValue: string;
  minWidth: string;
  minWidthP: string;
  maxWidth: string;
  public tooltipShowDelay;
  public minWidthM;
  public maxWidthM;
  public rowSelection;
  public modal: HTMLIonModalElement;
  public userType;
  // public filtro
  private id

  filtro = [
    {
      val: 'Empresa',
      value: 'empresa'
    },
    // {
    //   val: 'Vendedor',
    //   value: 'vendedor'
    // },
    {
      val: 'Supervisor',
      value: 'supervisor'
    },
    {
      val: 'Convenio',
      value: 'convenio'
    },

  ]

  filtros = [
    'empresa',
    'supervisor',
    'convenio',
  ]





  constructor(
    private repservice: RecargaService,
    private dateService: DateService,
    private excel: RecargaExcelService,
    public modalController: ModalController,
    private route: ActivatedRoute
  ) {
    this.id = this.route.snapshot.paramMap.get('id')
    let user = JSON.parse(atob(this.id))
    this.userType = user.tipo_usuario;
    
    this.condition = this.userType == "ADM" ? true : false;

    console.log('Este es el id de componente padre btoa', this.userType)
    // this.colorReglas = data.data
    // this.vColor = 5.5
    this.context = { componentParent: this };
    this.getConfig();
    this.minWidth = '3px';
    this.minWidthM = '17px';
    this.maxWidthM = '27px';
    this.minWidthP = '2px';
    this.maxWidth = '1px';
    this.initDate = this.dateService.getinitDate();
    this.finalDate = this.dateService.getFinaldate();
    this.year1Date = this.dateService.FinTreceMeses;
    console.log('hola desde recarga')
    this.repservice.enviarMensaje(this.id)
  }

  generateExcel() {
    console.log('clickkkkkk!!!!!!!!!!!!!')
    this.excel.generateExcel();
  }


  ngOnInit() {


    this.repservice.fechaInicio = this.year1Date;
    this.repservice.fechafin = this.finalDate;
    this.repservice.enviarMensajeSubject.subscribe(response => {
      // el back llama el SP_getRecargasPruebas SP_Zell_Reporte_Recargas
      if (response) {

        this.arrayInfo = response['data']
        this.params.api.setRowData(response['data'])

        this.pinnedBottomRowData = this.createFooterData(this.params);
        
        // const base64 = this.id;
        
        // // create a buffer
        // const buff = Buffer.from(base64, 'base64');
        
        // // decode buffer as UTF-8
        // const str = JSON.parse(buff.toString('utf-8'));
        // let hash = JSON.parse(atob(this.id));
        // let userType = hash.tipo_usuario;
        console.log('respuesta del subject ********************', this.userType)
        if (this.userType == "ADM"){
          this.params.columnApi.setColumnVisible('iCreditId', true)
          this.params.columnApi.setColumnVisible('nombre', true)
        }

      }
    })


  }

  ngOnChanges() {

  }

  getConfig() {
    this.rowClass = 'my-blue-class';
    this.getRowClass = params => {
      if (params.node.rowIndex % 2 === 0) {
        return 'my-shaded-effect';
      }
    },
      this.getRowStyle = function (params) {
        if (params.node.rowPinned === 'bottom') {
          return { "background-color": "red" };
        }
      },
      this.rowDef = this.getColumns();
    this.sideBar = 'filters';
    this.rowSelection = "multiple";
    this.defaultColDef = {
      flex: 1,
      minWidth: 150,
      filter: true,
      floatingFilter: true,
      tooltipComponent: 'customTooltip'
    };
    this.rowHeight = 50
    this.frameworkComponents = this.getFrameworkComponents();
    // this.rowClassRules = {
    //     'rag-green': 'data.age < 20',
    //     'rag-amber': 'data.age >= 20 && data.age < 25',
    //     'rag-red': 'data.age >= 25',
    // },
    this.tooltipShowDelay = 0;
    this.gridOptions = {

      isExternalFilterPresent: this.isExternalFilterPresent,
      doesExternalFilterPass: this.doesExternalFilterPass,
      columnDefs: this.rowDef,
      components: {
        moneyCells: MoneyCellsComponent,
        numberCells: NumberCellsComponent,
        percentCell: PercentCellsComponent,
        wordsCell: WordsCellsComponent,
        InputRender: InputRenderComponent,
        cellCustom: CellRendercustomComponent,
        tipoMensajeCell: TipoMensajeComponent,
        phoneNumberCell: PhonenumberCellsComponent,
        anchorTag: AnchorTagCellsComponent,
        msjCell: MsjCellComponent,
        urlSolrecarga: UrlSolicitudRecargaComponent,
        DateCells: DateCellsComponent,
      },
      defaultColDef: {
        filter: true,
        floatingFilter: true,
        resizable: true,
        sortable: true,
        hide: true,
        tooltipComponent: 'customTooltip',
      },
    };
    // this.gridApi.setPinnedBottomRowData(['',123,123,4325,2345])
  }

  // region :: create table
  getColumns() {

    // let col
    return [


      // {
      //   minWidth: this.minWidth,
      //   maxWidth: this.maxWidth,
      //   headerName: 'Vendedor',
      //   field: 'vendedor',
      //   hide: true,
      //   cellRenderer: 'wordsCell',
      //   sortable: true,
      //   resizable: true,
      //   floatingFilter: true,
      //   filter: true
      // },

      {
        headerName: 'Info persona',
        // headerGroupComponent: 'CustomHeaderGroup',
        children: [
          {
            colId: 'numeroCreditos',
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            headerName: 'Id Personal',
            field: 'iPersonId',
            cellRenderer: 'numberCells',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            filter: 'agNumberColumnFilter',
            // headerCheckboxSelection: true,
            // headerCheckboxSelectionFilteredOnly: true,
            // checkboxSelection: true,
          },
          {
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            headerName: 'Id credito',
            field: 'iCreditId',
            cellRenderer: 'numberCells',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            filter: 'agNumberColumnFilter',
            columnGroupShow: 'open',
            hide: true
          },
          {
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            headerName: 'Nombre del cliente',
            field: 'nombre',
            cellRenderer: 'wordsCell',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            filter: true,
            columnGroupShow: 'open',
            hide: true
          },
          {
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            headerName: 'Estatus credito',
            field: 'Estatus_credito',
            cellRenderer: 'wordsCell',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            filter: true,
            columnGroupShow: 'open'
          }
        ]
      },



      //Filtros hide: true,
      {
        minWidth: this.minWidth,
        maxWidth: this.maxWidth,
        headerName: 'Empresa',
        field: 'empresa',
        cellRenderer: 'wordsCell',
        sortable: true,
        resizable: true,
        hide: true,
        floatingFilter: true,
        filter: true
      },
      {
        minWidth: this.minWidth,
        maxWidth: this.maxWidth,
        headerName: 'Convenio',
        field: 'convenio',
        hide: true,
        cellRenderer: 'wordsCell',
        sortable: true,
        resizable: true,
        floatingFilter: true,
        filter: true
      },
      {
        minWidth: this.minWidth,
        maxWidth: this.maxWidth,
        headerName: 'Supervisor',
        field: 'supervisor',
        hide: true,
        cellRenderer: 'wordsCell',
        sortable: true,
        resizable: true,
        floatingFilter: true,
        filter: true
      },

      //END Filtros hide: true,

      {
        headerName: 'Condiciones credito',
        // headerGroupComponent: 'CustomHeaderGroup',
        children: [
          {
            colId: 'numeroCreditos',
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            headerName: 'Importe',
            field: 'importe',
            cellRenderer: 'numberCells',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            filter: 'agNumberColumnFilter'
          },
          {
            colId: 'numeroCreditos',
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            headerName: 'Plazo',
            field: 'iPlazo',
            cellRenderer: 'numberCells',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            filter: 'agNumberColumnFilter',
            columnGroupShow: 'open'
          },
          {
            colId: 'numeroCreditos',
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            headerName: 'Monto liquidar',
            field: 'nMonto_Liquidar',
            cellRenderer: 'numberCells',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            filter: 'agNumberColumnFilter',
            columnGroupShow: 'open'
          }
        ]
      },

      {
        headerName: 'Prospeccion',
        // headerGroupComponent: 'CustomHeaderGroup',
        children: [
          {
            colId: 'numeroCreditos',
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            headerName: 'Porcentaje pago',
            field: 'porcentaje_pago',
            cellRenderer: 'percentCell',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            filter: 'agNumberColumnFilter'
          },          
          {
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            headerName: 'Celular',
            field: 'vCelular',
            cellRenderer: 'phoneNumberCell',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            filter: true,
            columnGroupShow: 'open'
          },
          {
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            headerName: 'Telefono',
            field: 'vTelefono',
            cellRenderer: 'phoneNumberCell',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            filter: true,
            columnGroupShow: 'open'
          },
          {
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            headerName: 'Hash recargas',
            field: 'hash_recargas',
            cellRenderer: 'anchorTag',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            filter: true,
            editable: true,
            columnGroupShow: 'open'
          }
          

        ]
      },
      
      


      {
        headerName: 'Asignar estatus prospeccion',
        minWidth: this.minWidth,
        maxWidth: this.maxWidth,
        cellRenderer: 'cellCustom',
        // field: 'estatus',
        // editable: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
        filter: true
      },
      {
        minWidth: this.minWidth,
        maxWidth: this.maxWidth,
        headerName: 'Estatus prospeccion',
        cellRenderer: 'wordsCell',
        // cellRenderer: 'InputRender',
        field: 'estatus',
        sortable: true,
        resizable: true,
        floatingFilter: true,
        filter: true
      },
      {
        minWidth: this.minWidth,
        maxWidth: this.maxWidth,
        headerName: 'Fecha modificacion',
        // cellRenderer: 'InputRender',
        field: 'fecha_modificacion',
        sortable: true,
        resizable: true,
        floatingFilter: true,
        filter: true
      },

      {
        minWidth: this.minWidth,
        maxWidth: this.maxWidth,
        headerName: 'Sol plataforma',
        cellRenderer: 'urlSolrecarga',
        field: 'solicitud_plataforma',
        sortable: true,
        resizable: true,
        floatingFilter: true,
        filter: true
      },
      {
        minWidth: this.minWidth,
        maxWidth: this.maxWidth,
        headerName: 'Sol zell',
        field: 'solicitud_Recarga_zell',
        cellRenderer: 'numberCells',
        sortable: true,
        resizable: true,
        floatingFilter: true,
        filter: true
      },
      {
        width: 120,
        minWidth: 90,
        maxWidth: 250,
        headerName: 'Fecha estatus credito',
        field: 'vFecha_CStatus,',
        cellRenderer: 'DateCells',
        sortable: true,
        resizable: true,
        floatingFilter: true,
        hide: false,
      },
      {
        minWidth: this.minWidth,
        maxWidth: this.maxWidth,
        headerName: 'Estatus zell',
        field: 'estatus_zell',
        cellRenderer: 'wordsCell',
        sortable: true,
        resizable: true,
        floatingFilter: true,
        filter: true
      },
      {
        minWidth: this.minWidth,
        maxWidth: this.maxWidth,
        headerName: 'Estatus firma',
        field: 'status_recarga',
        cellRenderer: 'wordsCell',
        sortable: true,
        resizable: true,
        floatingFilter: true,
        filter: true
      },


      {
        id: 'aa',
        headerName: 'Tipo de mensaje',
        cellRenderer: 'tipoMensajeCell',
        field: 'sendMsj',
        minWidth: 190,
        maxWidth: 300,
        sortable: true,
        resizable: true,
        filter: true,
        cellClass: "cell-wrap-text",
        floatingFilter: true,
        floatingFilterComponent: 'msjCell',
        
      },
      {
        // colId: 'numeroCreditos',
        minWidth: this.minWidth,
        maxWidth: this.maxWidth,
        headerName: 'Vendedor',
        field: 'vendedor',
        cellRenderer: 'wordsCell',
        sortable: true,
        resizable: true,
        floatingFilter: true,
        // filter: 'agNumberColumnFilter',
        // columnGroupShow: 'open'
      },



    ]
  }

  addMsj(value) {
    console.log(value)
  }

  createFooterData(gridApi?, event?: GridOptions) {
    let result = [];
    let model;
    let visibleRows = [];
    let dataGrafico = [];

    if (gridApi) {
      model = gridApi.columnApi.columnModel.gridApi.rowModel;
      visibleRows = model.rowsToDisplay;

    }
    console.log('model recarga', this.arrayInfo.length)
    console.log('visibleRows recarga', visibleRows.length)

    result.push({
      iPersonId: `# ${visibleRows.length} / ${this.arrayInfo.length}  `,
      // iPersonId: `Total de solicitudes: ${visibleRows.length} / ${this.arrayInfo.length}  `,
      // iPersonId: `Solicitudes: ${visibleRows.length} / ${this.arrayInfo.length}  `,
      importe: ' ',
      iPlazo: ' ',
      porcentaje_pago: '',
      numeroCreditos: '',
      solicitud_zell: '',
      solicitud_plataforma: '',
      sendMsj: '',
    });
    // console.log('result:', result);
    this.pinnedBottomRowData = result;
    return result;
  
  }

  onRowSelected(Msj) {
    this.idMsj = this.repservice.tMsj

    let rowNode = this.gridApi.getRowNode('aa')!;

    // this.MsjCel.setAnchorTag(this.idMsj)
    // console.log(Msj, 'this.idMsj', this.repservice.tMsj)
    // console.log(this.idMsj, 'asdsd', Msj)
  }




  onChangeFilter(event: any) {
    let filtro = event['detail']['value'];
    this.repservice.filtro = filtro

    // console.log('filtrosssss ', filtro)

    this.filtros.map(ele => {
      if (filtro.includes(ele)) {

        this.params.columnApi.setColumnVisible(ele, true)
      } else {
        this.params.columnApi.setColumnVisible(ele, false)
      }
    })

    // optionSelected.map(ele => this.params.columnApi.setColumnVisible(ele, true))
    // console.log('optionSelected', optionSelected)
    // console.log('this.params.columnApi', this.params.columnApi)
    // this.params.columnApi.setColumnVisible(ele, true)
    // this.reportesService.filtro = optionSelected
    // this.reportesService.enviarMensaje();
  }


  getFrameworkComponents() {
    return {
      moneyCells: MoneyCellsComponent,
      numberCells: NumberCellsComponent,
      percentCell: PercentCellsComponent,
      wordsCell: WordsCellsComponent,
      InputRender: InputRenderComponent,
      cellCustom: CellRendercustomComponent,
      tipoMensajeCell: TipoMensajeComponent,
      phoneNumberCell: PhonenumberCellsComponent,
      anchorTag: AnchorTagCellsComponent,
      msjCell: MsjCellComponent,
      urlSolrecarga: UrlSolicitudRecargaComponent,
      // wordsCell: WordsCellsCarteraComponent,
      DateCells: DateCellsComponent,
      // customTooltip: CustomTooltipCellsCarteraComponent,
      // colorFilter: ColorCellsCarteraComponent,
    };
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }


  async openModal() {
    const modal: HTMLIonModalElement =
      await this.modalController.create({
        component: ModalQrcodeComponent,
        componentProps: {
          aParameter: true,
          otherParameter: new Date()
        }
      });

    modal.onDidDismiss().then((detail) => {
      if (detail !== null) {
        console.log('The result:', detail.data);
      }
    });

    await modal.present();
  }




  onFilterChanged(params: GridOptions): void {
  }

  onGridReady(params) {
    this.params = params;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.minRowHeight = params.api.getSizesForCurrentTheme().rowHeight;
    this.currentRowHeight = this.minRowHeight;
    params.api.sizeColumnsToFit();

  }





  isExternalFilterPresent() {
    return filterWord !== '';
  }

  doesExternalFilterPass(node) {
    let cellData;
    cellData = node.data.estatusRecomendados;
    switch (filterWord) {
      case '0':
        return cellData == filterWord;
      case '1':
        return cellData == filterWord;
      case '2':
        return cellData == filterWord;
      case '3':
        return cellData == filterWord;
      default:
        return node;
    }
  }

}

function headerTooltipStyle(reglas, campo) {
  let headerToolti
  let redRule, amberRule, greenRule;

  reglas.forEach(element => {
    if (element.Campo == campo) {
      redRule = element.red
      amberRule = element.amber
      greenRule = element.green
    }
  });

  return ` <div class="custom-tooltip" [style.background-color]="color">
<p>
<span>{{ data.athlete }}</span>
</p>
<p><span>Country: </span>{{ data.country }}</p>
<p><span>Total: </span>{{ data.total }}</p>
</div>`
  return (
    '<div class="header-toltip">' + '<span >  Reglas </span>' + '</div>' +
    '<ul>' +
    '<li class="rag-red"><span class="span-tooltip"> Rojo: ' + redRule + '</span></li>' +

    '<li class="rag-amber"><span class="span-tooltip"> Amarilo: $' + amberRule + '</span></li>' +

    '<li class="rag-green"><span class="span-tooltip"> Verde: $' + greenRule + '</span></li>' +

    '</ul>'
    // '<div class="arrow_box"> <div class="circulo"> </div>' +'<span>' + w.globals.seriesNames[0] + ': ' + creditosNuevos[dataPointIndex].toFixed(2) + '</span> ' + '</div>' +
    // '<div class="arrow_box">' + '<span>' + w.globals.seriesNames[1] + ': ' + creditosRecargas[dataPointIndex].toFixed(2) + '</span>' + '</div>' +
    // '<div class="arrow_box">' + '<span>' + w.globals.seriesNames[2] + ': ' + totalCreditosNuevosRecargas[dataPointIndex].toFixed(2) + '</span>' + '</div>' +
    // '<div class="arrow_box">' + '<span>' + w.globals.seriesNames[3] + ': ' + metasMeses[dataPointIndex].toFixed(2) + '</span>' + '</div>'
  )
  // return headerToolti
  // console.log('ragCellClassRules2',params,'  ', reglas)

}

function ragCellClassRules(params, reglas, campo) {
  let clascs
  reglas.filter(ele => {
    if (ele.Campo == campo) {
      // console.log(params);
      let red = ele.red.replaceAll('x', params)
      let amber = ele.amber.replaceAll('x', params)
      let green = ele.green.replaceAll('x', params)
      // console.log('red', red)
      clascs = eval(red) ? 'rag-red' : eval(amber) ? 'rag-amber' : eval(green) ? 'rag-green' : ''

    }
  })

  return clascs
  // console.log('ragCellClassRules2',params,'  ', reglas)

}







let filterWord = '';

var filterParams = {
  comparator: function (filterLocalDateAtMidnight, cellValue) {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split('-');
    var cellDate = new Date(
      Number(dateParts[0]),
      Number(dateParts[1]) - 1,
    );

    let date = new Date(filterLocalDateAtMidnight)
    let yyyy = date.getFullYear()
    let dd = ('0' + (date.getMonth() + 1)).slice(-2)

    let ndate = ` ${yyyy}-${dd}`;
    filterLocalDateAtMidnight = new Date(ndate)

    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
  minValidYear: 2014,
  // maxValidYear: 2021,
};

function sum(values, col) {
  var sum = 0;
  values.forEach(function (value) { sum += value.data[col] });
  return sum;
}
function sumP(values, col) {
  let lenght = 0;
  var sum = 0;
  values.forEach(function (value) { sum += value.data[col]; lenght++ });

  // console.log(sum,' ',lenght)
  return (sum / lenght);
}

function Totales(resolucionFiniquitoGrafica, exempleadosAyudateGrafica) {
  let result = []
  resolucionFiniquitoGrafica.map((ele, idx) => {
    let exempleadosAyudat = exempleadosAyudateGrafica[idx][1]
    // console.log(exempleadosAyudateGrafica[idx][1])
    result.push([
      ele[0],
      ele[1] + exempleadosAyudat
    ])
  })
  return result;
}



function agrupador(data, col, groupBy) {
  let arreglo = []
  data.map(ele => arreglo.push(ele.data))

  // data.forEach(function (value) { arreglo = value.data });
  let agrupador = Object.values(arreglo)


  let obj
  obj = arreglo.reduce((total, obj) => {
    let key = obj[groupBy]
    if (!total[key]) {
      total[key] = [];
    }
    let totales = obj[col]
    total[key].push(totales);
    return total;
  }, []);



  let result = Object.keys(obj).map(function (key) {
    let valor = obj[key].reduce((acc, curr) => acc + curr)
    // console.log(valor)
    return [key, valor];
  });
  // console.log('agrupadoragrupadoragrupadoragrupadoragrupador ',result)

  return result;
}

function Total(data, groupBy) {
  // data = data.value.data
  let arreglo = []
  data.map(ele => arreglo.push(ele.data))
  // data.forEach(function (value) { arreglo = value.data });

  let obj
  obj = arreglo.reduce((total, obj) => {
    let key = obj[groupBy];
    if (!total[key]) {
      total[key] = [];
    }
    let dispersionRecargas = obj.dispersionRecargas
    let dispersionNuevos = obj.dispersionNuevos
    total[key].push(dispersionRecargas, dispersionNuevos);
    return total;
  }, []);

  var result = Object.keys(obj).map(function (key) {
    let valor = obj[key].reduce((acc, curr) => acc + curr)
    // console.log(valor)
    return [key, valor];
  });
  // console.log('data de la funcion agrupador arreglo',arreglo)
  // console.log('data de la funcion agrupador obj',obj)
  // console.log('data de la funcion result',result)
  return result;
}




