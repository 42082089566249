import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AccessGuard } from './access.guard';
import { AuthGuard } from './auth.guard';
//import { OcrScanerComponent } from './pages/ocr-scaner/ocr-scaner.component';

const routes: Routes = [
  {
    path: 'login_admin',
    loadChildren: () => import('./pages/login-admin/login-admin.module').then( m => m.LoginAdminPageModule)
  },
  {
    path: 'login_user',
    loadChildren: () => import('./pages/login-user/login-user.module').then( m => m.LoginUserPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'registro',
    loadChildren: () => import('./pages/registro/registro.module').then( m => m.RegistroPageModule)
  },
  {
    path: 'principal',
    loadChildren: () => import('./pages/principal/principal.module').then( m => m.PrincipalPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'solicitud',
    loadChildren: () => import('./pages/solicitud/solicitud.module').then( m => m.SolicitudPageModule)
  },
  {
    path: 'solicitud-recarga',
    loadChildren: () => import('./pages/solicitud-recarga/solicitud-recarga.module').then( m => m.SolicitudRecargaPageModule)
  },
  {
    path: 'recomienda-gana',
    loadChildren: () => import('./pages/recomienda-gana/recomienda-gana.module').then( m => m.RecomiendaGanaPageModule)
  },
  {
    path: 'recomendador',
    loadChildren: () => import('./components/recomendador/recomendador.module').then( m => m.RecomendadorModule)
  },
  {
    path: 'confirmacion-referenciador',
    loadChildren: () => import('./pages/confirmacion-solicitud-embajador/confirmacion-solicitud-embajador.module').then( m => m.ConfirmacionSolicitudEmbajadorModule)
  },
  {
    path: 'encuesta-credito',
    loadChildren: () => import('./pages/encuesta-credito/encuesta-credito.module').then( m => m.EncuestaCreditoModule)
  },
  {
    path: 'reportes',
    loadChildren: () => import('./reportes/reportes.module').then( m => m.ReportesModule)
  }, 
  {
    path: '**',
    redirectTo: 'login_user'
  },
  // {
  //   path: 'ocr-scan',
  //   component: OcrScanerComponent
  // }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {useHash:true, preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
