import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-popover-component',
  templateUrl: './popover-component.component.html',
  styleUrls: ['./popover-component.component.scss'],
})
export class PopoverComponentComponent implements OnInit {

  constructor() { }

  
  @Input() data: string;

  ngOnInit() {}

}
