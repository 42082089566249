import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComposicionPagosComponent } from './composicion-pagos.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TablaComposicionPagosComponent } from '../../components/composicionPagos/tabla-composicion-pagos/tabla-composicion-pagos.component';

import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';


//Otras importaciones
import { AgGridModule } from 'ag-grid-angular';
import { NgApexchartsModule } from 'ng-apexcharts';
import { HammerModule } from '@angular/platform-browser';
import { NwCalendarModule } from 'nw-calendar';
import { WordsCellsComponent } from '../../components/cell-render/words-cells/words-cells.component';
import { PercentCellsComponent } from '../../components/cell-render/percent-cells/percent-cells.component';
import { NumberCellsComponent } from '../../components/cell-render/number-cells/number-cells.component';

//CellRender
import { MoneyCellsCarteraComponent } from '../../components/cartera/tabla-cartera/cells-table-cartera/money-cells-cartera/money-cells-cartera.component';
import { NumberCellsCarteraComponent } from '../../components/cartera/tabla-cartera/cells-table-cartera/number-cells-cartera/number-cells-cartera.component';
import { PercentCellsCarteraComponent } from '../../components/cartera/tabla-cartera/cells-table-cartera/percent-cells-cartera/percent-cells-cartera.component';
import { WordsCellsCarteraComponent } from '../../components/cartera/tabla-cartera/cells-table-cartera/words-cells-cartera/words-cells-cartera.component';
import { DateCellsCarteraComponent } from '../../components/cartera/tabla-cartera/cells-table-cartera/date-cells-cartera/date-cells-cartera.component';
import { ColorCellsCarteraComponent } from '../../components/cartera/tabla-cartera/cells-table-cartera/color-cells-cartera/color-cells-cartera.component';
import { CustomTooltipCellsCarteraComponent } from '../../components/cartera/tabla-cartera/cells-table-cartera/custom-tooltip-cells-cartera/custom-tooltip-cells-cartera.component';
import { FiltroComposicionPagosComponent } from '../../components/composicionPagos/filtro-composicion-pagos/filtro-composicion-pagos.component';
import { GraficoComposicionPagosComponent } from '../../components/composicionPagos/grafico-composicion-pagos/grafico-composicion-pagos.component';
import { TablaHistComposicionPagosComponent } from '../../components/composicionPagos/tabla-hist-composicion-pagos/tabla-hist-composicion-pagos.component';
import { GraficoHistComposicionPagosComponent } from '../../components/composicionPagos/grafico-hist-composicion-pagos/grafico-hist-composicion-pagos.component';
import { FiltrosHistComposicionPagosComponent } from '../../components/composicionPagos/filtros-hist-composicion-pagos/filtros-hist-composicion-pagos.component';
import { PopoversCellComponent } from '../../components/cell-render/popovers-cell/popovers-cell.component';
import { PopoverComponentComponent } from '../../components/cell-render/popover-component/popover-component.component';
import { SelectTipobajaComponent } from '../../components/composicionPagos/tabla-composicion-pagos/cell-render/select-tipobaja/select-tipobaja.component';
import { InputComposicionpagosComponent } from '../../components/composicionPagos/tabla-composicion-pagos/cell-render/input-composicionpagos/input-composicionpagos.component';
import { ActionComposicionpagosComponent } from '../../components/composicionPagos/tabla-composicion-pagos/cell-render/action-composicionpagos/action-composicionpagos.component';
import { DateComposicionpagosComponent } from '../../components/composicionPagos/tabla-composicion-pagos/cell-render/date-composicionpagos/date-composicionpagos.component';
import { CustomHeaderComposicionpagosComponent } from '../../components/composicionPagos/tabla-composicion-pagos/cell-render/custom-header-composicionpagos/custom-header-composicionpagos.component';


@NgModule({
  declarations: [
    ComposicionPagosComponent,
    TablaComposicionPagosComponent,
    TablaHistComposicionPagosComponent,
    FiltrosHistComposicionPagosComponent,
    GraficoHistComposicionPagosComponent,
    FiltroComposicionPagosComponent,
    GraficoComposicionPagosComponent,
    PopoverComponentComponent,

  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    FormsModule,
    HammerModule,
    NwCalendarModule,
    NgxDaterangepickerMd.forRoot(),
    AgGridModule.withComponents(
      [
        // PercentCellsCarteraComponent,
        // WordsCellsCarteraComponent,
        // MoneyCellsCarteraComponent,
        CustomHeaderComposicionpagosComponent,
        NumberCellsComponent,
        SelectTipobajaComponent,
        WordsCellsComponent,
        PopoversCellComponent,
        PercentCellsComponent,

        InputComposicionpagosComponent,
        ActionComposicionpagosComponent,
        DateComposicionpagosComponent,

        CustomTooltipCellsCarteraComponent,
        NumberCellsCarteraComponent,
        DateCellsCarteraComponent,
        ColorCellsCarteraComponent,
        CustomTooltipCellsCarteraComponent
      ]),
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  entryComponents: [
    PopoverComponentComponent
  ],
})
export class ComposicionPagosModule { }
