import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { IonicStorageModule } from '@ionic/storage';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { ModalSearch } from './components/solicitudes/modal-search/modal-search.component';
import { Camera } from '@ionic-native/camera/ngx';
import { OcrScanerComponent } from './pages/ocr-scaner/ocr-scaner.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReportesModule } from './reportes/reportes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GraphQLModule } from './graphql/graphql.module';
import { provideFeatureFlag } from './providers/feature-flag.provider';

@NgModule({
  declarations: [
    AppComponent,
    OcrScanerComponent
  ],
  entryComponents: [
    
  ],
  imports: [
    BrowserModule,
    GraphQLModule,
    HttpClientModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    ReportesModule,
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Camera,
    // NativeStorage,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideFeatureFlag()
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
