import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { RecargaService } from './recarga.service';

@Injectable({
  providedIn: 'root'
})
export class RecargaExcelService {

  
  private dataXls;

  constructor(private recargaService:RecargaService) { }


  generateExcel() {
    this.dataXls = this.recargaService.xlsData
    console.log('ReporteExcelService', this.dataXls)
    let data = []
    let filtros
    filtros = [
      'empresa',
      'convenio',
      'supervisor'
    ]

    let header = [
      // "Id Personal",
      // "Id credito",
      "Nombre del cliente",
      "Estatus credito",
      "Importe",
      "Plazo",
      "Monto liquidar",
      "Porcentaje pago",
      "Celular",
      "Telefono",
      "Hash recargas",
      "Estatus prospeccion",
      "Fecha modificacion",
      "Sol plataforma",
      "Sol zell",
      "Fecha estatus credito",
      "Estatus zell",
      'vendedor',
    ]

    let fil = []
    fil = this.recargaService.filtro
    if(fil){
      filtros = filtros.reverse()
      filtros.map(ele => {
        if (fil.includes(ele)) {
          header.unshift(ele)
        }
      })
    }

    header.unshift("Id Personal","Id credito",)
    
    this.dataXls.forEach(d => {

      // if(d.nombre)
      let empresa,vendedor,supervisor,convenio
      if(fil){
        empresa = fil.includes('empresa') ? d.empresa : null
        supervisor = fil.includes('supervisor') ? d.supervisor : null
        convenio = fil.includes('convenio') ? d.convenio : null
      }


      data.push([
        d.iPersonId,
        d.iCreditId,
        empresa,
        convenio,
        supervisor,        
        d.nombre,
        d.Estatus_credito,        
        d.importe,
        d.iPlazo,
        d.nMonto_Liquidar,
        d.porcentaje_pago / 100 || 0,
        d.vCelular,
        d.vTelefono,
        d.hash_recargas,
        d.estatus,
        d.fecha_modificacion,
        d.solicitud_plataforma,
        d.solicitud_zell,
        d.vFecha_CStatus,
        d.estatus_zell,
        d.vendedor,
      ])
    })


    const title = 'Reporte Recarga';

    
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Reporte Recarga');

    // Add new row
    let titleRow = worksheet.addRow([title]);
    // Set font, size and style in title row.
    titleRow.font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true };
    // Blank Row
    worksheet.addRow([]);
    //Add row with current date
    // let subTitleRow = worksheet.addRow(['Date : ' + new Date(), 'medium']);
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ffffff' },
        bgColor: { argb: 'ffffff' }
      }
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      }
    });

    //Eliminar nulos
    data = data.map((ele) => ele.filter(elemnt => {
      if (elemnt == 0) {
        return '0'
      } else if (elemnt === '') {
        return 'N/A'
      } else if (elemnt !== null) {
        return elemnt
      }
    }))

    let headerLenght = header.length
    console.log('headerLenght', headerLenght, header)
    // Add Data and Conditional Formatting
    data.forEach(d => {
      let row = worksheet.addRow(d);

      // this.numberFormatter(row, headerLenght - 1)

      // this.numberFormatter(row, headerLenght - 2)

      // this.percentFormatter(row, headerLenght - 8)
    });

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Reportes-Recarga.xlsx');
    });


    
  }

  numberFormatter(row, headerLenght) {
    let qty = row.getCell(headerLenght);
    return qty.style = {
      numFmt: '0.0000'
    }
  }

  currencyFormatter(row, headerLenght) {
    let qty = row.getCell(headerLenght);
    return qty.style = {
      numFmt: '$#,##0.00_);[Red]($#,##0.00)'
      // numFmt: '#,##0.00_);(#,##0.00)'
    }
  }

  percentFormatter(row, headerLenght) {
    let qty = row.getCell(headerLenght);
    return qty.style = {
      numFmt: '0.0000%'
    }
  }




}
