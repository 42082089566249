import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { PopoverComponentComponent } from '../popover-component/popover-component.component';

@Component({
  selector: 'app-popovers-cell',
  templateUrl: './popovers-cell.component.html',
  styleUrls: ['./popovers-cell.component.scss'],
})
export class PopoversCellComponent implements OnInit, ICellRendererAngularComp {
  cellData: any;
  params: ICellRendererParams;
  name: string;

  constructor(private popOver: PopoverController) { }

  ngOnInit() {}

  agInit(params: ICellRendererParams) {

    if (params.colDef.headerName == 'Comentario') {
      this.name = params.data.vComentarios_ConGes == null ? '' : this.converText(params.data.vComentarios_ConGes);
    }

  }

  converText(title) {
    return title.toUpperCase();
  }
  refresh(params: any): boolean {
    this.cellData = params.value;
    return true;
  }

  async _openPopover(event){
    console.log('aaaaaa')
    const poover = await this.popOver.create({
      component: PopoverComponentComponent,
      componentProps: { data: this.name },
      event,
      cssClass:'popover-class'
    })

    return await poover.present()
  }

}
