import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CarteraComponent } from './pages/cartera/cartera.component';
import { ColocacionComponent } from'./pages/colocacion/colocacion.component'
import { RecargaComponent } from './pages/recarga/recarga.component';
import { ComposicionPagosComponent } from './pages/composicionPagos/composicion-pagos.component';

const routes: Routes = [
//   {
//     path: '*',
//     redirectTo: 'colocacion'
// },
{
    path: 'colocacion',
    component: ColocacionComponent
},
{
    path: 'cartera',
    component: CarteraComponent
},
{
    path: 'recarga/:id',
    component: RecargaComponent
},
{
    path: 'ComposicionPago',
    component: ComposicionPagosComponent
}

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportesRoutingModule { }
