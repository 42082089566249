import { Injectable } from '@angular/core';
import { TouchListener } from 'ag-grid-community';
import { Subject } from 'rxjs';

import { HttpService } from '../../http.service';
import { Colocacion } from '../Interface/colocacion';

import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import * as data from '../shared/reglasSemaforizacion.json';

@Injectable({
    providedIn: 'root'
})
export class ReportesService {

    mensaje!: string;
    public enviarMensajeSubject = new Subject<{}>();
    public enviarToatalesGraficaSubject = new Subject<{}>();
    private dataXls;

    private fechaIni;
    private fechaFin;
    private filtros: any;
    private category: any;
    //El agrupador es por year or yearMonth
    private agrupador: string = 'yearMonth';


    private reglasSemaforizacion;
    constructor(private _http: HttpService) {
        this.reglasSemaforizacion = data.data
    }

    public respuesta: any[] = [];
    public datos: any[] = [];

    get xlsData() {
        return this.dataXls
    }


    get agrupadores() {
        return this.agrupador
    }

    set agrupadores(val: string) {
        this.agrupador = val
    }

    get fechaInicio() {
        return this.fechaIni
    }

    set fechaInicio(val: string) {
        this.fechaIni = val
    }

    get fechafin() {
        return this.fechaFin
    }

    set fechafin(val: string) {
        this.fechaFin = val
    }

    get filtro() {
        return this.filtros
    }

    set filtro(val: []) {
        if (val) {
            this.filtros = val
        }
    }
    
    get categories() {
        return this.category
    }

    set categories(val: []) {
        if (val) {
            this.category = val
        }
    }

    get data() {
        return [...this.respuesta]
    }

    get dataRepo() {
        return [...this.datos]
    }

    validateNull(d,campo){
        let result
        if (d.hasOwnProperty(campo) && !d[campo]) {
            result = 'N/A'
        } else {
            result = d[campo]
        }
        return result
    }

    generateExcel() {
        let data = []
        let filtros
        // console.log('generateExcel ',this.dataXls)
        filtros = [
            'empresa',
            'vendedor',
            'supervisor',
            'convenio',
            'catalogo_empresa.organizacion',
            'catalogo_empresa.mercado',
            'catalogo_empresa.sectorProducto',
            'catalogo_empresa.producto'
        ]

        let header = [
            "Dispersion creditos nuevos",
            "Dispersion creditos Recargas",
            "Dispersion Total",
            "Meta",
            "Cumplimiento Meta",
            "Dispersion Promedio",
            "Tasa Total",
            "Plazo Promedio",
            "Dias Apertura Ponderado Promedio"
        ]
        if (this.filtro) {
            // this.filtro.map(ele => )
            // this.filtro.map(ele => this.filtro)
            // console.log(this.dataXls)
            // console.log(this.filtro)
            let info
            info = this.dataXls[0]
            filtros = filtros.reverse()
            filtros.map(ele => {
                if (info.hasOwnProperty(ele)) {
                    ele = ele.replace('catalogo_empresa.', '')
                    header.unshift(ele)
                }
            })
        }
        header.unshift(this.agrupadores)
        // console.log('header',header)

        this.dataXls.forEach(d => {

            let convenio,vendedor,supervisor,organizacion,mercado,sectorProducto,producto
            convenio =this.validateNull(d,'convenio')
            vendedor =this.validateNull(d,'vendedor')
            supervisor =this.validateNull(d,'supervisor')
            organizacion =this.validateNull(d,'catalogo_empresa.organizacion')
            mercado =this.validateNull(d,'catalogo_empresa.mercado')
            sectorProducto =this.validateNull(d,'catalogo_empresa.sectorProducto')
            producto =this.validateNull(d,'catalogo_empresa.producto')
            // if (d.hasOwnProperty('convenio') && !d.convenio) {
            //     convenio = 'N/A'
            // } else {
            //     convenio = d.convenio
            // }
            
            console.log('convenioconvenioconvenio', convenio)

            data.push([
                d.yearMonth     || null,
                d.year          || null,
                d.empresa       || null,
                vendedor        || null,
                supervisor      || null,
                convenio        || null,
                organizacion    || null,
                mercado         || null,
                sectorProducto  || null,
                producto        || null,
                d.dispersionNuevos || 0,
                d.dispersionRecargas || 0,
                d.totalDispersado || 0,
                d.presupuesto || 0,
                d.cumplimientoMeta/100  || 0,
                d.dispersionPromedio || 0,
                d.tasaPonderadaPromedio/100 || 0,
                d.plazoPonderadoPromedio || 0,
                d.diasAperturaPonderadoPromedio || 0
            ])
        })

        // console.log('generateExcel ', this.dataXls)

        const title = 'Reportes Colocacion';



        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet('Reportes Colocacion');

        // Add new row
        let titleRow = worksheet.addRow([title]);
        // Set font, size and style in title row.
        titleRow.font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true };
        // Blank Row
        worksheet.addRow([]);
        //Add row with current date
        // let subTitleRow = worksheet.addRow(['Date : ' + new Date(), 'medium']);
        //Add Header Row
        let headerRow = worksheet.addRow(header);
        // Cell Style : Fill and Border
        headerRow.eachCell((cell, number) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'ffffff' },
                bgColor: { argb: 'ffffff' }
            }
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            }
        });



        // console.log('data', data)---------------
        //Eliminar nulos
        data = data.map((ele) => ele.filter(elemnt => {
            // console.log('data elemnt',elemnt)
            if (elemnt == 0) {
                return '0'
            } else if (elemnt === '') {
                return 'N/A'
            } else if (elemnt !== null) {
                return elemnt
            }
        }))

        let headerLenght = header.length
        // console.log('data', data)---------------
        // Add Data and Conditional Formatting
        data.forEach(d => {
            // console.log(d)------------------
            let row = worksheet.addRow(d);

            this.numberFormatter(row, headerLenght)
            this.Color(this.reglasSemaforizacion, row, headerLenght, 'DiasAperturadoPonderadoPromedio')

            this.numberFormatter(row, headerLenght- 1)
            this.Color(this.reglasSemaforizacion, row, headerLenght - 1, 'Plazo Promedio')

            this.percentFormatter(row, headerLenght- 2)
            this.Color(this.reglasSemaforizacion, row, headerLenght - 2, 'Tasa Total')

            this.currencyFormatter(row, headerLenght- 3)
            this.Color(this.reglasSemaforizacion, row, headerLenght - 3, 'Dispersión Promedio')
            
            this.percentFormatter(row, headerLenght- 4)
            this.Color(this.reglasSemaforizacion, row, headerLenght - 4, 'Cumplimiento Meta')
            
            this.currencyFormatter(row, headerLenght- 5)
            this.currencyFormatter(row, headerLenght- 6)
            this.currencyFormatter(row, headerLenght- 7)
            this.currencyFormatter(row, headerLenght- 8)
            // worksheet.getCell('B1').value = 0.016;
            // worksheet.getCell('B1').numFmt = '0.00%';
        });

        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, 'Reportes-Colocacion.xlsx');
        });
    }

    

    numberFormatter(row,headerLenght){
        let qty = row.getCell(headerLenght);
        return qty.style = {
            numFmt: '0.00'
        }
    }
    
    currencyFormatter(row,headerLenght){
        let qty = row.getCell(headerLenght);
        return qty.style = {
            numFmt: '$#,##0.00_);[Red]($#,##0.00)'
            // numFmt: '#,##0.00_);(#,##0.00)'
        }
    }

    percentFormatter(row,headerLenght){
        let qty = row.getCell(headerLenght);
        return qty.style = {
            numFmt: '0.00%'
        }
    }

    Color(reglas, row, headerLenght, campo) {
        let qty = row.getCell(headerLenght);
        let color = '048520';
        let valor 
        reglas.filter(ele => {
            if (ele.Campo == campo) {
                if(ele.Campo =='Tasa Total' || ele.Campo == 'Cumplimiento Meta'){
                    // console.log('qtyqtyqtyqtyqtyqty3','qty.value * 100')
                    valor = qty.value * 100
                }else{

                    valor = qty.value
                }
                console.log('qtyqtyqtyqtyqtyqty3',valor)
                
                // console.log(params);
                let red = ele.red.replaceAll('x', valor)
                let amber = ele.amber.replaceAll('x', valor)
                let green = ele.green.replaceAll('x', valor)
                console.log('red', red)
                color = eval(red) ? color = 'db2323' : eval(amber) ? color = 'ffff00' : eval(green) ? color = '048520' : color

            }
        })

        // return qty.style = {
        //     numFmt: '0.00',
        //     fill:
        //     {
        //         type: 'pattern',
        //         pattern: 'solid',
        //         bgColor: { argb: 'FF00FF00' }
        //     }
        // }
        return qty.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: color }
        }

    }

    enviarMensaje() {
        // this.mensaje = mensaje
        try {
            // categories
            let year1Date, Finaldate, agrupador, filtros, category;
            filtros = this.filtro
            agrupador = this.agrupadores;
            year1Date = this.fechaInicio;
            Finaldate = this.fechafin;
            category = this.category
            let fil, categoies
            // console.log('filtros',filtros)
            // console.log('year1Date ',year1Date,'  Finaldate', Finaldate)
            if (!this.filtro) {
                fil = []
            } else if (this.filtro.length > 1) {
                fil = filtros.join(', ')
                // console.log(fil)
            } else {
                fil = filtros.concat(' ')
            }
            // else if(this.category && this.filtro.length >1){
            //     fil = filtros[0].concat(', ')


            // console.log('filtros',fil)

            // if( !this.category ) {                
            //     categoies= ''
            // }else if(this.category.length >1){
            //     categoies = category.join(', ')
            //     console.log(fil)
            // }else{
            //     categoies = category
            // }
            // console.log('mensaje', fil)


            console.log(`reportes/colocacion?groupBy=${agrupador}, ${fil}&initDate=${year1Date}&finalDate=${Finaldate}`)
            this._http.getREP(`reportes/colocacion?groupBy=${agrupador}, ${fil}&initDate=${year1Date}&finalDate=${Finaldate}`)
                .toPromise()
                .then(resp => {
                    if (resp['code'] == 200) {
                        console.log()
                        // if(categoies.includes(','))categoies = categoies.split(', ');
                        this.dataXls = resp['data']
                        this.enviarMensajeSubject.next({
                            filtros,
                            data: resp['data'],
                            agrupador,
                            // categoies
                        })
                    }
                    console.log('data:resp[]',resp['data'])
                    return resp
                })
        } catch (error) {
            console.log('error', error)
            return error;
        }
    }

}
