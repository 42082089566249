import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-percent-cells',
  templateUrl: './percent-cells.component.html',
  styleUrls: ['./percent-cells.component.scss'],
})
export class PercentCellsComponent implements OnInit, ICellRendererAngularComp {
  cellData: any;
  params: ICellRendererParams;
  montoPercent: string;
  constructor() { }

  ngOnInit() { }

  agInit(params: ICellRendererParams) {
    this.params = params;
    if (params.colDef.headerName == 'Tasa Total') {
      // console.log('Tasa Total',params.data.tasaPonderadaPromedio )
      this.montoPercent = params.data.tasaPonderadaPromedio == null ? this.percentafaormat(0) : this.percentafaormat(params.data.tasaPonderadaPromedio);
    } else if (params.colDef.headerName == 'Cumplimiento Meta') {
      // console.log('Cumplimiento Meta',params.data.cumplimientoMeta )
      this.montoPercent = params.data.cumplimientoMeta == null ? this.percentafaormat(0) : this.percentafaormat(params.data.cumplimientoMeta);
    }

    //cartera componet
    else if (params.colDef.colId == 'carteraSanaPorcentaje') {
      this.montoPercent = params.data.carteraSanaPorcentaje == null ? this.percentafaormat(0) : this.percentafaormat(params.data.carteraSanaPorcentaje);
    } else if (params.colDef.colId == 'resolucionFiniquitoAdminPorcentaje') {
      this.montoPercent = params.data.resolucionFiniquitoAdminPorcentaje == null ? this.percentafaormat(0) : this.percentafaormat(params.data.resolucionFiniquitoAdminPorcentaje);
    } else if (params.colDef.colId == 'resolucionFiniquitoPorcentaje') {
      this.montoPercent = params.data.resolucionFiniquitoPorcentaje == null ? this.percentafaormat(0) : this.percentafaormat(params.data.resolucionFiniquitoPorcentaje);
    } else if (params.colDef.colId == 'exempleadosAyudatePorcentaje') {
      this.montoPercent = params.data.exempleadosAyudatePorcentaje == null ? this.percentafaormat(0) : this.percentafaormat(params.data.exempleadosAyudatePorcentaje);
    } else if (params.colDef.colId == 'carteraVendidaPorcentaje') {
      this.montoPercent = params.data.carteraVendidaPorcentaje == null ? this.percentafaormat(0) : this.percentafaormat(params.data.carteraVendidaPorcentaje);
    } else if (params.colDef.colId == 'difuntosPorcentaje') {
      this.montoPercent = params.data.difuntosPorcentaje == null ? this.percentafaormat(0) : this.percentafaormat(params.data.difuntosPorcentaje);
    } else if (params.colDef.colId == 'Plazo ponderado promedio') {
      this.montoPercent = params.data.plazoPonderadoPromedio == null ? this.percentafaormat(0) : this.percentafaormat(params.data.plazoPonderadoPromedio);
    } else if (params.colDef.colId == 'tasaPonderadaPromedio') {
      this.montoPercent = params.data.tasaPonderadaPromedio == null ? this.percentafaormat(0) : this.percentafaormat(params.data.tasaPonderadaPromedio);
    }

    //tabla Components    

    else if (params.colDef.headerName == 'Porcentaje pago') {
      this.montoPercent = params.data.porcentaje_pago == null ? this.percentafaormat(0) : this.percentafaormat(params.data.porcentaje_pago);
    }


    if (params.colDef.colId == 'saldoBajaPorcentaje') {
      this.montoPercent = params.data.saldoBajaPorcentaje == null ? this.percentafaormat(0) : this.percentafaormat(params.data.saldoBajaPorcentaje);
    }
    if (params.colDef.colId == 'saldoBajaPorcentaje') {
      this.montoPercent = params.data.saldoBajaPorcentaje == null ? this.percentafaormat(0) : this.percentafaormat(params.data.saldoBajaPorcentaje);
    }
    if (params.colDef.colId == 'cobranzaAdministrativaPorcentaje') {
      this.montoPercent = params.data.cobranzaAdministrativaPorcentaje == null ? this.percentafaormat(0) : this.percentafaormat(params.data.cobranzaAdministrativaPorcentaje);
    }
    if (params.colDef.colId == 'cobranzaExtrajudicialPorcentaje') {
      this.montoPercent = params.data.cobranzaExtrajudicialPorcentaje == null ? this.percentafaormat(0) : this.percentafaormat(params.data.cobranzaExtrajudicialPorcentaje);
    }
    if (params.colDef.colId == 'nK_PendientePorcentaje') {
      this.montoPercent = params.data.nK_PendientePorcentaje == null ? this.percentafaormat(0) : this.percentafaormat(params.data.nK_PendientePorcentaje);
    }


    // composicion de pago historico

    if (params.colDef.colId == 'porcentajeExEmplAdmCobra') {
      this.montoPercent = params.data.porcentajeExEmplAdmCobra == null ? this.percentafaormat100(0) : this.percentafaormat100(params.data.porcentajeExEmplAdmCobra);
    }

    if (params.colDef.headerName == 'Porcentaje Cartera') {
      this.montoPercent = params.data.porcentajeCartera == null ? this.percentafaormat100(0) : this.percentafaormat100(params.data.porcentajeCartera);
    }

  }

  percentafaormat(monto) {
    // let r;
    // r = monto * 100
    if (Math.sign(monto) == 1) {
      monto = String(monto).match(/^\d{1,3}(\.\d{2})*/g)
    } else {
      
      monto = String(monto).match(/^-\d{1,3}(\.\d{2})*/g)
    }
    return monto
    // return monto.toFixed(2);

  }

  percentafaormat100(monto) {
    // let r;
    monto = monto * 100
    monto = String(monto).match(/^\d{1,3}(\.\d{4})*/g)
    return monto
    // return monto.toFixed(2);

  }

  percentafaormat4(monto) {
    // let r;
    // r = monto * 100
    monto = String(monto).match(/^\d{1,3}(\.\d{4})*/g)
    return monto
    // return monto.toFixed(2);

  }


  refresh(params: any): boolean {
    this.cellData = params.value;
    return true;
  }

}
