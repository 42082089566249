import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MoneyCellsComponent } from '../../components/cell-render/money-cells/money-cells.component';
import { NumberCellsComponent } from '../../components/cell-render/number-cells/number-cells.component';
import { PercentCellsComponent } from '../../components/cell-render/percent-cells/percent-cells.component';
import { WordsCellsComponent } from '../../components/cell-render/words-cells/words-cells.component';
import { DateCellsComponent } from '../../components/colocacion/tabla-colocacion/cells-table/date-cells/date-cells.component';
import { ModalFechaComponent } from '../../components/colocacion/filtros/modal-fecha/modal-fecha.component';
import { ColorFilterComponent } from '../../components/colocacion/tabla-colocacion/cells-table/color-filter/color-filter.component';
import { CustomTooltipComponent } from '../../components/colocacion/tabla-colocacion/cells-table/custom-tooltip/custom-tooltip.component';
import { ColocacionComponent } from './colocacion.component';
import { GraficoColocacionComponent } from '../../components/colocacion/grafico-colocacion/grafico-colocacion.component';
import { TablaColocacionComponent } from '../../components/colocacion/tabla-colocacion/tabla-colocacion.component';
import { FiltrosComponent } from '../../components/colocacion/filtros/filtros.component';
import { ReportesRoutingModule } from '../../reportes-routing.module';
import { NgApexchartsModule } from 'ng-apexcharts';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { HammerModule } from '@angular/platform-browser';
import { NwCalendarModule } from 'nw-calendar';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

const cellComponent = [
  MoneyCellsComponent,
  // NumberCellsComponent,
  // PercentCellsComponent,
  // WordsCellsComponent,
  DateCellsComponent,
  ModalFechaComponent,
  ColorFilterComponent,
  CustomTooltipComponent
];

@NgModule({
  declarations: [
    ColocacionComponent,
    GraficoColocacionComponent,
    TablaColocacionComponent,
    FiltrosComponent,
    cellComponent
  ],
  imports: [
    CommonModule,
    ReportesRoutingModule,
    AgGridModule.withComponents(
      [
        CustomTooltipComponent,
        MoneyCellsComponent,
        NumberCellsComponent,
        PercentCellsComponent,
        WordsCellsComponent,
        DateCellsComponent,
        ModalFechaComponent,
        ColorFilterComponent
      ]),
    NgApexchartsModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    HammerModule,
    NwCalendarModule,
    NgxDaterangepickerMd.forRoot()

  ],
  exports: [
    ColocacionComponent,
  ],
  // entryComponents: [cellComponent,
  //   CustomTooltipComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class ColocacionModule {}
