import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ComposicionPagosService } from 'src/app/reportes/pages/composicionPagos/composicion-pagos.service';
import { ToastService } from '../../../../../../e2e/src/app/toast.service';
import { DateService } from '../../../shared/date.service';
import * as moment from 'moment';
import { composicionPagosHistExcelService } from 'src/app/reportes/pages/composicionPagos/hist-composicion-pagos-excel.service';

@Component({
  selector: 'app-filtros-hist-composicion-pagos',
  templateUrl: './filtros-hist-composicion-pagos.component.html',
  styleUrls: ['./filtros-hist-composicion-pagos.component.scss'],
})
export class FiltrosHistComposicionPagosComponent implements OnInit {

  // subject = new Subject<string>();

  @Output() mostarGrafico = new EventEmitter<boolean>(false);

  public statusChange = true

  filtros = [
    {
      val: 'Empresa Original',
      value: 'vPerCon_Original'
    },
    {
      val: 'Convenio Original',
      value: 'vConvenio_Original'
    },
    {
      val: 'Importe de pagos',
      value: 'totalAdministrativo,totalExtrajudicial'
    },
  ]

  filtrosPeriodo = [
    {
      val: 'Mensual',
      value: 'months'
    },
    {
      val: 'Trimestral',
      value: 'quarters'
    },
    {
      val: 'Semestral',
      value: 'semesters'
    },
    {
      val: 'Anual',
      value: 'year'
    },
  ]

  public Filters = {
    filtros: [],
  }


  private accFiltros = [];

  constructor(
    private _composicionPagosService: ComposicionPagosService,
    private exportXls: composicionPagosHistExcelService,
    private toastS: ToastService,
  ) { }

  ngOnInit(
  ) { }

  async applyFilter() {

    // let filtro = this._composicionPagosService.filtroHist ? this._composicionPagosService.filtroHist : []
    // let genral = this._composicionPagosService.getAgrupadorPeriodo ? this._composicionPagosService.getAgrupadorPeriodo : []

    // let accFil = [filtro, genral]

    let accFil = []
    let filtro = this._composicionPagosService.filtroHist && accFil.push(...this._composicionPagosService.filtroHist);
    let filtroPeriodi = this._composicionPagosService.getAgrupadorPeriodo && accFil.push(this._composicionPagosService.getAgrupadorPeriodo);

    if (this.accFiltros.length == 0) {


      this.toastS.loaderShow('Obteniendo informacion');
      this.accFiltros = accFil
      let data = await this._composicionPagosService.getDataHist();

      // console.log('this._composicionPagosService.getData()', data)

      data.code = 200 && this.toastS.loaderHide();

    } else {
      console.log({ accFiltros: this.accFiltros, accFil },);


      if (!(this.accFiltros.join('') == accFil.join(''))) {
        this.accFiltros = []
        this.accFiltros = accFil
        let data = await this._composicionPagosService.getDataHist();

        data.code = 200 && this.toastS.loaderHide();
      } else {
        this.toastS.show(`warning`, 'no se ha detectado ningun cambio')
      }

    }
  }

  generateExcel() {
    // this.reportesService.generateExcel()
    this.exportXls.generateExcel(
      'Composicion pagos historico',
      this._composicionPagosService.xlsDataHist,
      this._composicionPagosService.xlsHeaderHist
    );
  }

  async ionchenge(e) {
    let value = e.target.checked
    this.statusChange = value
    this.mostarGrafico.emit(value);

  }
  radioChecked(evt) {
    var target = evt.target.value;

    this._composicionPagosService.getAgrupadorPeriodo
    console.log('cambio del ion radio ', { target }, this._composicionPagosService.getAgrupadorPeriodo)


    this._composicionPagosService.setAgrupadorPeriodo = target || 'months';


  }

  onChangeFilter(event: any) {
    let optionSelected = event['detail']['value'];
    this._composicionPagosService.filtroHist = optionSelected


  }


}
