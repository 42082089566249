import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../environments/environment";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  headers: Object;

  constructor(private http: HttpClient, private storage: StorageService) {
    this.loadHeaderStorage();
  }

  get(url: string, params?: Object): Observable<Object> {
    if (params != undefined) {
      this.headers["params"] = params;
    } else {
      delete this.headers["params"];
    }
    return this.http.get(environment.ws + url, this.headers);
  }

  /*
    Para consultar los servicios de los reportes
   */
  getREP(url: string, params?: Object): Observable<Object> {
      if (params != undefined) {
        //console.log('GET',environment.ws+url,JSON.stringify(params));
        this.headers["params"] = params;
      } else {
        delete this.headers["params"];
      }
    return this.http.get(environment.wsREP + url, this.headers);
  }

  post(url: string, body: Object): Observable<Object> {
      //console.log('POST',environment.ws+url,JSON.stringify(body));
      delete this.headers["params"];
    return this.http.post(environment.ws + url, body, this.headers);
  }

  postREP(url: string, body: Object): Observable<Object> {
      //console.log('POST',environment.ws+url,JSON.stringify(body));
      delete this.headers["params"];
    console.log("peticion", environment.wsREP + url, body, this.headers);
    return this.http.post(environment.wsREP + url, body, this.headers);
  }

  put(url: string, body: Object): Observable<Object> {
      console.log("PUT", environment.ws + url, JSON.stringify(body));
    delete this.headers["params"];
    return this.http.put(environment.ws + url, body, this.headers);
  }

  patch(url: string, body: Object): Observable<Object> {
      console.log("PATCH", environment.ws + url, JSON.stringify(body));
    delete this.headers["params"];
    return this.http.patch(environment.ws + url, body, this.headers);
  }

  delete(url: string): Observable<Object> {
    delete this.headers["params"];
    return this.http.delete(environment.ws + url, this.headers);
  }

  setHeader(token?: string, correo?: string) {
    if (
      token != undefined &&
      token != "" &&
      correo != undefined &&
      correo != ""
    ) {
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token + " " + correo,
        }),
      };
    } else {
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      };
    }
  }

  postPHP(url: string, body: Object): Observable<Object> {
      // console.log('POST', environment.ws_php + url, JSON.stringify(body));
      delete this.headers["params"];
    return this.http.post(environment.ws_php + url, body, this.headers);
  }

  async postFile(url: string, body: Object) {
    delete this.headers["params"];
    let token = await this.storage.getData("auth");
    let headers = {};
    if (token != undefined && token != "" && token.length == 2) {
      headers = {
        headers: new HttpHeaders({
          Authorization: "Bearer " + token[0] + " " + token[1],
        }),
      };
    } else {
      headers = {
        headers: new HttpHeaders(),
      };
    }
    return await this.http
      .post(environment.ws + url, body, headers)
      .toPromise();
  }

  async getNest(url: string, params: Object = {}) {
    return await this.http.get(`${environment.WS_Admin}${url}`, params).toPromise();
  }

  async postNest(url: string, payload: Object) {
    delete this.headers["params"];
    return await this.http.post(`${environment.WS_Admin}${url}`, payload).toPromise();
  }

  async loadHeaderStorage() {
    let token = await this.storage.getData("auth");
    if (token != undefined && token != "" && token.length == 2) {
      this.setHeader(token[0], token[1]);
    } else {
      this.setHeader();
    }
  }
}
