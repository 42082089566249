import { Component, OnInit } from '@angular/core';
import { ReportesService } from '../../../services/reportes.service';
import { ToastService } from 'src/app/toast.service';
import { Filters } from '../../../Interface/filters';

import { ReporteFiltrosService } from '../../../services/reporte-filtros.service';
import { ReporteExcelService } from '../../../services/reporte-excel.service';
import { DateService } from '../../../shared/date.service';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import * as events from "events";
import { FormGroup, FormBuilder } from '@angular/forms';
import { Console } from 'console';
// import { Moment } from 'moment';
// import  moment as moment from 'moment';
import * as moment from 'moment';
import * as format from 'date-fns';
import { CarteraService } from 'src/app/reportes/pages/cartera/cartera.service';
@Component({
  selector: 'app-filtros-recarga',
  templateUrl: './filtros-recarga.component.html',
  styleUrls: ['./filtros-recarga.component.scss'],
})
export class FiltrosRecargaComponent implements OnInit {



    // selected: {startDate: Dayjs, endDate: Dayjs};
    selected: { startdDate: moment.Moment, endDate: moment.Moment };
    miVariable: string;
    // public selected;
    private accFiltros = [];
    private accCategoria = [];
    private accGenral = [];

    ranges: any = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }

    mensaje: string = '';

    public Filters: Filters = {
        filtros: [],
    }


    maxDate: moment.Moment = moment();
    minDate: moment.Moment = moment().subtract(8, 'years'); 

    filtros = [
        {
            val: 'Empresa',
            value: 'empresa'
        },
        {
            val: 'Vendedor',
            value: 'vendedor'
        },
        {
            val: 'Supervisor',
            value: 'supervisor'
        },
        {
            val: 'Convenio',
            value: 'convenioActual'
        },
        
    ]

    arrayAllFilter: Array<Filters>;
    arrayAllData: Array<any>;
    private initDate: string;
    private finalDate: string;
    public modal: HTMLIonModalElement;

    public Empresa: [];
    public Convenio: [];
    public Vendedore: [];
    public Supervisor: [];

    private year1Date: string;

    // get Empresas() {
    //     return this.reporteFiltrosService.Empresa;
    // }
    // get Convenios() {
    //     return this.reporteFiltrosService.Convenios;
    // }
    // get Vendedores() {
    //     return this.reporteFiltrosService.Vendedores;
    // }
    // get Supervisores() {
    //     return this.reporteFiltrosService.Supervisores;
    // }

    constructor(private reportesService: CarteraService,
        // private reporteFiltrosService: ReporteFiltrosService,
        private exportXls: ReporteExcelService,
        private toastS: ToastService,
        private dateService: DateService,
        public modalController: ModalController) {
    }

    generateExcel(){        
        // this.reportesService.generateExcel()
        this.exportXls.generateExcel()
    }


    ngOnInit() {
        console.log('Este es la fecha maxima',this.maxDate)

        this.initDate = this.dateService.getinitDate();
        this.finalDate = this.dateService.getFinaldate();


        // this.reporteFiltrosService.getDataOptions(this.initDate, this.finalDate);
        this.year1Date = this.dateService.FinTreceMeses;

        // this.getAllFilter();
    }

    radioChecked(evt) {
        var target = evt.target.value;
        // console.log('cambio del ion radio ', target)

        this.reportesService.switchGraficos = target
        // this.reportesService.enviarMensaje();

        // console.log('cambio del ion radio ',this.reportesService.switchGraficos)


    }

    change(event) {
        let sDate = event.startDate?._d
        let eDate = event.endDate?._d
        if (sDate) {
            sDate = this.dateService.getDateFormat(sDate)
            this.reportesService.fechaInicio = sDate
        }
        if (eDate) {
            eDate = this.dateService.getDateFormat(eDate)
            this.reportesService.fechafin = eDate;
        }

        // format= format
        // console.log('optionSelected', this.selected['startdDate'] )
        // console.log('optionSelected', moment(this.selected.startdDate).format('YYYY-MM-DD') )
        // .format("DD-MMMM-YY") )
        // console.log(  )
    }

    onChangeFilter(event: any) {
        let optionSelected = event['detail']['value'];
        // console.log('optionSelected', optionSelected)
        this.reportesService.filtro = optionSelected
        // this.reportesService.enviarMensaje();
    }

    onChangeCategory(event: any) {
        let optionSelected = event['detail']['value'];
        // console.log(optionSelected)
        this.reportesService.categories = optionSelected
        // this.reportesService.enviarMensaje();
    }

    applyFilter() {
        let categories = this.reportesService.categories ? this.reportesService.categories : []
        let filtro = this.reportesService.filtro ? this.reportesService.filtro : []
        let genral = [this.reportesService.switchGraficos, this.reportesService.fechaInicio, this.reportesService.fechafin]

        let accFil = [categories, filtro, genral]
        // let accFil = [categories, filtro]
        // console.log( this.accFiltros.length  == 0 )
        if (this.accFiltros.length == 0) {

            // this.accFiltros = [categories, filtro]
            this.accFiltros = [categories, filtro, genral]
            // console.log(this.accFiltros)
            // console.log(this.accFiltros[2])
            // console.log(typeof (this.accFiltros))
            this.reportesService.enviarMensaje();
            this.toastS.loaderShow('Obteniendo informacion');
            setTimeout(() => {
                // this.messageSuccess = false;
                this.toastS.loaderHide();
            }, 10000);
        } else {
            // let cat 
            // let fil 
            // let gen;
            // [cat, fil, gen] = [this.accFiltros] 
            // console.log('entro al else')

            let a = this.accFiltros.map((ele, idx) => ele.join('') == accFil[idx].join(''))
            if (a.includes(false)) {
                this.toastS.loaderShow('Obteniendo informacion');
                this.accFiltros = []
                // this.accFiltros = [categories, filtro,]
                this.accFiltros = [categories, filtro, genral]
                // console.log('else ', a.includes(false))
                this.reportesService.enviarMensaje();
                this.reportesService.enviarToatalesGraficaSubject.subscribe(response => {
                    if (response) {
                        // console.log('llego la data al loader', response)
                        setTimeout(() => {
                            // console.log('se cierra el loader')
                            // this.messageSuccess = false;
                            this.toastS.loaderHide();
                        }, 10000);
                    }
                })
            }
            // console.log(this.accFiltros.length==accFil.length || this.accFiltros.every(function(v,i) { return v === accFil[i] } ))
        }
    }


    
}

