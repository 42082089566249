import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { IAfterGuiAttachedParams, ICellRendererParams } from 'ag-grid-community';
import { recarga_prospecto } from 'src/app/reportes/models/recarga_prospecto';
import { RecargaService } from 'src/app/reportes/pages/recarga/recarga.service';

@Component({
  selector: 'app-msj-cell',
  templateUrl: './msj-cell.component.html',
  styleUrls: ['./msj-cell.component.scss'],
})
export class MsjCellComponent implements ICellRendererAngularComp {
  cellData: any;
  params: ICellRendererParams;
  name: string;
  public url;
  public celular;

  urlBase = 'https://web.whatsapp.com/send?phone='

  public templatesMsj

  prospecto: recarga_prospecto = {
    credito: 0,
    estatus: ""
  };

  constructor(private recargaService: RecargaService) {

  }

  ngOnInit() {

  }


  ngAfterViewInit() {

  }

  click() {
    console.log('templateData', this.recargaService.templateMsj)
    this.templatesMsj = this.recargaService.templateMsj;
  }

  agInit(params: ICellRendererParams) {
    this.params = params;

    console.log('context', this.params.context)
  }

  onInputBoxChanged(value) {

    let id = []
    let opcion;
    opcion = (value !== "Limpiar") ? opcion = 'guardar' : opcion = "Limpiar";

    console.log('onInputBoxChanged', this.params.api.getDisplayedRowCount())
    // console.log('onInputBoxChanged', this.params)
    let count = this.params.api.getDisplayedRowCount()
    for (var i = 0; i < count; i++) {
      var rowNode = this.params.api.getDisplayedRowAtIndex(i)!;
      id.push(rowNode.data.iCreditId)
      // console.log('row ' + i + ' is ' + );
    }

    this.recargaService.updateAllSubject(value, id, opcion)


  }

  converText(title) {
    return title.toUpperCase();
  }

  refresh(params: any): boolean {

    this.cellData = params.value;
    return true;
  }

}