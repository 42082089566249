import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { GridOptions, ValueSetterParams } from 'ag-grid-community';
import { resourceLimits } from 'worker_threads';
import { Colocacion } from '../../../Interface/colocacion';
import { ReportesService } from '../../../services/reportes.service';
import { DateService } from '../../../shared/date.service';
import { MoneyCellsComponent } from '../../cell-render/money-cells/money-cells.component';
import { NumberCellsComponent } from '../../cell-render/number-cells/number-cells.component';
import { PercentCellsComponent } from '../../cell-render/percent-cells/percent-cells.component';
import { WordsCellsComponent } from '../../cell-render/words-cells/words-cells.component';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import * as data from '../../../shared/reglasSemaforizacion.json';
import { type } from 'os';
import { DateCellsComponent } from '../../colocacion/tabla-colocacion/cells-table/date-cells/date-cells.component';
import { CustomTooltipComponent } from '../../colocacion/tabla-colocacion/cells-table/custom-tooltip/custom-tooltip.component';
import { ColorFilterComponent } from '../../colocacion/tabla-colocacion/cells-table/color-filter/color-filter.component';
import { ComposicionPagosService } from '../../../pages/composicionPagos/composicion-pagos.service';
import { PopoversCellComponent } from '../../cell-render/popovers-cell/popovers-cell.component';
import { SelectTipobajaComponent } from './cell-render/select-tipobaja/select-tipobaja.component';
import { InputComposicionpagosComponent } from './cell-render/input-composicionpagos/input-composicionpagos.component';
import { ActionComposicionpagosComponent } from './cell-render/action-composicionpagos/action-composicionpagos.component';
import { DateComposicionpagosComponent } from './cell-render/date-composicionpagos/date-composicionpagos.component';
import { exportXlsService } from 'src/app/reportes/services/export-xls.service';
import { CustomHeaderComposicionpagosComponent } from './cell-render/custom-header-composicionpagos/custom-header-composicionpagos.component';

@Component({
  selector: 'app-tabla-composicion-pagos',
  templateUrl: './tabla-composicion-pagos.component.html',
  styleUrls: ['./tabla-composicion-pagos.component.scss'],
})
export class TablaComposicionPagosComponent implements OnInit {



  public rowClass;
  public getRowClass;
  public rowClassRules;
  public getRowStyle;

  public colorReglas;

  private initDate: string;
  private finalDate: string;
  private paymentDateI: string;
  public year1Date: string;
  public defaultColDef;
  public sideBar;
  // public arrayInfo = Array<Colocacion>();
  public arrayInfo: any = [];
  public pinnedBottomRowData;
  rowHeight: number;
  rowDef;
  chartData;
  params: any;
  gridApi;
  gridColumnApi;
  gridOptions;
  context: any;
  minRowHeight;
  currentRowHeight: any;
  frameworkComponents: any;
  filterSelect: string;
  searchValue: string;
  minWidth: string;
  maxWidth: string;


  public tooltipShowDelay;

  @Input() showGrafico: boolean;

  filtros = [
    'iCreditId',
    'vName',
    'vApellido',
    'vGestor',
    'vConvenio_Original',
    'vPerCon_Original',
    'vConvenio_Actual',
    'Fecha_Cambio_Exempleado',
    'vLMovementDateO',
    'iDias_Inactividad,vFecha_ConGes,vComentarios_ConGes,vGestorId'
  ]


  constructor(
    private _composicionPagosService: ComposicionPagosService,
    private dateService: DateService,
    private exportXls: exportXlsService,
  ) {
    this.colorReglas = data.data
    // this.vColor = 5.5
    this.context = { componentParent: this };
    this.getConfig();
    this.minWidth = '3px';
    this.maxWidth = '1px';
    this.initDate = this.dateService.getinitDate();
    this.finalDate = this.dateService.getFinaldate();
    this.paymentDateI = this.dateService.getPaymentDate();
    this.year1Date = this.dateService.FinTreceMeses;

  }


  public style: any = {
    width: '100% !important',
    height: '50vh',
  };



  ngOnInit() {

    this._composicionPagosService.fechaInicio = this.year1Date;
    this._composicionPagosService.fechafin = this.finalDate;
    this._composicionPagosService.paymentDateI = '';
    this._composicionPagosService.paymentDateF = '';

    this._composicionPagosService.getData()
    this._composicionPagosService.enviarMensajeSubject.subscribe(response => {

      if (response) {
        let agrupador = response['agrupador']
        this.arrayInfo = response['data']
        this.params?.api.setRowData(response['data'])





        if (agrupador == 'year') {
          this.params.columnApi.setColumnVisible('year', true)
          this.params.columnApi.setColumnVisible('yearMonth', false)
        } else {
          this.params.columnApi.setColumnVisible('yearMonth', true)
          this.params.columnApi.setColumnVisible('year', false)
        }

        if (response['filtros']) {
          let filtro
          filtro = response['filtros'];

          // let regexp = new RegExp('catalogo_empresa.', 'g')
          // filtro = filtro.map(ele => ele.replace(regexp, ''))

          this.filtros.map(ele => {

            //! esto se podria mejorar 

            if (filtro.includes('asignacion_Principal,condiciones_Credito')) {
              this.params.columnApi.setColumnVisible('asignacion_Principal', true)
              this.params.columnApi.setColumnVisible('condiciones_Credito', true)
            }
            else {

              this.params.columnApi.setColumnVisible('asignacion_Principal', false)
              this.params.columnApi.setColumnVisible('condiciones_Credito', false)
            }

            if (filtro.includes('totalAdministrativo,totalExtrajudicial')) {
              this.params.columnApi.setColumnVisible('totalAdministrativo', true)
              this.params.columnApi.setColumnVisible('totalExtrajudicial', true)
            }
            else {

              this.params.columnApi.setColumnVisible('totalAdministrativo', false)
              this.params.columnApi.setColumnVisible('totalExtrajudicial', false)
            }

            if (filtro.includes('iDias_Inactividad,vFecha_ConGes,vComentarios_ConGes,vGestorId')) {

              this.params.columnApi.setColumnVisible('iDias_Inactividad', true)
              this.params.columnApi.setColumnVisible('vFecha_ConGes', true)
              this.params.columnApi.setColumnVisible('vComentarios_ConGes', true)
              this.params.columnApi.setColumnVisible('vGestorId', true)
            }
            else {

              this.params.columnApi.setColumnVisible('iDias_Inactividad', false)
              this.params.columnApi.setColumnVisible('vFecha_ConGes', false)
              this.params.columnApi.setColumnVisible('vComentarios_ConGes', false)
              this.params.columnApi.setColumnVisible('vGestorId', false)
            }

            if (filtro.includes('ultimo_empleo,Fecha_inicio,Fecha_fin,Nombre,Fecha_actualizacion,company_name,IMSSError,Curp')) {
              this.params.columnApi.setColumnVisible('ultimo_empleo', true)
              this.params.columnApi.setColumnVisible('Fecha_inicio_Composicionpagos', true)
              this.params.columnApi.setColumnVisible('Fecha_fin_Composicionpagos', true)
              this.params.columnApi.setColumnVisible('Nombre', true)
              this.params.columnApi.setColumnVisible('Fecha_actualizacion_Composicionpagos', true)
              this.params.columnApi.setColumnVisible('company_name_Composicionpagos', true)
              this.params.columnApi.setColumnVisible('IMSSError_Composicionpagos', true)
              this.params.columnApi.setColumnVisible('Curp_Composicionpagos', true)
              this.params.columnApi.setColumnVisible('fk_tipo_baja', true)
              this.params.columnApi.setColumnVisible('comentario_baja', true)
              this.params.columnApi.setColumnVisible('fecha_baja', true)
              this.params.columnApi.setColumnVisible('Acciones', true)

            } else {
              this.params.columnApi.setColumnVisible('ultimo_empleo', false)
              this.params.columnApi.setColumnVisible('Fecha_inicio_Composicionpagos', false)
              this.params.columnApi.setColumnVisible('Fecha_fin_Composicionpagos', false)
              this.params.columnApi.setColumnVisible('Nombre', false)
              this.params.columnApi.setColumnVisible('Fecha_actualizacion_Composicionpagos', false)
              this.params.columnApi.setColumnVisible('IMSSError_Composicionpagos', false)
              this.params.columnApi.setColumnVisible('Curp_Composicionpagos', false)
              this.params.columnApi.setColumnVisible('fk_tipo_baja', false)
              this.params.columnApi.setColumnVisible('comentario_baja', false)
              this.params.columnApi.setColumnVisible('fecha_baja', false)
              this.params.columnApi.setColumnVisible('Acciones', false)
            }

            if (filtro.includes(ele)) {
              this.params.columnApi.setColumnVisible(ele, true)
            } else {
              this.params.columnApi.setColumnVisible(ele, false)
            }

          });
        }


        this.pinnedBottomRowData = this.createFooterData(this.params);
      }
    })


  }

  ngOnChanges(changes): void {


    if (!this.showGrafico) {
      this.style = {
        width: '100% !important',
        height: '75vh',
      };
    } else {
      this.style = {
        width: '100% !important',
        height: '50vh',
      };
    }
  }

  getConfig() {
    this.rowClass = 'my-blue-class';
    this.getRowClass = params => {
      if (params.node.rowIndex % 2 === 0) {
        return 'my-shaded-effect';
      }
    },
      this.getRowStyle = function (params) {
        if (params.node.rowPinned === 'bottom') {
          return { "background-color": "red" };
        }
      },
      this.rowDef = this.getColumns();
    this.sideBar = 'filters';
    this.defaultColDef = {
      flex: 1,
      minWidth: 150,
      filter: true,
      floatingFilter: true,
      tooltipComponent: 'customHeader'
    };
    this.rowHeight = 70
    this.frameworkComponents = this.getFrameworkComponents();
    // this.rowClassRules = {
    //     'rag-green': 'data.age < 20',
    //     'rag-amber': 'data.age >= 20 && data.age < 25',
    //     'rag-red': 'data.age >= 25',
    // },
    this.tooltipShowDelay = 0;
    this.gridOptions = {

      isExternalFilterPresent: this.isExternalFilterPresent,
      doesExternalFilterPass: this.doesExternalFilterPass,
      columnDefs: this.rowDef,
      components: {
        moneyCells: MoneyCellsComponent,
        customSelect: SelectTipobajaComponent,
        customInput: InputComposicionpagosComponent,
        customActions: ActionComposicionpagosComponent,
        customDate: DateComposicionpagosComponent,
        numberCells: NumberCellsComponent,
        percentCell: PercentCellsComponent,
        wordsCell: WordsCellsComponent,
        popoverCell: PopoversCellComponent,
        DateCells: DateCellsComponent,
        customTooltip: CustomTooltipComponent,
        customHeader: CustomHeaderComposicionpagosComponent,
      },
      // rowClassRules: {

      //     'rag-green': 'data.diasAperturaPonderadoPromedio < 4000',
      //     'rag-amber': 'data.diasAperturaPonderadoPromedio >= 4000',
      // 'rag-red': 'data.age >= 25',

      // "row-fail": params => params.api.getValue("result", params.node) < 60,
      // "row-pass": params => params.api.getValue("result", params.node) >= 60

      // 'bg-red': (params) => {
      //     if (params.rowIndex < 2) {
      //         return true;
      //     }
      // },
      // },
      defaultColDef: {
        filter: true,
        floatingFilter: true,
        resizable: true,
        sortable: true,
        hide: true,
        tooltipComponent: 'customHeader',
      },
    };
    // this.gridApi.setPinnedBottomRowData(['',123,123,4325,2345])
  }

  // region :: create table
  getColumns() {

    // let col
    return [
      {
        headerName: 'Info Cliente',
        // headerGroupComponent: 'CustomHeaderGroup',
        children: [
          {
            width: 120,
            minWidth: 90,
            maxWidth: 150,
            headerName: 'Año / mes',
            field: 'yearMonth',
            cellRenderer: 'DateCells',
            typeOf: 'string',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            hide: false,
          },
          {
            width: 100,
            minWidth: 90,
            maxWidth: 150,
            headerName: 'Año',
            field: 'year',
            cellRenderer: 'DateCells',
            typeOf: 'string',
            sortable: true,
            resizable: true,
            // filter: 'agDateColumnFilter',
            // filterParams,
            floatingFilter: true,
            hide: true,
            // cellStyle: {color: 'red', 'background-color': 'green'}
          },
          {
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            headerName: 'Id credito',
            field: 'iCreditId',
            cellRenderer: 'numberCells',
            typeOf: 'float',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            filter: 'agNumberColumnFilter',
            hide: true,
            columnGroupShow: 'open',
          },
          {
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            headerName: 'Nombre cliente',
            field: 'vName',
            cellRenderer: 'wordsCell',
            typeOf: 'string',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            hide: true,
            columnGroupShow: 'open',
          },
          {
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            headerName: 'Apellido Vendedor',
            field: 'vApellido',
            cellRenderer: 'wordsCell',
            typeOf: 'string',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            hide: true,
            columnGroupShow: 'open',
          },
          {
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            headerName: 'Gestor Vendedor',
            field: 'vGestor',
            cellRenderer: 'wordsCell',
            typeOf: 'string',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            hide: true,
            columnGroupShow: 'open',
          },
          {
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            headerName: 'Convenio original',
            field: 'vConvenio_Original',
            cellRenderer: 'wordsCell',
            typeOf: 'string',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            hide: true,
            columnGroupShow: 'open',
          },
          {
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            headerName: 'PerCon original',
            field: 'vPerCon_Original',
            cellRenderer: 'wordsCell',
            typeOf: 'string',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            hide: true,
            columnGroupShow: 'open',
          },
          {
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            headerName: 'Convenio actual',
            field: 'vConvenio_Actual',
            cellRenderer: 'wordsCell',
            typeOf: 'string',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            hide: true,
            columnGroupShow: 'open',
          },
        ]
      },
      {
        minWidth: this.minWidth,
        maxWidth: this.maxWidth,
        headerName: 'Fecha cambio exempleado',
        field: 'Fecha_Cambio_Exempleado',
        cellRenderer: 'DateCells',
        typeOf: 'string',
        sortable: true,
        resizable: true,
        // filter: 'agDateColumnFilter',
        // filterParams,
        floatingFilter: true,
        hide: true,
        // cellStyle: {color: 'red', 'background-color': 'green'}
      },
      {
        minWidth: this.minWidth,
        maxWidth: this.maxWidth,
        headerName: 'Fecha de movimeinto',
        field: 'vLMovementDateO',
        cellRenderer: 'DateCells',
        typeOf: 'string',
        sortable: true,
        resizable: true,
        // filter: 'agDateColumnFilter',
        // filterParams,
        floatingFilter: true,
        hide: true,
        // cellStyle: {color: 'red', 'background-color': 'green'}
      },


      {
        minWidth: this.minWidth,
        maxWidth: this.maxWidth,
        headerName: 'Exempleado',
        field: 'exempleado',
        cellRenderer: 'wordsCell',
        typeOf: 'string',
        sortable: true,
        resizable: true,
        floatingFilter: true,
        hide: true,
      },

      {
        headerName: 'Historico Saldo',
        // headerGroupComponent: 'CustomHeaderGroup',
        children: [
          {
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            headerName: 'Importe credito',
            field: 'nImporte_Credito',
            cellRenderer: 'numberCells',
            typeOf: 'currency',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            filter: 'agNumberColumnFilter',
            hide: false,
            columnGroupShow: 'closed'
          },
          {
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            headerName: 'Saldo baja',
            field: 'saldoBaja',
            cellRenderer: 'numberCells',
            typeOf: 'currency',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            filter: 'agNumberColumnFilter',
            hide: false,
            columnGroupShow: 'closed'
          },
          {

            colId: 'saldoBajaPorcentaje',
            headerName: '%',
            width: 100,
            minWidth: 90,
            maxWidth: 150,
            field: 'saldoBajaPorcentaje',
            cellRenderer: 'percentCell',
            typeOf: 'percent',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            filter: 'agNumberColumnFilter',
            columnGroupShow: 'closed'
          },
          {
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            headerName: 'Capital administrativa',
            field: 'cobranzaAdministrativa',
            cellRenderer: 'numberCells',
            typeOf: 'currency',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            filter: 'agNumberColumnFilter',
            hide: false,
            columnGroupShow: 'closed'
          },
          {

            colId: 'cobranzaAdministrativaPorcentaje',
            width: 100,
            minWidth: 90,
            maxWidth: 150,
            headerName: '%',
            field: 'cobranzaAdministrativaPorcentaje',
            cellRenderer: 'percentCell',
            typeOf: 'percent',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            filter: 'agNumberColumnFilter',
            columnGroupShow: 'closed'
          },
          {
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            headerName: 'Capital extrajudicial',
            field: 'cobranzaExtrajudicial',
            cellRenderer: 'numberCells',
            typeOf: 'currency',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            filter: 'agNumberColumnFilter',
            hide: false,
            columnGroupShow: 'closed'
          },
          {

            colId: 'cobranzaExtrajudicialPorcentaje',
            headerName: '%',
            width: 100,
            minWidth: 90,
            maxWidth: 150,
            field: 'cobranzaExtrajudicialPorcentaje',
            cellRenderer: 'percentCell',
            typeOf: 'percent',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            filter: 'agNumberColumnFilter',
            columnGroupShow: 'closed'
          },
          {
            // width: 100,
            // minWidth: 90,
            // maxWidth: 150,
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            headerName: 'NK pendiente',
            field: 'nK_Pendiente',
            cellRenderer: 'numberCells',
            typeOf: 'currency',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            filter: 'agNumberColumnFilter',
            hide: false,
          },
          {

            colId: 'nK_PendientePorcentaje',
            headerName: '%',
            width: 100,
            minWidth: 90,
            maxWidth: 150,
            field: 'nK_PendientePorcentaje',
            cellRenderer: 'percentCell',
            typeOf: 'percent',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            filter: 'agNumberColumnFilter',
            columnGroupShow: 'closed'
          },

        ]
      },

      {
        headerName: 'Importe de pagos',
        // headerGroupComponent: 'CustomHeaderGroup',
        children: [

          {
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            headerName: 'Total Administrativo',
            field: 'totalAdministrativo',
            cellRenderer: 'numberCells',
            typeOf: 'currency',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            filter: 'agNumberColumnFilter',
            hide: true,
          },
          {
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            headerName: 'Total Extrajudicial',
            field: 'totalExtrajudicial',
            cellRenderer: 'numberCells',
            typeOf: 'currency',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            hide: true,
            columnGroupShow: 'open'
          }
        ]
      },


      {
        width: 130,
        minWidth: this.minWidth,
        maxWidth: this.maxWidth,
        headerName: 'Dias de inactividad',
        field: 'iDias_Inactividad',
        cellRenderer: 'numberCells',
        typeOf: 'float',
        sortable: true,
        resizable: false,
        // filter: 'agDateColumnFilter',
        // filterParams,
        floatingFilter: true,
        hide: true
        // cellStyle: {color: 'red', 'background-color': 'green'}
      },
      {
        minWidth: this.minWidth,
        maxWidth: this.maxWidth,
        headerName: 'Fecha Comentario',
        field: 'vFecha_ConGes',
        cellRenderer: 'DateCells',
        typeOf: 'string',
        sortable: true,
        resizable: false,
        // filter: 'agDateColumnFilter',
        // filterParams,
        floatingFilter: true,
        hide: true
        // cellStyle: {color: 'red', 'background-color': 'green'}
      },
      {
        minWidth: this.minWidth,
        maxWidth: this.maxWidth,
        headerName: 'Comentario',
        field: 'vComentarios_ConGes',
        cellRenderer: 'popoverCell',
        sortable: true,
        resizable: true,
        floatingFilter: true,
        hide: true
      },
      {
        minWidth: this.minWidth,
        maxWidth: this.maxWidth,
        headerName: 'Contacto gestor',
        field: 'vGestorId',
        cellRenderer: 'wordsCell',
        typeOf: 'string',
        sortable: true,
        resizable: true,
        floatingFilter: true,
        hide: true,
      },

      {
        headerName: 'Info laboral',
        // headerGroupComponent: 'CustomHeaderGroup',


        // customInput: InputComposicionpagosComponent,
        // customActions: ActionComposicionpagosComponent,
        children: [
          {
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            headerName: 'Ultimo empleo',
            field: 'ultimo_empleo',
            cellRenderer: 'wordsCell',
            typeOf: 'string',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            hide: true,
          },
          {
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            headerName: 'Estatus',
            field: 'fk_tipo_baja',
            cellRenderer: 'customSelect',
            typeOf: 'string',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            hide: true,
            columnGroupShow: 'open',
            headerTooltip: 'cumplimientoMeta',
            tooltipComponentParams: { campo: 'Cumplimiento Meta' },
          },
          {
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            headerName: 'Comentario Baja',
            field: 'comentario_baja',
            cellRenderer: 'customInput',
            typeOf: 'string',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            hide: true,
            columnGroupShow: 'open',

          },
          {
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            headerName: 'Fecha de Baja',
            field: 'fecha_baja',
            cellRenderer: 'customDate',
            typeOf: 'string',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            hide: true,
            columnGroupShow: 'open',
          },
          {
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            headerName: 'Acciones',
            field: 'Acciones',
            cellRenderer: 'customActions',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            hide: true,
            columnGroupShow: 'open',
          },

          {
            colId: 'Fecha_inicio_Composicionpagos',
            width: 120,
            minWidth: 90,
            maxWidth: 150,
            headerName: 'Fecha inicio',
            field: 'Fecha_inicio',
            cellRenderer: 'DateCells',
            typeOf: 'string',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            hide: true,
            columnGroupShow: 'open'
          },
          {
            colId: 'Fecha_fin_Composicionpagos',
            width: 120,
            minWidth: 90,
            maxWidth: 150,
            headerName: 'Fecha fin',
            field: 'Fecha_fin',
            cellRenderer: 'DateCells',
            typeOf: 'string',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            hide: true,
            columnGroupShow: 'open'
          },
          {
            width: 100,
            minWidth: 90,
            maxWidth: 350,
            headerName: 'Nombre',
            field: 'Nombre',
            cellRenderer: 'wordsCell',
            typeOf: 'string',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            hide: true,
            columnGroupShow: 'open'
          },
          {
            colId: 'Fecha_actualizacion_Composicionpagos',
            width: 120,
            minWidth: 90,
            maxWidth: 350,
            headerName: 'Fecha actualizacion',
            field: 'Fecha_actualizacion',
            cellRenderer: 'DateCells',
            typeOf: 'string',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            hide: true,
            columnGroupShow: 'open'
          },
          {
            colId: 'company_name_Composicionpagos',
            width: 120,
            minWidth: 90,
            maxWidth: 150,
            headerName: 'Nombre interno',
            field: 'company_name',
            cellRenderer: 'wordsCell',
            typeOf: 'string',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            hide: true,
            columnGroupShow: 'open'
          },
          {
            colId: 'IMSSError_Composicionpagos',
            width: 120,
            minWidth: 90,
            maxWidth: 400,
            headerName: 'IMSS error ',
            field: 'IMSSError',
            cellRenderer: 'wordsCell',
            typeOf: 'string',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            hide: true,
            columnGroupShow: 'open'
          },
          {
            colId: 'IMSSError_Composicionpagos',
            width: 120,
            minWidth: 90,
            maxWidth: 400,
            headerName: 'IMSS error ',
            field: 'IMSSError',
            cellRenderer: 'wordsCell',
            typeOf: 'string',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            hide: true,
            columnGroupShow: 'open'
          },
          {
            colId: 'Curp_Composicionpagos',
            width: 120,
            minWidth: 90,
            maxWidth: 400,
            headerName: 'Curp',
            field: 'Curp',
            cellRenderer: 'wordsCell',
            typeOf: 'string',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            hide: true,
            columnGroupShow: 'open'
          },
        ]
      },

      {
        headerName: 'Reasignacion Gestor',
        children: [
          {
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            headerName: 'Condiciones Credito',
            field: 'condiciones_Credito',
            cellRenderer: 'wordsCell',
            typeOf: 'string',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            hide: true,
          },
          {
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            headerName: 'Asignacion Principal',
            field: 'asignacion_Principal',
            cellRenderer: 'wordsCell',
            typeOf: 'string',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            hide: true,
            columnGroupShow: 'open'
          },

        ]
      },





    ]
  }




  getFrameworkComponents() {
    return {
      moneyCells: MoneyCellsComponent,
      customSelect: SelectTipobajaComponent,
      customInput: InputComposicionpagosComponent,
      customActions: ActionComposicionpagosComponent,
      customDate: DateComposicionpagosComponent,
      numberCells: NumberCellsComponent,
      percentCell: PercentCellsComponent,
      wordsCell: WordsCellsComponent,
      popoverCell: PopoversCellComponent,
      DateCells: DateCellsComponent,
      customTooltip: CustomTooltipComponent,
      colorFilter: ColorFilterComponent,
      customHeader: CustomHeaderComposicionpagosComponent,
    };
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }



  onFilterChanged(params: GridOptions): void {

  }

  onGridReady(params) {
    this.params = params;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.minRowHeight = params.api.getSizesForCurrentTheme().rowHeight;
    this.currentRowHeight = this.minRowHeight;
    params.api.sizeColumnsToFit();

  }

  createData(obj) {

    var result = [];
    let totaldispersionNuevos = obj[0].totalDispersado;
    let totaldispersionRecargas = obj[0].dispersionRecargas;
    let totaltotalDispersado = obj[0].totalDispersado;
    let totalpresupuesto = obj[0].presupuesto;
    let totaldispersionPromedio = obj[0].dispersionPromedio;
    let totalplazoPonderadoPromedio = obj[0].plazoPonderadoPromedio;
    let totaldiasAperturaPonderadoPromedio = obj[0].diasAperturaPonderadoPromedio;
    let totaltasaPonderadaPromedio = obj[0].tasaPonderadaPromedio;
    for (var i = 0; i < 1; i++) {
      result.push({
        empresa: 'Total',

        dispersionNuevos: totaldispersionNuevos,
        dispersionRecargas: totaldispersionRecargas,
        totalDispersado: totaltotalDispersado,
        presupuesto: totalpresupuesto,
        dispersionPromedio: totaldispersionPromedio,
        plazoPonderadoPromedio: totalplazoPonderadoPromedio,
        tasaPonderadaPromedio: totaltasaPonderadaPromedio,
        diasAperturaPonderado: totaldiasAperturaPonderadoPromedio
      });
    }
    return result;
  }

  createFooterData(gridApi?, event?: GridOptions) {
    let result = [];
    let model;
    let header;
    let visibleRows = [];
    let dataGrafico = [];



    if (gridApi) {
      model = gridApi.columnApi.columnModel.gridApi.rowModel;
      visibleRows = model.rowsToDisplay;

      this.getHeaderXLS(gridApi.columnApi.columnModel.viewportColumns)
      this.getDataXLS(visibleRows)

      // header = this.getHeader(gridApi.columnApi.columnModel.viewportColumns)

      // this._composicionPagosService.xlsData = visibleRows
    }
    let groupBy = this._composicionPagosService.agrupadores;



    let totalSaldoBaja = agrupador(visibleRows, 'saldoBaja', groupBy)
    let totalCobranzaAdministrativa = agrupador(visibleRows, 'cobranzaAdministrativa', groupBy)
    let totalCobranzaExtrajudicial = agrupador(visibleRows, 'cobranzaExtrajudicial', groupBy)
    let totalNK_Pendiente = agrupador(visibleRows, 'nK_Pendiente', groupBy)
    // let totalNK_PendientePorcentaje = agrupador(visibleRows, 'nK_PendientePorcentaje', groupBy)
    // let totalCreditosNuevosRecargas = Total(visibleRows, groupBy)


    let grafica = []
    totalSaldoBaja.map((curr, indx) => {
      if (grafica) {
        grafica.push({
          "yearMonth": curr[0],
          "totalSaldoBaja": totalSaldoBaja[indx][1],
          'totalCobranzaAdministrativa': totalCobranzaAdministrativa[indx][1],
          'totalCobranzaExtrajudicial': totalCobranzaExtrajudicial[indx][1],
          'totalNK_Pendiente': totalNK_Pendiente[indx][1],
          // 'Total': totalCreditosNuevosRecargas[indx][1],
        })
      }
    })


    this._composicionPagosService.enviarToatalesGraficaSubject.next(grafica)


    let Importe_Credito = sum(visibleRows, 'nImporte_Credito')

    let saldoBaja = sum(visibleRows, 'saldoBaja')
    let saldoBajaPorcentaje = sumP(visibleRows, 'saldoBajaPorcentaje')


    let totalExtrajudicial = sum(visibleRows, 'totalExtrajudicial')
    let cobranzaAdministrativa = sum(visibleRows, 'cobranzaAdministrativa')
    let cobranzaAdministrativaPorcentaje = sumP(visibleRows, 'cobranzaAdministrativaPorcentaje')



    let totalAdministrativo = sum(visibleRows, 'totalAdministrativo')
    let cobranzaExtrajudicial = sum(visibleRows, 'cobranzaExtrajudicial')
    let cobranzaExtrajudicialPorcentaje = sumP(visibleRows, 'cobranzaExtrajudicialPorcentaje')


    let nK_Pendiente = sum(visibleRows, 'nK_Pendiente')
    let nK_PendientePorcentaje = sumP(visibleRows, 'nK_PendientePorcentaje')
    let iDias_Inactividad = sum(visibleRows, 'iDias_Inactividad')



    result.push({
      yearMonth: 'Total',
      year: 'Total',
      iCreditId: '',
      exempleado: '',
      nImporte_Credito: Importe_Credito,
      saldoBaja,
      saldoBajaPorcentaje,
      totalAdministrativo,
      cobranzaAdministrativa,
      cobranzaAdministrativaPorcentaje,
      totalExtrajudicial,
      cobranzaExtrajudicial,
      cobranzaExtrajudicialPorcentaje,
      nK_Pendiente,
      nK_PendientePorcentaje,
      iDias_Inactividad
    });

    this.pinnedBottomRowData = result;
    return result;
  }

  getHeader(header) {
    let head = []

    header.forEach(element => {
      if (element.userProvidedColDef) {
        const { headerName, field } = element.userProvidedColDef
        head.push({
          headerName, field
        })
      }
    });

    return head

  }


  getComisionTotal(visibleRows, filtro) {
    // let fil = filtro
    let total;
    let resul;
    total = visibleRows.reduce((acc, cur) => { acc += cur.data[filtro]; return acc; }, 0)
    resul = isNaN(total) == true ? 0 : total;
    return parseInt(resul, 0);
  }

  getHeaderXLS(header) {
    let head = {}

    header.forEach(element => {
      if (element.userProvidedColDef) {
        const { headerName, field, typeOf } = element.userProvidedColDef

        if (element.originalParent.children.length > 1) {

          element.originalParent.children.map(ele => {
            const { userProvidedColDef, visible } = ele
            const { headerName, field, typeOf } = userProvidedColDef
            if (visible) {
              head = {
                ...head,
                [`${field}:${typeOf}`]: headerName
              }
            }

          })

        }
        else {
          head = {
            ...head,
            [`${field}:${typeOf}`]: headerName
          }
        }



      }
    });

    this.exportXls.xlsHeader = head

  }

  getDataXLS(data) {
    let newdata = []

    data.forEach(element => {
      newdata.push(element.data)
    })
    this.exportXls.xlsData = newdata
  }


  isExternalFilterPresent() {
    return filterWord !== '';
  }

  doesExternalFilterPass(node) {
    let cellData;
    cellData = node.data.estatusRecomendados;
    switch (filterWord) {
      case '0':
        return cellData == filterWord;
      case '1':
        return cellData == filterWord;
      case '2':
        return cellData == filterWord;
      case '3':
        return cellData == filterWord;
      default:
        return node;
    }
  }



}

function sumP(values, col) {
  let lenght = 0;
  var sum = 0;
  values.forEach(function (value) { sum += value.data[col]; lenght++ });

  return (sum / lenght);
}


function headerTooltipStyle(reglas, campo) {
  let headerToolti
  let redRule, amberRule, greenRule;

  reglas.forEach(element => {
    if (element.Campo == campo) {
      redRule = element.red
      amberRule = element.amber
      greenRule = element.green
    }
  });

  return ` <div class="custom-tooltip" [style.background-color]="color">
    <p>
      <span>{{ data.athlete }}</span>
    </p>
    <p><span>Country: </span>{{ data.country }}</p>
    <p><span>Total: </span>{{ data.total }}</p>
  </div>`
  return (
    '<div class="header-toltip">' + '<span >  Reglas </span>' + '</div>' +
    '<ul>' +
    '<li class="rag-red"><span class="span-tooltip"> Rojo: ' + redRule + '</span></li>' +

    '<li class="rag-amber"><span class="span-tooltip"> Amarilo: $' + amberRule + '</span></li>' +

    '<li class="rag-green"><span class="span-tooltip"> Verde: $' + greenRule + '</span></li>' +

    '</ul>'
    // '<div class="arrow_box"> <div class="circulo"> </div>' +'<span>' + w.globals.seriesNames[0] + ': ' + creditosNuevos[dataPointIndex].toFixed(2) + '</span> ' + '</div>' +
    // '<div class="arrow_box">' + '<span>' + w.globals.seriesNames[1] + ': ' + creditosRecargas[dataPointIndex].toFixed(2) + '</span>' + '</div>' +
    // '<div class="arrow_box">' + '<span>' + w.globals.seriesNames[2] + ': ' + totalCreditosNuevosRecargas[dataPointIndex].toFixed(2) + '</span>' + '</div>' +
    // '<div class="arrow_box">' + '<span>' + w.globals.seriesNames[3] + ': ' + metasMeses[dataPointIndex].toFixed(2) + '</span>' + '</div>'
  )
  // return headerToolti

}

function ragCellClassRules(params, reglas, campo) {
  let clascs
  reglas.filter(ele => {
    if (ele.Campo == campo) {
      let red = ele.red.replaceAll('x', params)
      let amber = ele.amber.replaceAll('x', params)
      let green = ele.green.replaceAll('x', params)
      clascs = eval(red) ? 'rag-red' : eval(amber) ? 'rag-amber' : eval(green) ? 'rag-green' : ''

    }
  })

  return clascs
}



let filterWord = '';

var filterParams = {
  comparator: function (filterLocalDateAtMidnight, cellValue) {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split('-');
    var cellDate = new Date(
      Number(dateParts[0]),
      Number(dateParts[1]) - 1,
    );

    let date = new Date(filterLocalDateAtMidnight)
    let yyyy = date.getFullYear()
    let dd = ('0' + (date.getMonth() + 1)).slice(-2)

    let ndate = ` ${yyyy}-${dd}`;
    filterLocalDateAtMidnight = new Date(ndate)

    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
  minValidYear: 2014,
  // maxValidYear: 2021,
};

function sum(values, col) {
  var sum = 0;
  values.forEach(function (value) { sum += value.data[col] });
  return sum;
}

function agrupador(data, col, groupBy) {
  // data = data.value.data
  let arreglo = []
  data.map(ele => arreglo.push(ele.data))
  // data.forEach(function (value) { arreglo = value.data });
  let agrupador = Object.values(arreglo)
  // if(obj['yearMonth']){

  // }
  let obj
  obj = arreglo.reduce((total, obj) => {
    let key = obj[groupBy]
    if (!total[key]) {
      total[key] = [];
      total[key] = [];
    }
    let totales = obj[col]
    total[key].push(totales);
    return total;
  }, []);

  var result = Object.keys(obj).map(function (key) {
    let valor = obj[key].reduce((acc, curr) => acc + curr)
    return [key, valor];
  });

  return result;
}

function Total(data, groupBy) {
  // data = data.value.data
  let arreglo = []
  data.map(ele => arreglo.push(ele.data))
  // data.forEach(function (value) { arreglo = value.data });

  let obj
  obj = arreglo.reduce((total, obj) => {
    let key = obj[groupBy];
    if (!total[key]) {
      total[key] = [];
    }
    let dispersionRecargas = obj.dispersionRecargas
    let dispersionNuevos = obj.dispersionNuevos
    total[key].push(dispersionRecargas, dispersionNuevos);
    return total;
  }, []);

  var result = Object.keys(obj).map(function (key) {
    let valor = obj[key].reduce((acc, curr) => acc + curr)
    return [key, valor];
  });

  return result;
}

var a1000ValueGetter = function (params) {

  // return params.data.a + ' 1000'; 

  return 1000;
};


