import { Component, OnInit } from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
  selector: 'app-date-cells-cartera',
  templateUrl: './date-cells-cartera.component.html',
  styleUrls: ['./date-cells-cartera.component.scss'],
})
export class DateCellsCarteraComponent implements OnInit {
  cellData: any;
  params: ICellRendererParams;
  money: string;
  dateObj : string;

  constructor() { }

  ngOnInit() {}

  agInit(params: ICellRendererParams){
    this.params = params;
    if (params.colDef.headerName == 'Historico'){
      this.dateObj  = params.data.hist == null ? '' : params.data.hist ;
    } else if(params.colDef.headerName == 'Año'){
      this.dateObj  = params.data.year == null ? '' : params.data.year ;
    }
  }

  refresh(params: any): boolean {
    this.cellData = params.value;
    return true;
  }
}
