import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ReportesService } from '../../../services/reportes.service';
import { ToastService } from 'src/app/toast.service';
import { Filters } from '../../../Interface/filters';

import { ReporteFiltrosService } from '../../../services/reporte-filtros.service';
import { ReporteExcelService } from '../../../services/reporte-excel.service';
import { DateService } from '../../../shared/date.service';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { ModalFechaComponent } from './modal-fecha/modal-fecha.component';
import * as events from "events";
import { FormGroup, FormBuilder } from '@angular/forms';
import { Console } from 'console';
// import { Moment } from 'moment';
// import  moment as moment from 'moment';
import * as moment from 'moment';
import * as format from 'date-fns';



@Component({
    selector: 'app-filtros',
    templateUrl: './filtros.component.html',
    styleUrls: ['./filtros.component.scss'],
})
export class FiltrosComponent implements OnInit {


    // selected: {startDate: Dayjs, endDate: Dayjs};
    selected: { startdDate: moment.Moment, endDate: moment.Moment };
    miVariable: string;
    // public selected;
    private accFiltros = [];
    private accCategoria = [];
    private accGenral = [];

    @Output() mostarGrafico = new EventEmitter<boolean>(false);
    public statusChange = true

    ranges: any = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }

    mensaje: string = '';

    public Filters: Filters = {
        filtros: [],
    }


    maxDate: moment.Moment = moment();
    minDate: moment.Moment = moment().subtract(8, 'years'); 

    filtros = [
        {
            val: 'Empresa',
            value: 'empresa'
        },
        {
            val: 'Vendedor',
            value: 'vendedor'
        },
        {
            val: 'Supervisor',
            value: 'supervisor'
        },
        {
            val: 'Convenio',
            value: 'convenio'
        },
        {
            val: 'organización',
            value: 'catalogo_empresa.organizacion'
        },
        {
            val: 'Mercado',
            value: 'catalogo_empresa.mercado'
        },
        {
            val: 'Sector Producto',
            value: 'catalogo_empresa.sectorProducto'
        },
        {
            val: 'Producto',
            value: 'catalogo_empresa.producto'
        }
    ]

    arrayAllFilter: Array<Filters>;
    arrayAllData: Array<any>;
    private initDate: string;
    private finalDate: string;
    public modal: HTMLIonModalElement;

    public Empresa: [];
    public Convenio: [];
    public Vendedore: [];
    public Supervisor: [];

    private year1Date: string;

    constructor(private reportesService: ReportesService,
        // private reporteFiltrosService: ReporteFiltrosService,
        private exportXls: ReporteExcelService,
        private toastS: ToastService,
        private formB: FormBuilder,
        private dateService: DateService,
        public modalController: ModalController) {
    }

    async ionchenge(e) {
        let value = e.target.checked
        this.statusChange = value
        this.mostarGrafico.emit(value);

    }

    generateExcel(){        
        // this.reportesService.generateExcel()
        this.exportXls.generateExcel()
    }


    ngOnInit() {
        console.log('Este es la fecha maxima',this.maxDate)

        this.initDate = this.dateService.getinitDate();
        this.finalDate = this.dateService.getFinaldate();


        // this.reporteFiltrosService.getDataOptions(this.initDate, this.finalDate);
        this.year1Date = this.dateService.FinTreceMeses;

        this.getAllFilter();
    }

    change(event) {
        let sDate = event.startDate?._d
        let eDate = event.endDate?._d
        if (sDate) {
            sDate = this.dateService.getDateFormat(sDate)
            this.reportesService.fechaInicio = sDate
        }
        if (eDate) {
            eDate = this.dateService.getDateFormat(eDate)
            this.reportesService.fechafin = eDate;
        }

        // format= format
        // console.log('optionSelected', this.selected['startdDate'] )
        // console.log('optionSelected', moment(this.selected.startdDate).format('YYYY-MM-DD') )
        // .format("DD-MMMM-YY") )
        // console.log(  )
    }

    onChangeFilter(event: any) {
        let optionSelected = event['detail']['value'];
        // console.log('optionSelected', optionSelected)
        this.reportesService.filtro = optionSelected
        // this.reportesService.enviarMensaje();
    }

    onChangeCategory(event: any) {
        let optionSelected = event['detail']['value'];
        // console.log(optionSelected)
        this.reportesService.categories = optionSelected
        // this.reportesService.enviarMensaje();
    }

    applyFilter() {
        let categories = this.reportesService.categories ? this.reportesService.categories : []
        let filtro = this.reportesService.filtro ? this.reportesService.filtro : []
        let genral = [this.reportesService.agrupadores, this.reportesService.fechaInicio, this.reportesService.fechafin]

        let accFil = [categories, filtro, genral]
        // console.log( this.accFiltros.length  == 0 )
        if (this.accFiltros.length == 0) {

            this.accFiltros = [categories, filtro, genral]
            // console.log(this.accFiltros)
            // console.log(this.accFiltros[2])
            // console.log(typeof (this.accFiltros))
            this.reportesService.enviarMensaje();
            this.toastS.loaderShow('Obteniendo informacion');
            setTimeout(() => {
                // this.messageSuccess = false;
                this.toastS.loaderHide();
            }, 1000);
        } else {

            let a = this.accFiltros.map((ele, idx) => ele.join('') == accFil[idx].join(''))
            if (a.includes(false)) {
                this.toastS.loaderShow('Obteniendo informacion');
                this.accFiltros = []
                this.accFiltros = [categories, filtro, genral]
                // console.log('else ', a.includes(false))
                this.reportesService.enviarMensaje();
                this.reportesService.enviarToatalesGraficaSubject.subscribe(response => {
                    if (response) {
                        // console.log('llego la data al loader', response)
                        setTimeout(() => {
                            // console.log('se cierra el loader')
                            // this.messageSuccess = false;
                            this.toastS.loaderHide();
                        }, 1000);
                    }
                })
            }
            // console.log(this.accFiltros.length==accFil.length || this.accFiltros.every(function(v,i) { return v === accFil[i] } ))
        }
    }

    radioChecked(evt) {
        var target = evt.target.value;
        // console.log('cambio del ion radio ', target)

        this.reportesService.agrupadores = target
        // this.reportesService.enviarMensaje();

        // console.log('cambio del ion radio ',target)


    }
    async getAllFilter() {
        this.getAllServices();
        await this.createModal();
    }

    filterUp(arrayData: Array<any>, selectFilter: string) {
        let objectArray = arrayData;
        let property = selectFilter;
        return objectArray.reduce(function (total, obj, idx) {
            let key = obj[property];
            if (!total[key]) {
                total[key] = [];
            }
            total[key].push(obj);
            return total;
        }, {});
    }

    async getAllServices() {
        // const arrayAllFilter = await this.reporteFiltrosService.getAllFilters();
        // const arrayAllData = await this.reporteFiltrosService.getAllData(this.initDate, this.finalDate);
        // Promise.all([arrayAllFilter, arrayAllData])
        //     .then(result => {
        //         if (result[0]['code'] == 200 && result[1]['code'] == 200) {
        //             this.arrayAllFilter = result[0]['data'];
        //             this.arrayAllData = result[1]['data'];
        //         }
        //     }).catch(error => {
        //         console.log(error, 'error')
        //     })
    }

    onChangeAnyFilter(tipo: string, event: any) {
        // let optionSelected = event['detail']['value'];
        // // console.log('****************123', tipo + '  ' + optionSelected);
        // let groupedPeople;
        // switch (tipo) {
        //     case 'empresa':
        //         // this.reporteFiltrosService.opFiltro('Empresa', optionSelected);
        //         // groupedPeople = this.filterUp(this.arrayAllData, tipo);
        //         break;
        //     case 'convenios':
        //         console.log('XXXXXXXXXXXXXXXXXXXXXXXXXXXXX123', tipo + '  ' + optionSelected);

        //         this.reporteFiltrosService.opFiltro('convenio', optionSelected);
        //         console.log(groupedPeople);
        //         break;
        //     case 'supervisores':
        //         groupedPeople = this.filterUp(this.arrayAllData, tipo);
        //         console.log(groupedPeople);
        //         break;
        //     case 'vendedores':
        //         groupedPeople = this.filterUp(this.arrayAllData, tipo);
        //         console.log(groupedPeople);
        //         break;
        // }
    }



    // region :: modal
    // first, i need to crete the modal
    public async createModal() {
        this.modal = await this.modalController.create({
            component: ModalFechaComponent,
            cssClass: [
                "my-modal-inner"
            ]
        });
    }

    /*
    modal for open dates componets
     */
    async changeDates() {
        this.modal.onDidDismiss()
            .then(async (responseModal) => {
                await this.createModal();
            });
        return await this.modal.present();
    }

    // endregion
}
