import { Injectable } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  loaderCharged:boolean;
  constructor(public toast:ToastController,public loaderC:LoadingController) { 
    this.loaderCharged = false;
  }

  /**
   * 
   * @param {string} type 
   * @param {string} message 
   */
  async show(type: (`warning` | `success` | `danger`), message: string) {
    const toast = await this.toast.create({
      message,
      color:type,
      position:'top',
      duration: 3000,
      cssClass: 'ion-text-center',
      buttons:[{
        side: 'end',
        role: 'close',
        icon: 'close'
      }]
    });
    toast.present();
  }
  public loaderShow(message:string){
    setTimeout(() => {
      if(this.loaderCharged){
        this.loaderShow(message);
      }else{
        this.loaderC.create({
          message:message, 
          duration: 60000
        }).then(a=>{a.present();this.loaderCharged=true});
      }
    }, 100);
  }

  public loaderShowWithoutTime(message:string){
    setTimeout(() => {
      if(this.loaderCharged){
        this.loaderShow(message);
      }else{
        this.loaderC.create({
          message:message,
        }).then(a=>{a.present();this.loaderCharged=true});
      }
    }, 100);
  }
  public loaderHide(duration=100){
    setTimeout(() => {
      if(this.loaderCharged){
        this.loaderCharged=false;
        this.loaderC.dismiss();
      }else{
        this.loaderHide();
      }
    }, duration);
  }
  
}
