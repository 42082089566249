import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-color-cells-cartera',
  templateUrl: './color-cells-cartera.component.html',
  styleUrls: ['./color-cells-cartera.component.scss'],
})
export class ColorCellsCarteraComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
