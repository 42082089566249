import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ComposicionPagosService } from 'src/app/reportes/pages/composicionPagos/composicion-pagos.service';

@Component({
  selector: 'app-select-tipobaja',
  templateUrl: './select-tipobaja.component.html',
  styleUrls: ['./select-tipobaja.component.scss'],
})
export class SelectTipobajaComponent implements OnInit, ICellRendererAngularComp {

  params: ICellRendererParams;
  cellData: any;
  defaultSelected: any;
  visible: boolean = false;


  public bajas: []




  constructor(
    private pagoService: ComposicionPagosService
  ) {
    this.bajas = this.pagoService.bajas;
  }

  ngOnInit() {

  }

  agInit(params: ICellRendererParams) {
    this.params = params;
    if (params.colDef.headerName == 'Estatus' && params.data.yearMonth !==
      "Total" && params.data.yearMonth !== "Total") {

      this.visible = true
      this.defaultSelected = params.data.fk_tipo_baja == null ? null : params.data.fk_tipo_baja;
    }
  }


  refresh(params: any): boolean {

    this.cellData = params.value;
    return true;
  }

  changeSelected(val) {
    // console.log({ iCreditId: this.params.data });
    let addOldParams = null

    if (this.params.data.comentario_baja) {
      addOldParams = {
        'comentario': this.params.data.comentario_baja
      }
    }
    if (this.params.data.fecha_baja) {
      addOldParams = {
        ...addOldParams,
        'fecha': new Date(this.params.data.fecha_baja).toISOString()
      }
    }

    this.pagoService.saveFired = {
      [this.params.data.iCreditId]: {
        'fk_tipo_baja': Number(val),
        ...addOldParams
      },
    }

    this.params.setValue(Number(val))
  }


}
