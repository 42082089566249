import { Component, Input, OnInit } from '@angular/core';
import { Colocacion } from '../../Interface/colocacion';
import { ReportesService } from '../../services/reportes.service';
// import { ToastService } from './ src/app/toast.service';
import { ToastService } from '../../../toast.service';


import { DateService } from '../../shared/date.service';

// import { RepColocacionService } from 'src/app/services/REP/rep-colocacion.service';

@Component({
    selector: 'app-colocacion',
    templateUrl: './colocacion.component.html',
    styleUrls: ['./colocacion.component.scss'],
})
export class ColocacionComponent implements OnInit {
    private initDate: string;
    private Finaldate: string;
    arrayInfo: Array<Colocacion>;
    showComponents: boolean; // para mantener el loader mientras se carga la info

    @Input() showGrafico: boolean = true

    constructor(private repservice: ReportesService,
        private repServ: ReportesService,
        private toastS: ToastService,
        private dateService: DateService) {

    }

    ngOnInit() {
        // this.toastS.loaderShow('Obteniendo informacion');
        this.showComponents = true;
        console.log('.....................esta es de colocacion 1')
        // this.repservice.enviarMensajeSubject.subscribe(response => {
        //     console.log('si hay respuesda desa el grafico',response)
        //     if (response) {
        setTimeout(() => {
            // this.toastS.loaderHide();
            console.log('................esta es de colocacion despues de los 3 segundos')
            // this.messageSuccess = false;
        }, 3000);
        //     }      
        //   })



        // this.repservice.enviarToatalesGraficaSubject.subscribe(response => {

        // if (response) {
        //     this.toastS.loaderHide();
        // } else {
        //     this.showComponents = false;
        // }
        // })
        // this.showComponents = false;
        // this.initDate = this.dateService.getinitDate();
        // this.Finaldate = this.dateService.getFinaldate();
        // this.getLoadData();
        // console.log('FinaldateFinaldateFinaldate', this.initDate, this.Finaldate);
    }

    async getLoadData() {
        // const tableResponse = await this.repservice.getREPColocacionTable(this.initDate, this.Finaldate);
        // if (tableResponse.code == 200) {
        //     this.arrayInfo = tableResponse.data // esto le voy a aenviar a mi tabla
        //     this.showComponents = true;
        //     this.toastS.loaderHide();
        // } else {
        //   this.showComponents = false;
        // }
    }

    showGraph(event) {
        console.log('showGraph', event)

        this.showGrafico = event
    }

}
