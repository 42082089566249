import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ColDef, Column, ColumnApi, GridApi, ICellRendererParams, RowNode, ValueSetterParams } from 'ag-grid-community';
import { ComposicionPagosService } from 'src/app/reportes/pages/composicionPagos/composicion-pagos.service';

@Component({
  selector: 'app-input-composicionpagos',
  templateUrl: './input-composicionpagos.component.html',
  styleUrls: ['./input-composicionpagos.component.scss'],
})
export class InputComposicionpagosComponent implements OnInit, ICellRendererAngularComp {

  params: ICellRendererParams;
  cellData: any;
  text: any;
  visible: boolean = false;



  constructor(
    private pagoService: ComposicionPagosService
  ) { }
  // oldValue: any;
  // newValue: any;
  // node: RowNode;
  // data: any;
  // column: Column;
  // colDef: ColDef;
  // api: GridApi;
  // columnApi: ColumnApi;
  // context: any;

  ngOnInit() {

  }

  agInit(params: ICellRendererParams) {
    this.params = params;

    if (params.colDef.headerName == 'Comentario Baja' && params.data.yearMonth !==
      "Total" && params.data.yearMonth !== "Total") {
      this.visible = true
      this.text = params.data.comentario_baja == null ? null : params.data.comentario_baja;
    }
  }

  // lueSetter: (par: ValueSetterParams) => {
  //         this.newValue = par.newValue;
  //   // const valueChanged = params.data.b !== newVal;
  //   // if(valueChanged) {
  //   //   params.data.b = newVal;
  //   // }
  //   //       return valueChanged;
  // }


refresh(params: any): boolean {

  this.cellData = params.value;
  return true;
}

onChangeTime(data): void {
  let addOldParams = null

  if (this.params.data.fk_tipo_baja){
    addOldParams = {
      'fk_tipo_baja': Number(this.params.data.fk_tipo_baja)
    }
  }
  if (this.params.data.fecha_baja){
    addOldParams = {
      ...addOldParams,
      'fecha': new Date(this.params.data.fecha_baja).toISOString()

    }
  }

  this.pagoService.saveFired = {
    [this.params.data.iCreditId]: {
      'comentario': data,
      ...addOldParams
    },
  }

  // console.log({ params: this.params.data, addOldParams });
    this.params.setValue(data)
}


}
