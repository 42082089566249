import { Injectable } from '@angular/core';
import { ComposicionPagosService } from './composicion-pagos.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Injectable({
    providedIn: 'root'
})
export class composicionPagosHistExcelService {

    private dataXls;
    private xlsHeaderHist;
    private reglasSemaforizacion;
    constructor() {
    }

    generateExcel(title, data, headers) {

        this.dataXls = data

        this.xlsHeaderHist = headers

        let header = [], HeaderToSeach = [], newData = []

        Object.entries(this.xlsHeaderHist).forEach(([key, vall]) => {
            header.push(vall)
            HeaderToSeach.push(key)
        })


        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet('Composicion pagos');

        // Add new row
        let titleRow = worksheet.addRow([title]);
        // Set font, size and style in title row.
        titleRow.font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true };
        // Blank Row
        worksheet.addRow([]);

        let headerRow = worksheet.addRow(header);
        // Cell Style : Fill and Border
        headerRow.eachCell((cell, number) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'ffffff' },
                bgColor: { argb: 'ffffff' }
            }
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            }
        });


        this.dataXls.forEach((ele) => {

            let rowTypeXls = {};

            let data = HeaderToSeach.map((keyElemt, index) => {

                let [key, typeOf] = keyElemt.split(':')

                rowTypeXls[index] = typeOf;

                return ele[key] || ((typeOf == 'string') ? 'N/A' : 0);

            })

            newData.push(data)
            let row = worksheet.addRow(data);

            Object.entries(rowTypeXls).forEach(([key, value]) => {
                switch (value) {
                    case 'number':
                        this.numberFormatter(row, Number(key) + 1)
                        break;
                    case 'float':
                        this.floatFormatter(row, Number(key) + 1)
                        break;
                    case 'percent':
                        this.percentFormatter(row, Number(key) + 1)
                        break;
                    case 'currency':
                        this.currencyFormatter(row, Number(key) + 1)
                        break;
                }

            })

        })

        workbook.xlsx.writeBuffer().then((newData) => {
            let blob = new Blob([newData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, 'Composicion-pagos.xlsx');
        });


    }

    
    numberFormatter(row, headerLenght) {
        let qty = row.getCell(headerLenght);
        return qty.style = {
            numFmt: '0'
        }
    }

    floatFormatter(row, headerLenght) {
        let qty = row.getCell(headerLenght);
        return qty.style = {
            numFmt: '0.0000'
        }
    }

    currencyFormatter(row, headerLenght) {
        let qty = row.getCell(headerLenght);
        return qty.style = {
            numFmt: '$#,##0.00_);[Red]($#,##0.00)'
            // numFmt: '#,##0.00_);(#,##0.00)'
        }
    }


    percentFormatter(row, headerLenght) {
        let qty = row.getCell(headerLenght);
        return qty.style = {
            numFmt: '0.0000%'
        }
    }

}