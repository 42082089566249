import { Component, OnInit, ViewChild } from '@angular/core';
import { CarteraService } from '../../../pages/cartera/cartera.service';
import { ToastService } from 'src/app/toast.service';

import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexYAxis,
  ApexPlotOptions,
  ApexResponsive,
  ApexXAxis,
  ApexLegend,
  ApexFill,
  ApexTooltip,
  ApexStroke,
  ApexGrid
} from "ng-apexcharts";
import { DateService } from '../../../shared/date.service';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  responsive: ApexResponsive[];
  xaxis: ApexXAxis;
  legend: ApexLegend;
  yaxis: ApexYAxis | ApexYAxis[];
  tooltip: ApexTooltip | ApexTooltip[];
  fill: ApexFill;
  stroke: ApexStroke;
  grid: ApexGrid;
};

@Component({
  selector: 'app-grafico-cartera',
  templateUrl: './grafico-cartera.component.html',
  styleUrls: ['./grafico-cartera.component.scss'],
})
export class GraficoCarteraComponent implements OnInit {

  @ViewChild("chart") chart: ChartComponent;
  // public totalyaxis;
  public chartOptions: Partial<ChartOptions>;
  private initDate: string;
  private Finaldate: string;
  private max: number = 1000000000000000000;

  constructor(
    private repservice: CarteraService,
    private dateService: DateService,
    private toastS: ToastService
  ) {
    // this.toastS.loaderShow('Obteniendo informacion');
  }

  data: any;

  arraySeries = [];


  ngOnInit() {
    this.initDate = this.dateService.FinTreceMeses;
    this.Finaldate = this.dateService.getFinaldate();

    // console.log( 'hola' ,this.repservice.data);

    this.repservice.enviarToatalesGraficaSubject.subscribe(response => {
      // console.log('si hay respuesda desa el grafico', response)
      if (response) {
        switchGrafico = this.repservice.switchGraficos
        if(switchGrafico == 'Porcentaje'){
          this.max = 1000
        }else if(switchGrafico == 'cantidad'){
          this.max = 1000000000000000000
        }
        this.data = response

        console.log('this.data', this.data)
        this.getArrayApex()
        // this.toastS.loaderHide();
      } else {

        // this.showComponents = false;
      }

    })

    // this.repservice.getsDataTable(this.initDate, this.Finaldate)
    //   .subscribe((resp: Colocacion) => {
    //     if (resp['code'] == 200) {

    //       this.getArrayApex()
    //     }
    //   });

    this.chartOptions = {
      series: [...this.arraySeries],

      chart: {
        type: "line",
        height: 250,
        width: '100%',
        stacked: true,
        background: '#fff',
        fontFamily: 'ayudate',
        foreColor: '#151618',
        redrawOnParentResize: true,
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 400,
          animateGradually: {
            enabled: true,
            delay: 150
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350
          }
        },
      },

      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          horizontal: false,
        }
      },

      xaxis: {
        type: "category",
        categories: []
      },
      yaxis: [
        {
          decimalsInFloat: 2
        }

      ],
      legend: {
        position: "right",
        offsetY: 40
      },
      fill: {
        opacity: 1
      },
    };
  }


  getArrayApex() {
    let carteraSana = this.getData(this.data, 'carteraSana');
    let cobranzaAdministrativa = this.getData(this.data, 'cobranzaAdministrativa');
    console.log('cobranzaAdministrativa',cobranzaAdministrativa)
    let cobranzaextraJudicial = this.getData(this.data, 'cobranzaextraJudicial');
    let carteraVendidaYDifuntos = this.getData(this.data, 'carteraVendidaYDifuntos');
    let Total = this.getData(this.data, 'Total');
    let categorias = this.getCategories(this.data);

    // let total = (categorias+carteraVendidaYDifuntos+cobranzaextraJudicial)

    let totalyaxis = this.mathYaxis(carteraSana, cobranzaAdministrativa, cobranzaextraJudicial, carteraVendidaYDifuntos)
    // console.log('creditosNuevos',creditosNuevos)
    // console.log('creditosRecargas',creditosRecargas)
    // console.log('totalCreditosNuevosRecargas',totalCreditosNuevosRecargas)
    // console.log('metasMeses',metasMeses)
    this.arraySeries = [
      
      {
        name: 'Cartera vendida y Difuntos',
        type: 'column',
        data: carteraVendidaYDifuntos,
        color: '#FF6679'
      },
      {
        name: 'Cobranza extra judicial',
        type: 'column',
        data: cobranzaextraJudicial,
        color: '#FC9047'
      },
      {
        name: 'Cobranza administrativa',
        type: 'column',
        data: cobranzaAdministrativa,
        color: '#FFF35C'
      },
      {
        name: 'Cartera sana',
        type: 'column',
        data: carteraSana,
        color: '#05db8e',
      },
      {
        name: 'Total',
        type: 'radialBar',
        data: Total,
        color: '#ffd000'
      },
      
    ];
    // console.log('arraySeries', this.arraySeries);

    this.chartOptions = {

      series: [...this.arraySeries],
      chart: {
        type: "line",
        height: '250px',
        width: '100%',
        stacked: true,
        background: '#fff',
        fontFamily: 'ayudate',
        foreColor: '#151618',
        redrawOnParentResize: true,
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 400,
          animateGradually: {
            enabled: true,
            delay: 150
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350
          }
        },
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false,
        },

      },

      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],

      plotOptions: {
        bar: {
          horizontal: false
        },
      },
      xaxis: {
        type: "category",
        categories: categorias
      },
      yaxis: [
        {
          // max: totalyaxis
          logarithmic:true,
          logBase: 2,
          min: 0,
          max: this.max,
          labels: {
            show: false,
            // formatter: labelFormatter,
          }
        },
        {
          // max: totalyaxis/2,
          seriesName: "Cartera sana",
          max: totalyaxis,
          tickAmount: 6,
          labels: {
            show: true,
            formatter: labelFormatter,
          }
        }
      ],
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {

          let carterasana = moneyFormatMxn(carteraSana[dataPointIndex])
          let cobranzaadministrativa = moneyFormatMxn(cobranzaAdministrativa[dataPointIndex])
          let cobranzaextrajudicial = moneyFormatMxn(cobranzaextraJudicial[dataPointIndex])
          let carteravendidaDifuntos = moneyFormatMxn(carteraVendidaYDifuntos[dataPointIndex])
          let total = moneyFormatMxn(Total[dataPointIndex])
          // console.log('..............w', w.globals)
          return (
            '<div class="header-toltip">' + '<span >' + w.globals.categoryLabels[dataPointIndex] + '</span>' + '</div>' +
            '<ul>' +
            '<li class="li-toltip-red"><span class="span-tooltip"> ' + w.globals.seriesNames[0] + ': ' + carteravendidaDifuntos + '</span></li>' +

            '<li class="li-toltip-orange"><span class="span-tooltip"> ' + w.globals.seriesNames[1] + ': ' + cobranzaextrajudicial + '</span></li>' +

            '<li class="li-toltip-yellowL"><span class="span-tooltip"> ' + w.globals.seriesNames[2] + ': ' + cobranzaadministrativa + '</span></li>' +

            '<li class="li-toltip-green"><span class="span-tooltip"> ' + w.globals.seriesNames[3] + ': ' + carterasana + '</span></li>' +

            '<li class="li-toltip-yellow"><span class="span-tooltip"> ' + w.globals.seriesNames[4] + ': ' + total + '</span></li>' +
            '</ul>'
          )
        }
      },

      legend: {
        position: "right",
        offsetY: 40
      },
      fill: {
        opacity: 1
      },




    };



    // return arraySeries;

  }

  mathYaxis(creditosNuevos, creditosRecargas, totalCreditosNuevosRecargas, metasMeses) {
    let Cn = Math.max(...creditosNuevos);
    let Cr = Math.max(...creditosRecargas);
    let Tn = Math.max(...totalCreditosNuevosRecargas);
    let Mm = Math.max(...metasMeses);

    let suma = Math.max(Cn, Cr, Tn, Mm)

    let val = parseInt(suma.toString().split('').shift()) + 1
    let longitud = Math.round(suma).toString().length
    // Math.round(      
    let result = val.toString().padEnd(longitud, '0')

    return parseInt(result)
  }

  getCategories(array) {
    let nuevo = array
    let arrayMetas = [];
    let f;
    nuevo.forEach(element => {
      f = element.Historico
      return arrayMetas.push(f);
    });
    return arrayMetas;
  }

  getData(array, columna) {
    // console.log('khmartinez**************************',array)
    let nuevo = array
    let arrayCreditosNuevos = [];
    let f;
    nuevo.forEach(element => {
      f = element[columna]

      return arrayCreditosNuevos.push(f);
    });
    return arrayCreditosNuevos;
  }

  getDataNuevos(array) {
    // console.log('khmartinez**************************',array)
    let nuevo = array
    let arrayCreditosNuevos = [];
    let f;
    nuevo.forEach(element => {
      f = element.carteraSana

      return arrayCreditosNuevos.push(f);
    });
    return arrayCreditosNuevos;
  }

  getDataRecargas(array) {
    let nuevo = array
    let arrayCreditosRecargas = [];
    let f;
    nuevo.forEach(element => {
      f = element.carteraVendidaYDifuntos
      return arrayCreditosRecargas.push(f);
    });
    return arrayCreditosRecargas;
  }

  getDataMeta(array) {
    // console.log('arrayyyyyyyyy',array)
    let nuevo = array
    // console.log('nuevoooooooooo',nuevo)
    let arrayMetas = [];
    let f;
    nuevo.forEach(element => {
      f = element.cobranzaextraJudicial
      return arrayMetas.push(f);
    });
    return arrayMetas;
  }

  getTotalCreditosNuevosRecargas(array) {
    let nuevo = array
    let arrayMetas = [];
    let f;
    nuevo.forEach(element => {
      f = element.resolucionFiniquitoAdmin
      return arrayMetas.push(f);
    });
    return arrayMetas;
  }
}

let switchGrafico = 'cantidad';

let labelFormatter = function (value) {
  // console.log(value,' labelFormatter ')
  let val
  val = Math.abs(value);
  if (switchGrafico == 'cantidad' ) {
    if (val >= 1000000) {
      val = (val / 1000000).toFixed(1) + " M";
    }else{

      val = val.toFixed(1) + " Mil"
    }
  } else if (switchGrafico == 'Porcentaje') {
    // val = (val/10)*100
    val = val.toFixed() + "%";

  } 
  
  // console.log(val,' labelFormatter val')
  // else {
  //   val = 0
  // }
  return val;
};


function moneyFormatMxn(cant) {
  let formatter 
  // console.log('switchGrafico ',switchGrafico)
  if(switchGrafico == 'cantidad'){
    formatter = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      minimumFractionDigits: 2
    });
    formatter =formatter.format(cant);
  }else if(switchGrafico == 'Porcentaje'){
    formatter = cant.toFixed(2) + '%'
  }
  
  return formatter
}