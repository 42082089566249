import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ComposicionPagosService } from 'src/app/reportes/pages/composicionPagos/composicion-pagos.service';
import { ToastService } from 'src/app/toast.service';

@Component({
  selector: 'app-action-composicionpagos',
  templateUrl: './action-composicionpagos.component.html',
  styleUrls: ['./action-composicionpagos.component.scss'],
})
export class ActionComposicionpagosComponent implements OnInit, ICellRendererAngularComp {

  cellData: any;
  params: ICellRendererParams;
  visible: boolean = false;

  constructor(
    private pagoService: ComposicionPagosService,
    private toast: ToastService,
  ) {}

  ngOnInit() {

  }

  agInit(params: ICellRendererParams) {
    this.params = params;

    if (params.data.yearMonth !== "Total" && params.data.yearMonth !== "Total") {
      this.visible = true
    }
    
  }


  refresh(params: any): boolean {
    this.cellData = params.value;
    return true;
  }

  async onClick() {
    let response = await  this.pagoService.saveFiredByCredit(this.params.data.iCreditId)
    if (response && response['code'] == '200') {
      this.toast.show('success', response['msj']);
    }else{
      this.toast.show('warning', response['msj']);
    }
    console.log({ response });
  }

}
