import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';


@Component({
  selector: 'app-anchor-tag-cells',
  templateUrl: './anchor-tag-cells.component.html',
  styleUrls: ['./anchor-tag-cells.component.scss'],
})
export class AnchorTagCellsComponent implements OnInit, ICellRendererAngularComp {

  cellData: any;
  params: ICellRendererParams;
  tag: string;

  constructor() { }

  ngOnInit() {}


  agInit(params: ICellRendererParams) {
    this.params = params;

    if (params.colDef.headerName == 'Hash recargas') {
      this.tag = params.data.hash_recargas == null ? '' : params.data.hash_recargas;
    }
  }

  converText(title) {
    return title.toUpperCase();
  }

  IdFormat() {

  }

  numberFormat(cant) {
    const formatter = new Intl.NumberFormat('es-MX', {
      minimumFractionDigits: 2,
      currency: 'MXN'
    });
    return formatter.format(cant);
    // Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(monto)
  }
  refresh(params: any): boolean {
    this.cellData = params.value;
    return true;
  }

}


