
import { Component, OnInit } from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';


@Component({
  selector: 'app-date-cells',
  templateUrl: './date-cells.component.html',
  styleUrls: ['./date-cells.component.scss'],
})
export class DateCellsComponent implements OnInit,ICellRendererAngularComp {
  cellData: any;
  params: ICellRendererParams;
  money: string;
  dateObj : string;

  constructor() { }

  ngOnInit() {}

  agInit(params: ICellRendererParams){
    this.params = params;
    if (params.colDef.headerName == 'Año / mes'){
      this.dateObj  = params.data.yearMonth == null ? '' : params.data.yearMonth ;
    } else if(params.colDef.headerName == 'Año'){
      this.dateObj  = params.data.year == null ? '' : params.data.year ;
    } else if (params.colDef.headerName == 'Fecha de movimeinto'){
      this.dateObj = params.data.vLMovementDateO == null ? '' : params.data.vLMovementDateO ;
    } else if (params.colDef.headerName == 'Res fin'){
      this.dateObj = params.data.vResFin == null ? '' : params.data.vResFin ;
    } else if (params.colDef.headerName == 'Ex empleado ayudate'){
      this.dateObj = params.data.vExEmpAyu == null ? '' : params.data.vExEmpAyu ;
    } else if (params.colDef.headerName == 'Fecha cambio exempleado'){
      this.dateObj = params.data.Fecha_Cambio_Exempleado == null ? '' : params.data.Fecha_Cambio_Exempleado ;
    } else if (params.colDef.headerName == 'Fecha Comentario'){
      this.dateObj = params.data.vFecha_ConGes == null ? '' : params.data.vFecha_ConGes ;
    } else if (params.colDef.headerName == 'Fecha estatus credito'){
      this.dateObj = params.data.vFecha_CStatus == null ? '' : params.data.vFecha_CStatus ;
    }
    else if (params.colDef.colId == 'Fecha_inicio_Composicionpagos'){
      this.dateObj = params.data.Fecha_inicio == null ? '' : params.data.Fecha_inicio;
    } else if (params.colDef.colId == 'Fecha_fin_Composicionpagos'){
      this.dateObj = params.data.Fecha_fin == null ? '' : params.data.Fecha_fin ;
    } else if (params.colDef.colId == 'Fecha_actualizacion_Composicionpagos'){
      this.dateObj = params.data.Fecha_actualizacion == null ? '' : params.data.Fecha_actualizacion ;
    }
    
    
    if (params.colDef.colId == 'fecha_limite'){
      this.dateObj = params.data.fecha_limite == null ? '' : params.data.fecha_limite ;
    }

  }

  refresh(params: any): boolean {
    this.cellData = params.value;
    return true;
  }

}
