import { Component, OnInit } from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';


@Component({
  selector: 'app-words-cells-cartera',
  templateUrl: './words-cells-cartera.component.html',
  styleUrls: ['./words-cells-cartera.component.scss'],
})
export class WordsCellsCarteraComponent implements OnInit {
  cellData: any;
  params: ICellRendererParams;
  name: string;
  constructor() { }

  ngOnInit() {}

  agInit(params: ICellRendererParams){
    this.params = params;
    if (params.colDef.headerName == 'Empresa'){
      this.name = params.data.empresa == null ? '' : this.converText(params.data.empresa);
    } else if (params.colDef.headerName == 'Mercado'){
      this.name = params.data.mercado == null ? '' : this.converText(params.data.mercado);
    } else if (params.colDef.headerName == 'Convenio Actual'){
      this.name = params.data.convenioActual == null ? '' : this.converText(params.data.convenioActual);
    }else if (params.colDef.headerName == 'Vendedor'){
      this.name = params.data.vendedor == null ? '' : this.converText(params.data.vendedor);
    }else if (params.colDef.headerName == 'Supervisor'){
      this.name = params.data.supervisor == null ? '' : this.converText(params.data.supervisor);
    }else if (params.colDef.headerName == 'Organizacion'){
      // console.log('Organizacion',params.data)
      this.name = params.data.organizacion == null ? '' : this.converText(params.data.organizacion);
    }else if (params.colDef.headerName == 'Sector Producto'){
      this.name = params.data.sectorProducto == null ? '' : this.converText(params.data.sectorProducto);
    }else if (params.colDef.headerName == 'Producto'){
      this.name = params.data.producto == null ? '' : this.converText(params.data.producto);
    }
  }
  converText(title){
    return title.toUpperCase();
  }
  refresh(params: any): boolean {
    this.cellData = params.value;
    return true;
  }

}
