import { Injectable } from '@angular/core';
// import { HttpService } from 'e2e/src/app/http.service';
import { HttpService } from 'src/app/http.service';

import { HttpClient } from '@angular/common/http';

import { Subject } from 'rxjs';
import { recarga_prospecto } from '../../models/recarga_prospecto';
import { environment } from 'e2e/src/environments/environment';
import { DateService } from '../../shared/date.service';
@Injectable({
  providedIn: 'root'
})
export class RecargaService {


  private fechaIni;
  private fechaFin;
  private filtros: any;
  private category: any;
  private dataXls;
  private Msj;
  public data;

  urlBase = 'https://web.whatsapp.com/send?phone=';
  public templatesMsj: [];

  public enviarMensajeSubject = new Subject<{}>();
  // public MsjSubject = new Subject<{}>();

  constructor(private _http: HttpService,
    private http: HttpClient,
    private dateformatter:DateService
  ) {

  }

  get datas() {
    return this.data;
  }

  set datas(val: []) {
    if (val) {
      this.data = val
    }
  }

  get tMsj() {
    return this.Msj;
  }

  set tMsj(val: []) {
    if (val) {
      this.Msj = val
    }
  }

  get templateMsj() {
    return this.templatesMsj;
  }

  set templateMsj(val: []) {
    if (val) {
      this.templatesMsj = val
    }
  }

  get fechaInicio() {
    return this.fechaIni
  }

  set fechaInicio(val: string) {
    this.fechaIni = val
  }

  get fechafin() {
    return this.fechaFin
  }

  set fechafin(val: string) {
    this.fechaFin = val
  }

  get filtro() {
    return this.filtros
  }

  set filtro(val: []) {
    if (val) {
      this.filtros = val
    }
  }


  get xlsData() {
    return this.dataXls
  }

  // sendMsj(val){
  //   this.MsjSubject.next(val)
  // }


  enviarMensaje(idCode) {

    try {

      let year1Date, Finaldate, agrupador, filtros;
      filtros = this.filtro
      year1Date = this.fechaInicio;
      Finaldate = this.fechafin;


      console.log(`reportes/recarga`)
      this._http.getREP(`reportes/recarga?EXT=${idCode}`)
        .toPromise()
        .then(resp => {

          if (resp['code'] == 200) {
            this.data = resp['data'];
            this.templateMsj = resp['templateMsj']
            // console.log('data:resp[]', this.templateMsj)
            // console.log('peticion correcta',resp)
            // if(categoies.includes(','))categoies = categoies.split(', ');
            this.dataXls = resp['data']
            this.enviarMensajeSubject.next({
              // filtros,
              data: resp['data']
              // agrupador,
              // categoies
            })
          }

          return resp
        })
    } catch (error) {
      console.log('error', error)
      return error;
    }
  }

  guardarProspecto(prospecto: recarga_prospecto) {
    // console.log(environment.ws + 'reportes/guardarRecarga', prospecto)

    return this._http.postREP('reportes/guardarRecarga', prospecto)
  }

  updateSubjectOneByOne(iCreditId?, url?) {
    // console.log('params', iCreditId, url)


    console.log('iPersonId', this.data)
    Object.entries(this.data).forEach(([key, value]) => {
      if (value['iCreditId'] == iCreditId) {
        value['sendMsj'] = url;
      }
    });

    let data = this.data
    // console.log(data)
    this.enviarMensajeSubject.next({ data })


  }



  updateAllSubject(value, id?, opcion?) {

    let urlBase = 'https://web.whatsapp.com/send?phone=';

    Object.entries(this.data).forEach(([key, val]) => {
      // console.log(val)
      let celular = val['vCelular']
      let idCredit = val['iCreditId']
      let msj, fisrNameClient, firstNameVen, importe, hash;
      fisrNameClient = val['nombre'].split(' ')[0].toLowerCase();
      firstNameVen = val['vendedor'].toLowerCase();
      importe = val['importe'];
      hash = val['hash_recargas'];

      if (opcion !== 'Limpiar') {
        if (id.includes(idCredit) && celular !== "0000000000" && !celular.includes('(')) {
            // let url = this.urlBase + '+52' + celular + '&text=' + value;
          msj = urlBase + '+52' + celular + '&text=' +  value.replaceAll('{1}', fisrNameClient)
              .replaceAll('{2}', firstNameVen)
              .replaceAll('{3}', importe)
              .replaceAll('{4}', idCredit)
              .replaceAll('{5}', hash)
            // console.log('updateAllSubject', msj)
            val['sendMsj'] = msj
          
        }
      }
      else{
        val['sendMsj'] = ''
      }

    });
    let data = this.data

    // console.log('updateAllSubject', data)
    // console.log('updateAllSubject', id)
    this.enviarMensajeSubject.next({ data })
  }

  UpdateData(credito){
    let date = credito['fecha_modificacion']
    console.log('date antes de ', date)
    
    
    if (Number.isInteger(date)){
      // parseInt(date);
      date = this.dateformatter.getDateFormat(date);
    }

    console.log('date despues de ', date) 
    Object.entries(this.data).forEach(([key, value]) => {
      if (value['iCreditId'] == credito['credito']) {
        value['estatus'] = credito['estatus'];
        value['fecha_modificacion'] = date;
      }
    });

    let data = this.data
    // console.log(data)
    this.enviarMensajeSubject.next({ data })


  }




}



