import { Injectable } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  loaderCharged:boolean;
  constructor(public toast:ToastController,public loaderC:LoadingController) { 
    this.loaderCharged = false;
  }

  async show(type:string,message:string) {
    const toast = await this.toast.create({
      message,
      color:type,
      position:'top',
      duration: 3000,
      cssClass: 'ion-text-center'
    });
    toast.present();
  }
  public loaderShow(message:string){
    setTimeout(() => {
      if(this.loaderCharged){
        this.loaderShow(message);
      }else{
        this.loaderC.create({
          message
        }).then(a=>{a.present();this.loaderCharged=true});
      }
    }, 100);
  }
  public loaderHide(){
    setTimeout(() => {
      if(this.loaderCharged){
        this.loaderCharged=false;
        this.loaderC.dismiss();
      }else{
        this.loaderHide();
      }
    }, 100);
  }
}
