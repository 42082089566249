import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/http.service';

import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CarteraService {

  private fechaIni;
  private fechaFin;
  private filtros: any;
  private category: any;
  private dataXls;

  private agrupador: string = 'hist';
  private switchGrafico: string = 'cantidad'; // Porcentaje ,cantidad

  public enviarMensajeSubject = new Subject<{}>();
  public enviarToatalesGraficaSubject = new Subject<{}>();

  constructor(private _http: HttpService) {

  }

  get fechaInicio() {
    return this.fechaIni
  }

  set fechaInicio(val: string) {
    this.fechaIni = val
  }

  get fechafin() {
    return this.fechaFin
  }

  set fechafin(val: string) {
    this.fechaFin = val
  }

  get filtro() {
    return this.filtros
  }

  set filtro(val: []) {
    if (val) {
      this.filtros = val
    }
  }

  get categories() {
    return this.category
  }

  set categories(val: []) {
    if (val) {
      this.category = val
    }
  }

  get agrupadores() {
    return this.agrupador
  }

  set agrupadores(val: string) {
    this.agrupador = val
  }

  get xlsData() {
    return this.dataXls
  }

  get switchGraficos() {
    return this.switchGrafico
  }

  set switchGraficos(val: string) {
      this.switchGrafico = val
  }

  enviarMensaje() {

    try {

      let year1Date, Finaldate, agrupador, filtros, category;
      filtros = this.filtro
      agrupador = this.agrupadores;
      year1Date = this.fechaInicio;
      Finaldate = this.fechafin;
      category = this.category
      let fil
      if (!this.filtro) {
        fil = []
      } else if (this.filtro.length > 1) {
        fil = filtros.join(', ')
        // console.log(fil)
      } else {
        fil = filtros.concat(' ')
      }


      console.log(`reportes/cartera?groupBy=${agrupador}, ${fil}&initDate=${year1Date}&finalDate=${Finaldate}`)
      this._http.getREP(`reportes/cartera?groupBy=${agrupador}, ${fil}&initDate=${year1Date}&finalDate=${Finaldate}`)
        .toPromise()
        .then(resp => {
          if (resp['code'] == 200) {
            // if(categoies.includes(','))categoies = categoies.split(', ');
            this.dataXls = resp['data']
            this.enviarMensajeSubject.next({
              filtros,
              data: resp['data'],
              agrupador,
              // categoies
            })
          }
          console.log('data:resp[]', this.dataXls)
          return resp
        })
    } catch (error) {
      console.log('error', error)
      return error;
    }
  }

}
