import { Component, OnInit } from '@angular/core';
import { Camera, PictureSourceType } from '@ionic-native/camera/ngx';
import { ActionSheetController, Platform } from '@ionic/angular';
//import * as Tesseract from 'tesseract.js';
import {createWorker} from 'tesseract.js';
@Component({
  selector: 'app-ocr-scaner',
  templateUrl: './ocr-scaner.component.html',
  styleUrls: ['./ocr-scaner.component.scss'],
})
export class OcrScanerComponent implements OnInit {

  private worker: Tesseract.Worker;
  public progress:number = 0;
  public selectedImage:string;
  public imageText:string;
  public isLoading:boolean;
  public workerReady:boolean;

  constructor(
    public actionSheetCtrl: ActionSheetController, 
    private camera:Camera,
    private platform:Platform) { }

  ngOnInit() {
    this.isLoading = false;
    this.workerReady = false;
    this.initFileSelector();
    this.loadWorker();
  }

  public async loadWorker(){
    this.worker = createWorker({
      logger: (progress) => {
        if(progress.status == 'recognizing text'){
          this.progress = parseInt('' + progress.progress * 100);
        }
      }
    });
    await this.worker.load();
    await this.worker.loadLanguage('spa');
    await this.worker.initialize('spa');
    this.workerReady = true;
  }

  public async selectSource(){
    let actionSheet = await this.actionSheetCtrl.create({
      buttons:[
        {
          text:'Use Library',
          handler: () => {
            this.getPicture(this.camera.PictureSourceType.PHOTOLIBRARY);
          }
        },
        {
          text:'Capture Image',
          handler: () => {
            this.getPicture(this.camera.PictureSourceType.CAMERA);
          }
        },
        {
          text:'Cancel',
          role:'cancel'
        }
      ]
    });
    actionSheet.present();
  }

  private initFileSelector(){
    let element = document.querySelector('.control_files') as any;
    element.addEventListener('change', (event) => {
      const archivo = event.target.files[0];
      const reader = new FileReader();
      if(this.platform.is('cordova')){
        reader.onload = ( event => {
          console.log(archivo);
          this.selectedImage = reader.result.toString();
        });
      }else{
        reader.addEventListener('load', (event:any) => {
          console.log(archivo);
          this.selectedImage = reader.result.toString();
        });
      }
      reader.readAsDataURL(archivo);
    });
  }

  private getPicture(sourceType: PictureSourceType){
    if(this.platform.is('cordova')){
      this.camera.getPicture({
        quality:100,
        destinationType:this.camera.DestinationType.DATA_URL,
        sourceType: sourceType,
        allowEdit: true,
        saveToPhotoAlbum: false,
        correctOrientation:true
      }).then( imageData => {
        this.selectedImage = `data:image/jpeg;base64,${imageData}`;
      });
    }else{
      let element = document.querySelector('.control_files') as any;
      element.click();
    }
  }

  public async recognizeImage(){
    this.isLoading = true;
    let result = await this.worker.recognize(this.selectedImage);
    console.log(result);
    this.imageText = "";
    //let valid = new RegExp(/[.$:;,£á?¿({-~!"'“»^_!\\¡`\—]/);
    let finish = false;
    result.data.lines.forEach( line => {
      let text = '';
      line.paragraph.lines.forEach( l => {
        if(!finish){
          let textAux = '';
          l.words.forEach( word => {
            //console.log(!valid.test(word.text));
            //console.log(word.text.replace(/[.$:;,£´?({-~!"'“»^_!\\¡`\—]/, ''));
            textAux = word.text.replace(/[.$:;,£á?¿(){-~!"'“»^_!\\¡`\—]/, '').trim();
            text += `${textAux} `;
          });
            this.imageText += `<br>${text}</br>`;
            text = '';
          }
      });
      finish = true;
      return true;
      //\b($word)\b
      //this.imageText += `<br>${text}</br>`;
    });
    this.isLoading = false;
    this.progress = 0;
  }
}
