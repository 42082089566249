import { Injectable } from "@angular/core";
import { HttpService } from '../http.service';
import { StorageService } from '../storage.service';


@Injectable({
    providedIn: 'root'
})
export class loggerService{

    constructor(private _http: HttpService){}

    async saveError(responnse, id_solicitud, description): Promise<Object>{
        return await this._http.post('logger/error', { id_solicitud, responnse, description }).toPromise();
    }

    async saveDeviceInfo(responnse, id_solicitud, description){
        return await this._http.post('logger/error', { responnse, id_solicitud, description }).toPromise();
    }

}