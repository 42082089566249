import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpService } from 'src/app/http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ComposicionPagosService {

  private fechaIni;
  private fechaFin;
  private paymentDateIni;
  private paymentDateFin;
  private typeDate = 'Fecha_Cambio_Exempleado';
  private groupType = 'Fecha_Cambio_Exempleado';

  private filtros: [];
  private filtrosHist: [];
  private tiposDeBaja: [];
  private dataXls;
  private dataXlsHist;
  private dataXlsHeaderHist;
  private search = true;

  //disculpa el spanglish (:)> 
  private bajasToSave = {};

  private agrupador: string = 'yearMonth';

  private agrupadorPeriodo: string = 'months';

  public enviarMensajeSubject = new Subject<{}>();
  public enviarToatalesGraficaSubject = new Subject<{}>();
  public dataHist = new Subject<{}>();
  public dataHistGrafica = new Subject<{}>();

  constructor(
    private _http: HttpService,
    private http: HttpClient
  ) { }

  get xlsHeaderHist() {
    return this.dataXlsHeaderHist
  }

  set xlsHeaderHist(val: any) {
    if (val) {
      this.dataXlsHeaderHist = val
    }
  }

  get fechaInicio() {
    return this.fechaIni
  }

  set fechaInicio(val: string) {
    this.fechaIni = val
  }

  get getAgrupadorPeriodo() {
    return this.agrupadorPeriodo
  }

  set setAgrupadorPeriodo(val: string) {
    this.agrupadorPeriodo = val
  }

  get fechafin() {
    return this.fechaFin
  }

  set fechafin(val: string) {
    this.fechaFin = val
  }

  get paymentDateF() {
    return this.paymentDateFin
  }

  set paymentDateF(val: string) {
    this.paymentDateFin = val
  }

  get paymentDateI() {
    return this.paymentDateIni
  }

  set paymentDateI(val: string) {
    this.paymentDateIni = val
  }

  get paymentDate() {
    return this.paymentDate
  }

  set paymentDate(val: string) {
    this.paymentDate = val
  }

  get filtro() {
    return this.filtros
  }

  set filtro(val: []) {
    if (val) {
      this.filtros = val
    }
  }

  get filtroHist() {
    return this.filtrosHist
  }

  set filtroHist(val: []) {
    if (val) {
      this.filtrosHist = val
    }
  }

  get agrupadores() {
    return this.agrupador
  }

  set agrupadores(val: string) {
    this.agrupador = val
  }


  get seachTypeDate() {
    return this.typeDate
  }

  set seachTypeDate(val: string) {
    this.typeDate = val
  }

  get typeGroup() {
    return this.groupType
  }

  set typeGroup(val: string) {
    this.groupType = val
  }


  get xlsData() {
    return this.dataXls
  }

  set xlsData(val: any) {
    if (val) {
      this.dataXls = val
    }
  }

  get xlsDataHist() {
    return this.dataXlsHist
  }

  set xlsDataHist(val: any) {
    if (val) {
      this.dataXlsHist = val
    }
  }
  get searchGet() {
    return this.search
  }

  set searchSet(val: any) {
    if (val) {
      this.search = val
    }
  }

  get bajas() {
    return this.tiposDeBaja
  }

  set bajas(val: []) {
    if (val) {
      this.tiposDeBaja = val
    }
  }
  get saveFired() {
    return this.bajasToSave
  }

  set saveFired(val: {}) {

    if (val) {
      let key = Number(Object.keys(val)[0])
      this.bajasToSave[key] = {
        ...this.bajasToSave[key],
        ...val[key]
      }
      // console.log({ bajasToSave: this.bajasToSave });
    }
  }



  async getData() {

    try {

      let year1Date, Finaldate, agrupador, filtros, typeDate, fecha;
      filtros = this.filtro
      agrupador = this.agrupadores;
      year1Date = this.fechaInicio;
      Finaldate = this.fechafin;
      typeDate = this.typeDate
      let fil
      if (!this.filtro) {
        fil = []
      } else if (this.filtro.length > 1) {
        fil = filtros.join(', ')
      } else {
        fil = filtros.concat(' ')
      }


      return await this._http.getREP(`reporte/cobranza?groupBy=${agrupador},${fil}&initDate=${year1Date}&finalDate=${Finaldate}&typeGroup=${this.groupType}&paymentDateF=${this.paymentDateF}&paymentDateI=${this.paymentDateI}`)
        .toPromise()
        .then(resp => {
          if (resp['code'] == 200) {
            this.searchSet = false
            // if(categoies.includes(','))categoies = categoies.split(', ');
            this.dataXls = resp['data']
            this.tiposDeBaja = resp['bajas']
            this.enviarMensajeSubject.next({
              filtros,
              data: resp['data'],
              agrupador,
              // categoies
            })
          }
          // console.log('data:resp[]', this.dataXls)
          return resp
        })
    } catch (error) {
      console.log('error', error)
      return error;
    }
  }

  async getDataHist() {

    try {
      let filtros
      if (this.filtroHist) {
        let filtro = this.filtroHist.join(',')

        filtros = filtro.includes('totalAdministrativo,totalExtrajudicial') ?
          filtro.replace('totalAdministrativo,totalExtrajudicial', '') : filtro
      }

      return await this._http.getREP(`reporte/cobranzaHist?filtros=${filtros}&timePeriod=${this.agrupadorPeriodo}`)
        .toPromise()
        .then(resp => {

          if (resp['code'] == 200) {
            this.dataXlsHist = resp['data']
            this.dataHist.next({
              code: resp['code'],
              filtros: this.filtrosHist,
              data: resp['data']
            })
          }
          return resp['data']
          // console.log('data:resp[]', this.dataXls)

        })

      // return await  this._http.getREP(`reporte/cobranza?groupBy=${agrupador},${fil}&initDate=${year1Date}&finalDate=${Finaldate}&typeGroup=${this.groupType}&paymentDateF=${this.paymentDateF}&paymentDateI=${this.paymentDateI}`)
      //   .toPromise()
      //   .then(resp => {
      //     if (resp['code'] == 200) {
      //       this.searchSet = false
      //       // if(categoies.includes(','))categoies = categoies.split(', ');
      //       this.dataXls = resp['data']
      //       this.enviarMensajeSubject.next({
      //         filtros,
      //         data: resp['data'],
      //         dataHist: resp['dataHistCobranza'],
      //         agrupador,
      //         // categoies
      //       })
      //     }
      //     // console.log('data:resp[]', this.dataXls)
      //     return resp
      //   })

    } catch (error) {

    }

  }

  async saveFiredByCredit(id) {


    let credit = this.bajasToSave[id]

    if (credit &&
      credit.hasOwnProperty('fk_tipo_baja') &&
      credit.hasOwnProperty('comentario') &&
      credit.hasOwnProperty('fecha')
    ) {

      let reponse = await this._http.postREP(`reporte/saveCobranza`,
        {
          ...credit,
          id_credito: id
        }
      ).toPromise()
        .then((res: Response) => {
          return res
        })

      return reponse;
    } else {
      return { msj: 'todos los campos son obligatorios', code: 500 };

    }


  }


}
