import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-tooltip-cells-cartera',
  templateUrl: './custom-tooltip-cells-cartera.component.html',
  styleUrls: ['./custom-tooltip-cells-cartera.component.scss'],
})
export class CustomTooltipCellsCarteraComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
