import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { StorageService } from '../storage.service';
import { SolicitudesService } from './solicitudes.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private solicitudesS:SolicitudesService, private http:HttpService,private storage:StorageService) { }

  async autenticarCliente(body:Object):Promise<Object>{
    let result = await this.http.post('login/autenticar_cliente',body).toPromise();
    if(result['code']==200){
      await this.storage.removeData('coach');
      this.http.setHeader(result['token'],result['correo']);
      await this.storage.setData('auth',[result['token'],result['correo']]);
      let result2 = await this.http.get('login/datos_cliente').toPromise();
      await this.storage.setData('solicitante',result2['data']['solicitante']);
      await this.storage.setData('solicitudes',result2['data']['solicitudes']);
      let zell_user = await this.solicitudesS.buscarPersonaZell(result2['data']['solicitante'])
      await this.storage.setData('solicitante_zell', zell_user['data']);
    }
    return result;
  }
  async cerrarSesionCliente():Promise<Object>{
    let result = await this.http.patch('login/cerrar_sesion_cliente',{}).toPromise();
    this.http.setHeader();
    this.storage.removeAll();
    return result;
  }
}
