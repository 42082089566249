import { Component, OnInit } from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';


@Component({
  selector: 'app-words-cells',
  templateUrl: './words-cells.component.html',
  styleUrls: ['./words-cells.component.scss'],
})
export class WordsCellsComponent implements OnInit, ICellRendererAngularComp {
  cellData: any;
  params: ICellRendererParams;
  name: string;
  constructor() { }

  ngOnInit() {}

  agInit(params: ICellRendererParams){

    //Gobales
    this.params = params;
    if (params.colDef.headerName == 'Empresa'){
      this.name = params.data.empresa == null ? '' : this.converText(params.data.empresa);
    } else if (params.colDef.headerName == 'Mercado'){
      this.name = params.data.mercado == null ? '' : this.converText(params.data.mercado);
    }else if (params.colDef.headerName == 'Vendedor'){
      this.name = params.data.vendedor == null ? '' : this.converText(params.data.vendedor);
    }else if (params.colDef.headerName == 'Supervisor'){
      this.name = params.data.supervisor == null ? '' : this.converText(params.data.supervisor);
    }else if (params.colDef.headerName == 'Organizacion'){
      this.name = params.data.organizacion == null ? '' : this.converText(params.data.organizacion);
    }else if (params.colDef.headerName == 'Sector Producto'){
      this.name = params.data.sectorProducto == null ? '' : this.converText(params.data.sectorProducto);
    }else if (params.colDef.headerName == 'Producto'){
      this.name = params.data.producto == null ? '' : this.converText(params.data.producto);
    }
    // colocacion Component
    else if (params.colDef.headerName == 'Convenio'){
      this.name = params.data.convenio == null ? '' : this.converText(params.data.convenio);
    }

    //Cartera componet
    else if (params.colDef.headerName == 'Convenio Actual'){
      this.name = params.data.convenioActual == null ? '' : this.converText(params.data.convenioActual);
    }

    //Recarga Componente
    else if (params.colDef.headerName == 'Nombre del cliente'){
      this.name = params.data.nombre == null ? '' : this.converText(params.data.nombre);
    } else if (params.colDef.headerName == 'Estatus credito'){
      this.name = params.data.Estatus_credito == null ? '' : this.converText(params.data.Estatus_credito);
    } else if (params.colDef.headerName == 'Celular'){
      this.name = params.data.vCelular == null ? '' : this.converText(params.data.vCelular);
    } else if (params.colDef.headerName == 'Telefono'){
      this.name = params.data.vTelefono == null ? '' : this.converText(params.data.vTelefono);
    } else if (params.colDef.headerName == 'Convenio'){
      this.name = params.data.convenio == null ? '' : this.converText(params.data.convenio);
    } else if (params.colDef.headerName == 'Vendedor'){
      this.name = params.data.vendedor == null ? '' : this.converText(params.data.vendedor);
    } else if (params.colDef.headerName == 'Supervisor'){
      this.name = params.data.supervisor == null ? '' : this.converText(params.data.supervisor);
    } else if (params.colDef.headerName == 'Estatus zell'){
      this.name = params.data.estatus_zell == null ? '' : this.converText(params.data.estatus_zell);
    } else if (params.colDef.headerName == 'Estatus firma'){
      this.name = params.data.status_recarga == null ? '' : this.converText(params.data.status_recarga);
    } else if (params.colDef.headerName == 'Estatus prospeccion'){
      this.name = params.data.estatus == null ? '' : this.converText(params.data.estatus);
    } else if (params.colDef.headerName == 'Vendedor'){
      this.name = params.data.vendedor == null ? '' : this.converText(params.data.vendedor);
    }

    else if (params.colDef.headerName == 'Exempleado') {
      this.name = params.data.exempleado == null ? 0 : this.converText(params.data.exempleado) ;
    }
    if (params.colDef.headerName == 'Nombre cliente') {
      this.name = params.data.vName == null ? '' : this.converText(params.data.vName);
    }
    if (params.colDef.headerName == 'Apellido Vendedor') {
      this.name = params.data.vApellido == null ? '' : this.converText(params.data.vApellido);
    }
    if (params.colDef.headerName == 'Gestor Vendedor') {
      this.name = params.data.vGestor == null ? '' : this.converText(params.data.vGestor);
    }
    if (params.colDef.headerName == 'Convenio original') {
      this.name = params.data.vConvenio_Original == null ? '' : this.converText(params.data.vConvenio_Original);
    }
    if (params.colDef.headerName == 'PerCon original') {
      this.name = params.data.vPerCon_Original == null ? '' : this.converText(params.data.vPerCon_Original);
    }
    if (params.colDef.headerName == 'Convenio actual') {
      this.name = params.data.vConvenio_Actual == null ? '' : this.converText(params.data.vConvenio_Actual);
    }
    if (params.colDef.headerName == 'Contacto gestor') {
      this.name = params.data.vGestorId == null ? '' : this.converText(params.data.vGestorId);
    }
    if (params.colDef.headerName == 'Condiciones Credito') {
      this.name = params.data.condiciones_Credito == null ? '' : this.converText(params.data.condiciones_Credito);
    }
    if (params.colDef.headerName == 'Asignacion Principal') {
      this.name = params.data.asignacion_Principal == null ? '' : this.converText(params.data.asignacion_Principal);
    }

    if (params.colDef.colId == 'vConvenio_Original') {
      this.name = params.data.vConvenio_Original == null ? '' : this.converText(params.data.vConvenio_Original);
    }
    if (params.colDef.colId == 'vPerCon_Original') {
      this.name = params.data.vPerCon_Original == null ? '' : this.converText(params.data.vPerCon_Original);
    }

    if (params.colDef.headerName == 'Ultimo empleo') {
      this.name = params.data.ultimo_empleo == null ? '' : this.converText(params.data.ultimo_empleo);
    }
    if (params.colDef.colId == 'company_name_Composicionpagos') {
      this.name = params.data.company_name == null ? '' : this.converText(params.data.company_name);
    }
    if (params.colDef.colId == 'IMSSError_Composicionpagos') {
      this.name = params.data.IMSSError == null ? '' : this.converText(params.data.IMSSError);
    }
    if (params.colDef.colId == 'Curp_Composicionpagos') {
      this.name = params.data.Curp == null ? '' : this.converText(params.data.Curp);
    }

    if (params.colDef.headerName == 'Nombre') {
      this.name = params.data.Nombre == null ? '' : this.converText(params.data.Nombre);
    }


    if (params.colDef.headerName == 'calificacion') {
      this.name = params.data.calificacion == null ? '' : this.converText(params.data.calificacion);
    }
   

    if (params.colDef.headerName == 'Comentario Baja') {
      this.name = params.data.comentario_baja == null ? '' : this.converText(params.data.comentario_baja);
    }
   

    

  }
  converText(title){
    return title.toUpperCase();
  }
  refresh(params: any): boolean {
    this.cellData = params.value;
    return true;
  }

}
