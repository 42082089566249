import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ComposicionPagosService } from 'src/app/reportes/pages/composicionPagos/composicion-pagos.service';
import * as moment from 'moment';

@Component({
  selector: 'app-date-composicionpagos',
  templateUrl: './date-composicionpagos.component.html',
  styleUrls: ['./date-composicionpagos.component.scss'],
})
export class DateComposicionpagosComponent implements OnInit, ICellRendererAngularComp {

  cellData: any;
  params: ICellRendererParams;
  visible: boolean = false;

  startDate ;

  constructor(
    private pagoService: ComposicionPagosService
  ) { }

  ngOnInit() {

  }

  agInit(params: ICellRendererParams) {
    this.params = params;
    if (params.colDef.headerName == 'Fecha de Baja'&& params.data.yearMonth !==
      "Total" && params.data.yearMonth !== "Total") {

      this.visible = true
      this.startDate = params.data.fecha_baja == null ? '' : new Date(params.data.fecha_baja).toISOString() ;
      params.data.fecha_baja &&
        console.log('agInit+++++++++++++++++++++++++', { params: params.data });
    }
  }


  refresh(params: any): boolean {
    this.cellData = params.value;
    return true;
  }

  onChangeTime(date){
    let newDate = new Date(date).toISOString()
    let addOldParams = null

    if (this.params.data.fk_tipo_baja) {
      addOldParams = {
        'fk_tipo_baja': Number(this.params.data.fk_tipo_baja)
      }
    }
    if (this.params.data.comentario_baja) {
      addOldParams = {
        ...addOldParams,
        'comentario': this.params.data.comentario_baja
      }
    }

    console.log(newDate);
    this.pagoService.saveFired = {
      [this.params.data.iCreditId]: {
        'fecha': newDate,
        ...addOldParams
      },
    }


    this.params.setValue(newDate)

  }
}