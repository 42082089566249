import { Component, OnInit } from '@angular/core';
import { ITooltipParams } from 'ag-grid-community';
// import { ITooltipAngularComp } from 'ag-grid-community/angular';
import {ITooltipAngularComp} from 'ag-grid-angular';



@Component({
  selector: 'app-custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  // template: ` `,
  styleUrls: ['./custom-tooltip.component.scss'],
})
export class CustomTooltipComponent implements ITooltipAngularComp {

  private params: { campo: string ,reglas:any} & ITooltipParams;
  private data: any[];
  private color: string;
  public redRule;
  public amberRule;
  public greenRule;

  constructor() {

    console.log('this.data CustomTooltipComponent',)
   }

  agInit(params: { campo: string , reglas:any } & ITooltipParams): void { 
    this.params = params;
    let campo = this.params.campo;
    let reglas = this.params.reglas;

    reglas.forEach(element => {
      if(element.Campo == campo){
          this.redRule     = element.redV
          this.amberRule   = element.amberV
          this.greenRule   = element.greenV
          // console.log('this.data CustomTooltipComponent',element)
      }        
  });

  this.color= '#e7e5e5'
  }

}




  // styles: [
  //   `
  //     :host {
  //       position: absolute;
  //       width: 150px;
  //       height: 70px;
  //       pointer-events: none;
  //       transition: opacity 1s;
  //     }

  //     :host.ag-tooltip-hiding {
  //       opacity: 0;
  //     }

  //     .custom-tooltip p {
  //       margin: 5px;
  //       white-space: nowrap;
  //     }

  //     .custom-tooltip p:first-of-type {
  //       font-weight: bold;
  //     }
  //   `,
  // ],