import { Injectable } from "@angular/core";
import { Apollo, Query, Subscription, gql } from "apollo-angular";
import { Observable } from "rxjs";


@Injectable({
    providedIn: "root",
})
export class graphQLService {
    headers: Object;

    constructor(
        // private querySubscription: Subscription,
        private apollo: Apollo
    ) {

    }

    query(query, variables?) {

        return this.apollo.subscribe({
            query: gql` ${ query }`,
            variables
        }).toPromise();
    }

    mutate(query, variables?){

        try {
            return this.apollo
                .mutate({
                    mutation: gql `${query}`,
                    variables,
                }).toPromise()
       
        } catch (error) {
            console.log('mutate Error',error);
        }

    } 

}

