import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { resourceLimits } from 'worker_threads';
import { Colocacion } from '../../../Interface/colocacion';
import { ReportesService } from '../../../services/reportes.service';
import { DateService } from '../../../shared/date.service';
import { MoneyCellsComponent } from '../../cell-render/money-cells/money-cells.component';
import { NumberCellsComponent } from '../../cell-render/number-cells/number-cells.component';
import { PercentCellsComponent } from '../../cell-render/percent-cells/percent-cells.component';
import { WordsCellsComponent } from '../../cell-render/words-cells/words-cells.component';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import * as data from '../../../shared/reglasSemaforizacion.json';
import { type } from 'os';
import { DateCellsComponent } from '../../colocacion/tabla-colocacion/cells-table/date-cells/date-cells.component';
import { CustomTooltipComponent } from '../../colocacion/tabla-colocacion/cells-table/custom-tooltip/custom-tooltip.component';
import { ColorFilterComponent } from '../../colocacion/tabla-colocacion/cells-table/color-filter/color-filter.component';
import { ComposicionPagosService } from '../../../pages/composicionPagos/composicion-pagos.service';
import { ToastService } from 'src/app/toast.service';

@Component({
  selector: 'app-tabla-hist-composicion-pagos',
  templateUrl: './tabla-hist-composicion-pagos.component.html',
  styleUrls: ['./tabla-hist-composicion-pagos.component.scss'],
})
export class TablaHistComposicionPagosComponent implements OnInit {


  @Input() showHistGrafico: boolean;
  public rowClass;
  public getRowClass;
  public rowClassRules;
  public getRowStyle;





  public colorReglas;

  private initDate: string;
  private finalDate: string;
  private paymentDateI: string;
  public year1Date: string;
  private accFiltros = [];
  public defaultColDef;
  public sideBar;
  // public arrayInfo = Array<Colocacion>();
  public arrayInfo: any = [];
  public pinnedBottomRowData;
  rowHeight: number;
  rowDef;
  chartData;
  params: any;
  gridApi;
  gridColumnApi;
  gridOptions;
  context: any;
  minRowHeight;
  currentRowHeight: any;
  frameworkComponents: any;
  filterSelect: string;
  searchValue: string;
  minWidth: string;
  maxWidth: string;


  public tooltipShowDelay;

  filtros = [
    {
      val: 'Empresa Original',
      value: 'vPerCon_Original'
    },
    {
      val: 'Convenio Original',
      value: 'vConvenio_Original'
    },
  ]

  public Filters = {
    filtros: [],
  }



  constructor(
    private _composicionPagosService: ComposicionPagosService,
    private dateService: DateService,
    private toastS: ToastService,
  ) {
    this.colorReglas = data.semaforizacionComposicionPagos
    // this.vColor = 5.5
    this.context = { componentParent: this };
    this.getConfig();
    this.minWidth = '3px';
    this.maxWidth = '1px';

  }
  public style: any = {
    width: '100% !important',
    height: '50vh',
  };

  // width: 100 % !important;
  // height: 50vh;
  // // max-width: 85vw;
  // // min-width: 45vw;
  // // min-height: 55vh;
  // max - height: 50vh;
  // border - radius: 1px solid rgb(193, 21, 21)!important;



  ngOnInit() {

    this._composicionPagosService.getDataHist()
    this._composicionPagosService.dataHist.subscribe(response => {



      if (response) {
        this.arrayInfo = response['data']
        this.params?.api.setRowData(response['data'])



        if (response['filtros']) {
          let filtro
          filtro = response['filtros'];
          this.filtros.map(ele => {
            console.log('filtros ------------------', { filtro });
            let val = ele.value

            if (filtro.includes('totalAdministrativo,totalExtrajudicial')) {
              this.params.columnApi.setColumnVisible('totalAdministrativo', true)
              this.params.columnApi.setColumnVisible('totalExtrajudicial', true)
            }
            else {
              this.params.columnApi.setColumnVisible('totalAdministrativo', false)
              this.params.columnApi.setColumnVisible('totalExtrajudicial', false)
            }

            if (filtro.includes(val)) {
              this.params.columnApi.setColumnVisible(val, true)
            } else {
              this.params.columnApi.setColumnVisible(val, false)
            }

            if (filtro.includes('vPerCon_Original')) {
              this.params.columnApi.setColumnVisible('calificacion', true)
              this.params.columnApi.setColumnVisible('tasa_interes', true)
              this.params.columnApi.setColumnVisible('rh_estatus', true)
              this.params.columnApi.setColumnVisible('monto_max', true)
              this.params.columnApi.setColumnVisible('plazo_maximo', true)
              this.params.columnApi.setColumnVisible('fecha_limite', true)
              this.params.columnApi.setColumnVisible('antiguedad_min', true)
            }
            else {
              this.params.columnApi.setColumnVisible('calificacion', false)
              this.params.columnApi.setColumnVisible('tasa_interes', false)
              this.params.columnApi.setColumnVisible('rh_estatus', false)
              this.params.columnApi.setColumnVisible('monto_max', false)
              this.params.columnApi.setColumnVisible('plazo_maximo', false)
              this.params.columnApi.setColumnVisible('fecha_limite', false)
              this.params.columnApi.setColumnVisible('antiguedad_min', false)
            }



          });
        }


        this.pinnedBottomRowData = this.createFooterData(this.params);
      }
    })


  }

  ngOnChanges(changes): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.

    if (!this.showHistGrafico) {
      this.style = {
        width: '100% !important',
        height: '80vh',
      };
    } else {
      this.style = {
        width: '100% !important',
        height: '50vh',
      };
    }

  }

  getConfig() {
    this.rowClass = 'my-blue-class';
    this.getRowClass = params => {
      if (params.node.rowIndex % 2 === 0) {
        return 'my-shaded-effect';
      }
    },
      this.getRowStyle = function (params) {
        if (params.node.rowPinned === 'bottom') {
          return { "background-color": "red" };
        }
      },
      this.rowDef = this.getColumns();
    this.sideBar = 'filters';
    this.defaultColDef = {
      flex: 1,
      minWidth: 150,
      filter: true,
      floatingFilter: true,
      tooltipComponent: 'customTooltip',
      sortable: true,
    };
    this.rowHeight = 50
    this.frameworkComponents = this.getFrameworkComponents();
    // this.rowClassRules = {
    //     'rag-green': 'data.age < 20',
    //     'rag-amber': 'data.age >= 20 && data.age < 25',
    //     'rag-red': 'data.age >= 25',
    // },
    this.tooltipShowDelay = 0;
    this.gridOptions = {

      isExternalFilterPresent: this.isExternalFilterPresent,
      doesExternalFilterPass: this.doesExternalFilterPass,
      columnDefs: this.rowDef,
      components: {
        moneyCells: MoneyCellsComponent,
        numberCells: NumberCellsComponent,
        percentCell: PercentCellsComponent,
        wordsCell: WordsCellsComponent,
        DateCells: DateCellsComponent,
        customTooltip: CustomTooltipComponent
      },
      // rowClassRules: {

      //     'rag-green': 'data.diasAperturaPonderadoPromedio < 4000',
      //     'rag-amber': 'data.diasAperturaPonderadoPromedio >= 4000',
      // 'rag-red': 'data.age >= 25',

      // "row-fail": params => params.api.getValue("result", params.node) < 60,
      // "row-pass": params => params.api.getValue("result", params.node) >= 60

      // 'bg-red': (params) => {
      //     if (params.rowIndex < 2) {
      //         return true;
      //     }
      // },
      // },
      defaultColDef: {
        filter: true,
        floatingFilter: true,
        resizable: true,
        sortable: true,
        hide: true,
        tooltipComponent: 'customTooltip',
      },
    };
    // this.gridApi.setPinnedBottomRowData(['',123,123,4325,2345])
  }

  getColumns() {
    return [
      {
        width: 120,
        minWidth: 90,
        maxWidth: 150,
        headerName: 'Año / mes',
        field: 'yearMonth',
        cellRenderer: 'DateCells',
        sortable: true,
        resizable: true,
        floatingFilter: true,
        hide: false,
        typeOf: 'string',
      },


      {
        headerName: 'Info empresa',
        // headerGroupComponent: 'CustomHeaderGroup',
        children: [

          {
            width: 100,
            minWidth: 90,
            maxWidth: 400,
            colId: 'vPerCon_Original',
            headerName: 'Empresa original',
            field: 'vPerCon_Original',
            cellRenderer: 'wordsCell',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            hide: true,
            typeOf: 'string',
          },

          {
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            headerName: 'calificacion',
            field: 'calificacion',
            cellRenderer: 'wordsCell',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            hide: true,
            columnGroupShow: 'open',
            typeOf: 'string',
          },
          {
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            colId: 'tasa_interes',
            headerName: 'tasa interes',
            field: 'tasa_interes',
            cellRenderer: 'numberCells',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            hide: true,
            columnGroupShow: 'open',
            typeOf: 'float',
            filter: 'agNumberColumnFilter',
          },
          {
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            colId: 'rh_estatus',
            headerName: 'Confirmacion RH',
            field: 'rh_estatus',
            cellRenderer: 'numberCells',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            hide: true,
            columnGroupShow: 'open',
            typeOf: 'number',
            filter: 'agNumberColumnFilter',
          },
          {
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            colId: 'monto_max',
            headerName: 'Monto maximo',
            field: 'monto_max',
            cellRenderer: 'numberCells',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            hide: true,
            columnGroupShow: 'open',
            typeOf: 'currency',
            filter: 'agNumberColumnFilter',
          },
          {
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            colId: 'plazo_maximo',
            headerName: 'Plazo maximo',
            field: 'plazo_maximo',
            cellRenderer: 'numberCells',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            hide: true,
            columnGroupShow: 'open',
            typeOf: 'number',
            filter: 'agNumberColumnFilter',
          },
          {
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            colId: 'fecha_limite',
            headerName: 'Fecha limite',
            field: 'fecha_limite',
            cellRenderer: 'DateCells',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            hide: true,
            columnGroupShow: 'open',
            typeOf: 'string',
            filter: 'agNumberColumnFilter',
          },

          {
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            colId: 'antiguedad_min',
            headerName: 'Antigüedad minima',
            field: 'antiguedad_min',
            cellRenderer: 'numberCells',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            hide: true,
            columnGroupShow: 'open',
            typeOf: 'number',
            filter: 'agNumberColumnFilter',
          },


        ]
      },

      {
        width: 100,
        minWidth: 90,
        maxWidth: 400,
        colId: 'vConvenio_Original',
        headerName: 'Convenio original',
        field: 'vConvenio_Original',
        cellRenderer: 'wordsCell',
        sortable: true,
        resizable: true,
        floatingFilter: true,
        hide: true,
        columnGroupShow: 'open',
        typeOf: 'string',
      },

      {
        minWidth: this.minWidth,
        maxWidth: this.maxWidth,
        headerName: 'Entrada Exempleado',
        field: 'entrada_exempleado',
        colId: 'entrada_exempleado',
        cellRenderer: 'numberCells',
        sortable: true,
        resizable: true,
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        hide: false,
        columnGroupShow: 'open',
        typeOf: 'currency',
      },
      {
        minWidth: this.minWidth,
        maxWidth: this.maxWidth,
        headerName: 'Capital Administrativa',
        field: 'salidaAdministrativa',
        colId: 'salidaAdministrativa',
        cellRenderer: 'numberCells',
        sortable: true,
        resizable: true,
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        hide: false,
        columnGroupShow: 'open',
        typeOf: 'currency',
      },
      {
        minWidth: this.minWidth,
        maxWidth: this.maxWidth,
        headerName: 'Capital Extrajudicial',
        field: 'salidaExtrajudicial',
        colId: 'salidaExtrajudicial',
        cellRenderer: 'numberCells',
        sortable: true,
        resizable: true,
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        hide: false,
        columnGroupShow: 'open',
        typeOf: 'currency',
      },
      {
        minWidth: this.minWidth,
        maxWidth: this.maxWidth,
        headerName: 'Porcentaje ExEmplAdmCobra',
        field: 'porcentajeExEmplAdmCobra',
        colId: 'porcentajeExEmplAdmCobra',
        cellRenderer: 'percentCell',
        sortable: true,
        resizable: true,
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        hide: false,
        columnGroupShow: 'open',
        typeOf: 'percent',
      },
      {
        minWidth: this.minWidth,
        maxWidth: this.maxWidth,
        headerName: 'Aumento Exmpleado',
        field: 'aumentoExmpleado',
        colId: 'aumentoExmpleado',
        cellRenderer: 'numberCells',
        sortable: true,
        resizable: true,
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        hide: false,
        columnGroupShow: 'open',
        typeOf: 'currency',
      },
      {
        typeOf: 'percent',
        colId: 'porcentaje cartera',
        floatingFilterComponent: 'colorFilter',
        minWidth: this.minWidth,
        maxWidth: this.maxWidth,
        headerName: 'Porcentaje Cartera',
        field: 'porcentajeCartera',
        cellRenderer: 'percentCell',
        sortable: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        headerTooltip: 'porcentajeCartera',
        hide: false,
        columnGroupShow: 'open',
        tooltipComponentParams: { campo: 'porcentaje cartera', reglas: this.colorReglas },
        cellClass: params => {
          if (Object.values(params.data).shift() == 'Total') {

            return params.value;
          } else {
            return ragCellClassRules(params.value, this.colorReglas, 'porcentaje cartera')
          }
        }
      },
      {
        minWidth: this.minWidth,
        maxWidth: this.maxWidth,
        headerName: 'nK_Pendiente',
        field: 'nK_Pendiente',
        colId: 'nK_Pendiente',
        cellRenderer: 'numberCells',
        sortable: true,
        resizable: true,
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        hide: false,
        columnGroupShow: 'open',
        typeOf: 'currency',
      },
      {
        minWidth: this.minWidth,
        maxWidth: this.maxWidth,
        headerName: 'totalExmpleado',
        field: 'totalExmpleado',
        colId: 'totalExmpleado',
        cellRenderer: 'numberCells',
        sortable: true,
        resizable: true,
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        hide: false,
        columnGroupShow: 'open',
        typeOf: 'currency',
      },

      {
        headerName: 'Importe de pagos',
        // headerGroupComponent: 'CustomHeaderGroup',
        children: [

          {
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            headerName: 'Total Administrativo',
            field: 'totalAdministrativo',
            cellRenderer: 'numberCells',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            filter: 'agNumberColumnFilter',
            hide: true,
            typeOf: 'currency',
          },
          {
            minWidth: this.minWidth,
            maxWidth: this.maxWidth,
            headerName: 'Total Extrajudicial',
            field: 'totalExtrajudicial',
            cellRenderer: 'numberCells',
            sortable: true,
            resizable: true,
            floatingFilter: true,
            hide: true,
            columnGroupShow: 'open',
            typeOf: 'currency',
          }
        ]
      },


    ]
  }


  getFrameworkComponents() {
    return {
      moneyCells: MoneyCellsComponent,
      numberCells: NumberCellsComponent,
      percentCell: PercentCellsComponent,
      wordsCell: WordsCellsComponent,
      DateCells: DateCellsComponent,
      customTooltip: CustomTooltipComponent,
      colorFilter: ColorFilterComponent,
    };
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }



  onFilterChanged(params: GridOptions): void {
    // console.log(this.numberOfRows = params.api?.getDisplayedRowCount());
    // console.log('hola')
  }

  onGridReady(params) {
    this.params = params;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.minRowHeight = params.api.getSizesForCurrentTheme().rowHeight;
    this.currentRowHeight = this.minRowHeight;
    params.api.sizeColumnsToFit();

  }

  createFooterData(gridApi?, event?: GridOptions) {
    let result = [];
    let model;
    let header;
    let visibleRows = [];
    let dataGrafico = [];



    if (gridApi) {
      model = gridApi.columnApi.columnModel.gridApi.rowModel;
      visibleRows = model.rowsToDisplay;

      this.getHeaderXLS(gridApi.columnApi.columnModel.viewportColumns)
      this.getDataXLS(visibleRows)


    }
    let groupBy = this._composicionPagosService.agrupadores;


    let entrada_exempleado = sum(visibleRows, 'entrada_exempleado')

    let salidaAdministrativa = sum(visibleRows, 'salidaAdministrativa')
    let salidaExtrajudicial = sum(visibleRows, 'salidaExtrajudicial')
    let porcentajeExEmplAdmCobra = ((salidaAdministrativa + salidaExtrajudicial) / entrada_exempleado) * 100
    let aumentoExmpleado = sum(visibleRows, 'aumentoExmpleado')
    let nK_Pendiente = sum(visibleRows, 'nK_Pendiente')
    let totalExmpleado = sum(visibleRows, 'totalExmpleado')
    let porcentajeCartera = ((aumentoExmpleado / nK_Pendiente) * 12) * 100

    let totalEntrada_exempleado = agrupador(visibleRows, 'entrada_exempleado', groupBy)
    let totalSalidaAdministrativa = agrupador(visibleRows, 'salidaAdministrativa', groupBy)
    let totalSalidaExtrajudicial = agrupador(visibleRows, 'salidaExtrajudicial', groupBy)
    let totalAdministrativo = sum(visibleRows, 'totalAdministrativo')
    let totalExtrajudicial = sum(visibleRows, 'totalExtrajudicial')
    let totalAumentoExmpleado = agrupador(visibleRows, 'aumentoExmpleado', groupBy)


    // dataHistGrafica

    let grafica = []
    totalEntrada_exempleado.map((curr, indx) => {
      if (grafica) {
        grafica.push({
          "yearMonth": curr[0],
          "totalEntrada_exempleado": totalEntrada_exempleado[indx][1],
          'totalSalidaAdministrativa': totalSalidaAdministrativa[indx][1],
          'totalSalidaExtrajudicial': totalSalidaExtrajudicial[indx][1],
          'totalAumentoExmpleado': totalAumentoExmpleado[indx][1],
          // 'Total': totalCreditosNuevosRecargas[indx][1],
        })
      }
    })

    this._composicionPagosService.dataHistGrafica.next(grafica)

    // let nK_PendientePorcentaje = sumP(visibleRows, 'nK_PendientePorcentaje')



    result.push({
      yearMonth: 'Total',
      entrada_exempleado,
      salidaAdministrativa,
      salidaExtrajudicial,
      totalExtrajudicial,
      totalAdministrativo,
      porcentajeExEmplAdmCobra,
      aumentoExmpleado,
      porcentajeCartera,
      nK_Pendiente,
      totalExmpleado,

    });
    // console.log('result:', result);
    this.pinnedBottomRowData = result;
    return result;
  }


  getHeaderXLS(header) {
    let head = {}

    header.forEach(element => {
      if (element.userProvidedColDef) {
        const { headerName, field, typeOf } = element.userProvidedColDef

        if (element.originalParent.children.length > 1) {

          element.originalParent.children.map(ele => {
            const { userProvidedColDef, visible } = ele
            const { headerName, field, typeOf } = userProvidedColDef

            if (visible) {
              head = {
                ...head,
                [`${field}:${typeOf}`]: headerName
              }
            }
          })

        }
        else {
          head = {
            ...head,
            [`${field}:${typeOf}`]: headerName
          }
        }
        // console.log('getHeaderXLS', element.originalParent.children.length, { header: element.originalParent.children });



      }
    });

    this._composicionPagosService.xlsHeaderHist = head

  }

  getDataXLS(data) {
    let newdata = []

    data.forEach(element => {
      newdata.push(element.data)
    })
    this._composicionPagosService.xlsDataHist = newdata
  }

  isExternalFilterPresent() {
    return filterWord !== '';
  }

  doesExternalFilterPass(node) {
    let cellData;
    cellData = node.data.estatusRecomendados;
    switch (filterWord) {
      case '0':
        return cellData == filterWord;
      case '1':
        return cellData == filterWord;
      case '2':
        return cellData == filterWord;
      case '3':
        return cellData == filterWord;
      default:
        return node;
    }
  }




}




function sumP(values, col) {
  let lenght = 0;
  var sum = 0;
  values.forEach(function (value) { sum += value.data[col]; lenght++ });

  // console.log(sum,' ',lenght)
  return (sum / lenght);
}


function headerTooltipStyle(reglas, campo) {
  let headerToolti
  let redRule, amberRule, greenRule;

  reglas.forEach(element => {
    if (element.Campo == campo) {
      redRule = element.red
      amberRule = element.amber
      greenRule = element.green
    }
  });

  return ` <div class="custom-tooltip" [style.background-color]="color">
    <p>
      <span>{{ data.athlete }}</span>
    </p>
    <p><span>Country: </span>{{ data.country }}</p>
    <p><span>Total: </span>{{ data.total }}</p>
  </div>`
  return (
    '<div class="header-toltip">' + '<span >  Reglas </span>' + '</div>' +
    '<ul>' +
    '<li class="rag-red"><span class="span-tooltip"> Rojo: ' + redRule + '</span></li>' +

    '<li class="rag-amber"><span class="span-tooltip"> Amarilo: $' + amberRule + '</span></li>' +

    '<li class="rag-green"><span class="span-tooltip"> Verde: $' + greenRule + '</span></li>' +

    '</ul>'
    // '<div class="arrow_box"> <div class="circulo"> </div>' +'<span>' + w.globals.seriesNames[0] + ': ' + creditosNuevos[dataPointIndex].toFixed(2) + '</span> ' + '</div>' +
    // '<div class="arrow_box">' + '<span>' + w.globals.seriesNames[1] + ': ' + creditosRecargas[dataPointIndex].toFixed(2) + '</span>' + '</div>' +
    // '<div class="arrow_box">' + '<span>' + w.globals.seriesNames[2] + ': ' + totalCreditosNuevosRecargas[dataPointIndex].toFixed(2) + '</span>' + '</div>' +
    // '<div class="arrow_box">' + '<span>' + w.globals.seriesNames[3] + ': ' + metasMeses[dataPointIndex].toFixed(2) + '</span>' + '</div>'
  )
  // return headerToolti
  // console.log('ragCellClassRules2',params,'  ', reglas)

}

function ragCellClassRules(params, reglas, campo) {
  let clascs
  reglas.filter(ele => {
    if (ele.Campo == campo) {
      // console.log(params);
      let red = ele.red.replaceAll('x', params)
      let amber = ele.amber.replaceAll('x', params)
      let green = ele.green.replaceAll('x', params)
      // console.log('red', red)
      clascs = eval(red) ? 'rag-red' : eval(amber) ? 'rag-amber' : eval(green) ? 'rag-green' : ''
      // console.log(params, campo, reglas)

    }
  })

  return clascs
  // console.log('ragCellClassRules2',params,'  ', reglas)

}



let filterWord = '';

var filterParams = {
  comparator: function (filterLocalDateAtMidnight, cellValue) {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split('-');
    var cellDate = new Date(
      Number(dateParts[0]),
      Number(dateParts[1]) - 1,
    );

    let date = new Date(filterLocalDateAtMidnight)
    let yyyy = date.getFullYear()
    let dd = ('0' + (date.getMonth() + 1)).slice(-2)

    let ndate = ` ${yyyy}-${dd}`;
    filterLocalDateAtMidnight = new Date(ndate)

    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
  minValidYear: 2014,
  // maxValidYear: 2021,
};

function sum(values, col) {
  var sum = 0;
  values.forEach(function (value) { sum += value.data[col] });
  return sum;
}

function agrupador(data, col, groupBy) {
  // data = data.value.data
  let arreglo = []
  data.map(ele => arreglo.push(ele.data))
  // data.forEach(function (value) { arreglo = value.data });
  let agrupador = Object.values(arreglo)
  // console.log('totalTablaDispersionNuevostotalTablaDispersionNuevostotalTablaDispersionNuevos',Object.values(arreglo))
  // if(obj['yearMonth']){

  // }
  let obj
  obj = arreglo.reduce((total, obj) => {
    let key = obj[groupBy]
    if (!total[key]) {
      total[key] = [];
      total[key] = [];
    }
    let totales = obj[col]
    total[key].push(totales);
    return total;
  }, []);

  var result = Object.keys(obj).map(function (key) {
    let valor = obj[key].reduce((acc, curr) => acc + curr)
    // console.log(valor)
    return [key, valor];
  });
  // console.log('data de la funcion agrupador arreglo',arreglo)
  // console.log('data de la funcion agrupador obj',obj)
  // console.log('data de la funcion result',result)
  return result;
}

function Total(data, groupBy) {
  // data = data.value.data
  let arreglo = []
  data.map(ele => arreglo.push(ele.data))
  // data.forEach(function (value) { arreglo = value.data });

  let obj
  obj = arreglo.reduce((total, obj) => {
    let key = obj[groupBy];
    if (!total[key]) {
      total[key] = [];
    }
    let dispersionRecargas = obj.dispersionRecargas
    let dispersionNuevos = obj.dispersionNuevos
    total[key].push(dispersionRecargas, dispersionNuevos);
    return total;
  }, []);

  var result = Object.keys(obj).map(function (key) {
    let valor = obj[key].reduce((acc, curr) => acc + curr)
    // console.log(valor)
    return [key, valor];
  });
  // console.log('data de la funcion agrupador arreglo',arreglo)
  // console.log('data de la funcion agrupador obj',obj)
  // console.log('data de la funcion result',result)
  return result;
}

var a1000ValueGetter = function (params) {
  // console.log('params',params.columnApi.getValueColumns())
  // console.log('params',params.api['columnModel']['rowModel']['rowsToDisplay'])
  // return params.data.a + ' 1000'; 
  // var total = params.getValue('dispersionNuevo');
  // console.log('params',total)
  // console.log('params',params)
  return 1000;
};



